import React, { useState } from 'react'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GoogleMapApiKey } from '../../globalURL';
import CustomMarker from "../../images/marker-new.png"
import "./map.css";
export const Map = (props) => {
  const { center, markersList } = props
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GoogleMapApiKey,
  })
  const [selectedMarker, setSelectedMarker] = useState()
  const containerStyle = {
    // width: '100vw',
    // height: '100vh' 
    width: '100%',
    height: '650px'
  };

  // const center = {
  //   lat: 16.5062,
  //   lng: 80.6480
  // };
  // const center2 = {
  //   lat: 16.1809,
  //   lng: 81.1303
  // };
  // const markersList = [
  //   {
  //     name: "Vijayawada",
  //     location: {
  //       lat: 16.5062,
  //       lng: 80.6480
  //     }
  //   },
  //   {
  //     name: "Machiipatnam",
  //     location: {
  //       lat: 16.1809,
  //       lng: 81.1303
  //     }
  //   },
  //   {
  //     name: "Pamaru",
  //     location: {
  //       lat: 16.3230,
  //       lng: 80.9612
  //     }
  //   }

  // ]

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={4} 
      options={{
        gestureHandling: "greedy"
    }}
    >
      {markersList.map((marker) => (
        <>
          <div key={marker.name}>
            <Marker key={marker.name + "123"} position={marker.location} cursor='hand' options={{ icon: CustomMarker }} onClick={() => setSelectedMarker(marker)} />
          </div>
        </>

      ))}
      {selectedMarker && (
        <InfoWindow key={selectedMarker.name + "123"} position={selectedMarker.location} onCloseClick={() => setSelectedMarker("")}  >
          <>
            {selectedMarker.project_phase === "As Operated" ? (<>
              {/* Operationl Phase  */}
              <table className='table'><tbody>
                <tr className='table-default'>
                  <th className='text-left colBlack'>  Project ID </th>
                  <th className='text-left colBlack'> : </th>
                  <th className='text-left colBlack'> {selectedMarker.gsas_project_id}  </th>
                </tr>
                <tr className='table-default'>
                  <th className='text-left colBlack'> Project Name </th>
                  <th className='text-left colBlack'> : </th>
                  <th className='text-left colBlack'> {selectedMarker.project_name}  </th>
                </tr>
                <tr className='table-default'>
                  <th className='text-left colBlack'> Project Phase  </th>
                  <th className='text-left colBlack'> : </th>
                  <th className='text-left colBlack'> {selectedMarker.project_phase}  </th>
                </tr>
                <tr className='table-default'>
                  <th className='text-left colBlack'> System SEER  </th>
                  <th className='text-left colBlack'> : </th>
                  <th className='text-left colBlack'>  {selectedMarker.system_seer_op}  </th>
                </tr>
                <tr className='table-default'>
                  <th className='text-left colBlack'> System Performance </th>
                  <th className='text-left colBlack'> : </th>
                  <th className='text-left colBlack'>  {selectedMarker.system_performance_op}  </th>
                </tr>
                <tr className='table-default'>
                  <th className='text-left colBlack'> Energy Efficiency </th>
                  <th className='text-left colBlack'> : </th>
                  <th className='text-left colBlack'>  {selectedMarker.gsas_energy_efficiency_op}  </th>
                </tr></tbody></table>
            </>) : (<>
              {/* Design Phase  */}
              <table className='table'>
                <tbody>
                  <tr className='table-default'>
                    <th className='text-left colBlack'>  Project ID </th>
                    <th className='text-left colBlack'> : </th>
                    <th className='text-left colBlack'> {selectedMarker.gsas_project_id}  </th>
                  </tr>
                  <tr className='table-default'>
                    <th className='text-left colBlack'> Project Name </th>
                    <th className='text-left colBlack'> : </th>
                    <th className='text-left colBlack'> {selectedMarker.project_name}  </th>
                  </tr>
                  <tr className='table-default'>
                    <th className='text-left colBlack'> Project Phase  </th>
                    <th className='text-left colBlack'> : </th>
                    <th className='text-left colBlack'> {selectedMarker.project_phase}  </th>
                  </tr>
                  <tr className='table-default'>
                    <th className='text-left colBlack'> System SEER  </th>
                    <th className='text-left colBlack'> : </th>
                    <th className='text-left colBlack'>  {selectedMarker.system_seer}  </th>
                  </tr>
                  <tr className='table-default'>
                    <th className='text-left colBlack'> Chiller SEER </th>
                    <th className='text-left colBlack'> : </th>
                    <th className='text-left colBlack'>  {selectedMarker.chiller_seer}  </th>
                  </tr>
                  <tr className='table-default'>
                    <th className='text-left colBlack'> System performance </th>
                    <th className='text-left colBlack'> : </th>
                    <th className='text-left colBlack'>  {selectedMarker.system_seer_kw_ton}  </th>
                  </tr>
                  <tr className='table-default'>
                    <th className='text-left colBlack'> Chiller Performance   </th>
                    <th className='text-left colBlack'> : </th>
                    <th className='text-left colBlack'>  {selectedMarker.chiller_seer_kw_ton}  </th>
                  </tr></tbody></table>
            </>)}
            <button className='butC' onClick={() => setSelectedMarker("")} > Close </button>
          </>
        </InfoWindow>)}


    </GoogleMap>
  ) : <></>
}
