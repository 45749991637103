import React from 'react'

const InputWidget = (props) => {
  return (
    <div className={props.cls}>
      <div className='form-floating mb-2'>
        <input
          type={props.type}
          onChange={props.handleInputChange}
          className="form-control"
          id="floatingInput"
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          readOnly={props.readonly} 

        />
        <label htmlFor="floatingInput">
          {props.label}
        </label>
      </div>
    </div>
  )
}

export default InputWidget
