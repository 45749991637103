import React, { useState, useEffect, useRef, useContext } from "react";
import Dashboardsidebar from "./Dashboardsidebar";
import axios from "axios";
import "../../css/background_image.css";
import { useNavigate } from "react-router-dom";

import {
  BaseURL,
  chillersUrl,
  // citiesListURL,
  // countriesListURL,
  paymentTransactionDetailsUrl,
  projectDetails,
  uploadFileOrImage,
} from "../../globalURL";
import Spinner from "./Spinner";
import { CondenserContext } from "../../App";
import CsvFileDownlaod from "../../cooling-loading-profile-template.csv";
import { toast } from "react-toastify";
// import FileUpload from "../dashboard_pages/otherdocumentfile";
import { getCurrentPackageSelected } from "../../common/common_helper";

const Chiller = () => {
  const dataFetchedRef = useRef(false);
  const { setIsCondenserTableDisabled } = useContext(CondenserContext);
  const [formData, setFormData] = useState({
    // country_id: "",
    // city_id: "",
    no_of_chillers: 0,
    capacities: [],
    if_capacity_is_lower_than_minimum_will_the_chiller_start: "No",
    chiller_other_document_file: "",
    minimum_start_up_capacity: "",
    nominal_cop: "",
    cooling_load_profile_file: "",
    distribution_losses_type: "",
    distribution_losses: "",
    condenser_cooling_media: "",
    compressor_type: "",
    condenser_temperature: "",
    evaporator_temperature: "",
    condenser_delta_t: "",
    _id: localStorage.getItem("project_id") || "",
    access_token: localStorage.getItem("access_token") || "",
    gsas_project_id: localStorage.getItem("gsas_project_id") || "",
    project_phase: localStorage.getItem("phase") || "",
    project_date: localStorage.getItem("project_date") || "",
    project_name: localStorage.getItem("project_name") || "",
    client_name: localStorage.getItem("client_name") || "",
    consultant_name: localStorage.getItem("consultant_name") || "",
    plant_size: localStorage.getItem("plant_size") || "",
  });
  const navigate = useNavigate();
  // const [countriesList, setCountriesList] = useState([]);
  const [readonly, setReadonly] = useState(false);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // const [compresserType, setCompresserType] = useState({});
  const [waterCoolDisabled, setWaterCoolDisabled] = useState(true);
  const [airCoolEnable, setAirCoolEnable] = useState(false);

  function handleIncrement() {
    if (value < 10) {
      setValue(value + 1);
      const updatedCapacities = [...(formData?.capacities || [])];
      updatedCapacities.push({ chiller: "" });
      setFormData({
        ...formData,
        no_of_chillers: value + 1,
        capacities: updatedCapacities,
      });
    }
  }

  function handleDecrement() {
    if (value > 0) {
      setValue(value - 1);
      const updatedCapacities = [...(formData?.capacities || [])];
      updatedCapacities.pop();
      setFormData({
        ...formData,
        no_of_chillers: value - 1,
        capacities: updatedCapacities,
      });
    }
  }

  const handleSelectChange = (event) => {
    setFormData({ ...formData, compressor_type: null });
    const selectedValue = event.target.value;

    setIsCondenserTableDisabled(selectedValue === "Air-cooled" ? true : false);
    // selectedValue === "Air-cooled"
    //   ? console.log("true", true)
    //   : console.log("false", false);
    setFormData({ ...formData, condenser_cooling_media: selectedValue });
    //
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    axios
      .post(
        projectDetails,
        JSON.stringify({ project_id: localStorage.getItem("project_id") }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        let result = res?.data?.data;
        // console.log("res?.data?.data.re_edit_project", res?.data?.data.re_edit_project);
        if (res?.data?.data.re_edit_project === "yes") {
          setReadonly(true);
        } else {
          setReadonly(false);
        }

        // console.log("result", result);
        if (res.data.data === null) {
        } else {
          const noOfChillers = result?.no_of_chillers;
          setValue(parseInt(noOfChillers) || 0);
          setFormData(res?.data?.data);
          // setFormData({ ...formData, no_of_chillers: noOfChillers });
        }

        setIsLoading(false);
        // formData.capacities[0]['chiller'] = "2322"
      })
      .catch((error) => {
        console.log(error);
      });
    // Start new service

    axios
      .post(
        paymentTransactionDetailsUrl,
        JSON.stringify({ id: getCurrentPackageSelected() }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        // console.log(res.data.data);
        let result = res.data.data;
        // console.log("Result ::::: ", result);
        // alert(result.thermal_store_enable)
        if (result.water_cooled_chiller_enable === "No") {
          setWaterCoolDisabled(true);
        } else {
          setWaterCoolDisabled(false);
        }
        if (result.air_cooled_chiller_enable === "No") {
          setAirCoolEnable(true);
        } else {
          setAirCoolEnable(false);
        }
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  // const [citiesList, setCitiesList] = useState([]);
  // const fetchCities = async (id) => {
  //   await axios
  //     .post(citiesListURL, JSON.stringify({ country_id: id, state_id: "" }), {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => {
  //       setCitiesList(res["data"]["data"]);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const [formDataError, setFormDataError] = useState({
    // country_id: "",
    // city_id: "",
    no_of_chillers: 0,
    capacities: [],
    if_capacity_is_lower_than_minimum_will_the_chiller_start: "No",
    minimum_start_up_capacity: "",
    nominal_cop: "",
    cooling_load_profile_file: "",
    distribution_losses_type: "",
    distribution_losses: "",
    condenser_cooling_media: "",
    compressor_type: "",
    condenser_temperature: "",
    evaporator_temperature: "",
    condenser_delta_t: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (formDataError[name] && value) {
      setFormDataError({ ...formDataError, [name]: "" });
    }
  };

  const handleCapacitiesChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...formData?.capacities];
    list[index][name] = value;
    setFormData({ ...formData, ["capacities"]: list });
  };

  const validateFormData = () => {
    const errors = {};
    const requiredFields = [
      // "country_id",
      // "city_id",
      "no_of_chillers",
      "minimum_start_up_capacity",
      "cooling_load_profile_file",
      "nominal_cop",
      "distribution_losses_type",
      "distribution_losses",
      "compressor_type",
      "condenser_temperature",
      "evaporator_temperature",
      "condenser_delta_t",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        // alert(field + "  required")
        errors[field] = `Please enter ${field}`;
      }
    });

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = validateFormData();
    if (formData.cooling_load_profile_file === "") {
      setIsLoading(false);
      // alert("Error: Cooling Loading Profile CSV required.");
      toast.error("Error: Cooling Loading Profile CSV required");
      return false;
    }
    if (Object.keys(errors).length > 0) {
      // alert("Error: data not entered.Please check once")
      toast.error("Error: data not entered.Please check once");
      setFormDataError(errors);
      setIsLoading(false);
    } else {
      setFormDataError({});
      setIsLoading(true);
      // formData.append({"cooling_load_profile_file":finalCoolingLoadingFile});
      try {
        const response = await axios.post(
          chillersUrl,
          JSON.stringify(formData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIsLoading(false);
        if (response?.data?.data?.id) {
          navigate("/thermalstore");
        } else {
          // alert(`${response.data.message}`);
          toast.error(response.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        console.log(error);
      }
    }

    if (Object.keys(errors).length === 0) {
      // console.log("chiller formData", formData);
    }
  };

  // const [finalCoolingLoadingFile, setfinalCoolingLoadingFile] = useState(null);
  const coolingLoadingProfileUpload = (event) => {
    //formData.cooling_load_profile_file
    const formDataImage = new FormData();
    // setSelectedFile(event.target.files[0])
    formDataImage.append("file", event.target.files[0]);
    fetch(uploadFileOrImage, {
      method: "POST",
      body: formDataImage,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log(data["data"]["full_file_address"]);
        setFormData({
          ...formData,
          ["cooling_load_profile_file"]: data["data"]["full_file_address"],
        });
        // setfinalCoolingLoadingFile(data["data"]["full_file_address"])
        // formData.append({"cooling_load_profile_file":finalCoolingLoadingFile});
      });
  };

  function handleFileUpload(event) {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    fetch(uploadFileOrImage, {
      method: "POST",
      body: formDataImage,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log("sss",data["data"]["full_file_address"]);
        setFormData({
          ...formData,
          ["chiller_other_document_file"]: data["data"]["full_file_address"],
        });
      });

    // console.log("fileDocName", fileDocName);
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dashboardsidebar />
          <div className="content-page mt-0 class-back-ground border-colo-top">
            <div className="content fz-14 ">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-11">
                    <div className="card transparent">
                      <div className="card-body pt-1">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div
                              className="page-title-box"
                              style={{ padding: "40px 0px 30px 0" }}
                            >
                              <h4 className="table-title page-title-underline">
                                Chiller
                              </h4>
                            </div>
                          </div>
                        </div>
                        <form action="#" onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <label className="input_label" htmlFor="">
                                Chiller Type
                              </label>
                              <div className="form-floating mb-2">
                                <select
                                  disabled={readonly}
                                  name="condenser_cooling_media"
                                  value={formData.condenser_cooling_media}
                                  className="form-select w-100"
                                  onChange={handleSelectChange}
                                >
                                  <option value="">
                                    -------------------- Cooling Media
                                    --------------------
                                  </option>
                                  <option
                                    value="Water-cooled"
                                    selected={
                                      formData.condenser_cooling_media ===
                                      ("Water-cooled" ||
                                        formData.condenser_cooling_media)
                                    }
                                    hidden={waterCoolDisabled}
                                  >
                                    Water-cooled
                                  </option>
                                  <option
                                    value="Air-cooled"
                                    selected={
                                      formData.condenser_cooling_media ===
                                      ("Air-cooled" ||
                                        formData.condenser_cooling_media)
                                    }
                                    hidden={airCoolEnable}
                                  >
                                    Air-cooled
                                  </option>
                                </select>

                                {formDataError.condenser_cooling_media && (
                                  <div className="text-danger">
                                    {formDataError.condenser_cooling_media}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <label className="input_label" htmlFor="">
                                Compressor Type
                              </label>
                              <div className="form-floating mb-2">
                                <select
                                  disabled={readonly}
                                  name="compressor_type"
                                  id=""
                                  className="form-select w-100"
                                  value={formData.compressor_type}
                                  onChange={handleInputChange}
                                >
                                  <option value="">
                                    -------------------- Compressor Type
                                    --------------------
                                  </option>
                                  <option value="Screw"> Screw</option>
                                  <option value="Scroll">Scroll </option>
                                  <option value="Reciprocating">
                                    Reciprocating
                                  </option>
                                  <option
                                    value="Centrifugal"
                                    style={{
                                      display:
                                        formData.condenser_cooling_media ===
                                        "Water-cooled"
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    Centrifugal
                                  </option>
                                </select>

                                {formDataError.compressor_type && (
                                  <div className="text-danger">
                                    {formDataError.compressor_type}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className="row "
                            style={{ justifyContent: "space-between" }}
                          >
                            <div className="col-lg-6 col-md-4">
                              <div className="pt-2 fz-12 lablecolod">
                                <label
                                  className="input_label"
                                  htmlFor=""
                                  style={{ fontWeight: "bold" }}
                                >
                                  Number Of Chillers{" "}
                                </label>
                                <span
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#00d9ff",
                                  }}
                                  className="text-danger"
                                >
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected maripgo">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleDecrement}
                                      disabled={readonly}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={value}
                                    name="no_of_chillers"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleIncrement}
                                      disabled={readonly}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row row-cols-lg-5 row-cols-md-5 row-cols-sm-3 row-cols-2">
                            {formData &&
                              formData?.capacities &&
                              formData?.capacities.map((x, i) => {
                                return (
                                  <div
                                    className="col-lg-4"
                                    key={`chiller-${i}`}
                                  >
                                    <div className="form-group">
                                      <label className="input_label" htmlFor="">
                                        Chiller {i + 1} - Capacity{" "}
                                        {`(${formData?.selected_units})`}
                                      </label>
                                      <input
                                        type="number"
                                        disabled={readonly}
                                        pattern="[0-9]*"
                                        step="any"
                                        key={i}
                                        name="chiller"
                                        onChange={(e) =>
                                          handleCapacitiesChange(e, i)
                                        }
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Client Name"
                                        value={
                                          formData.capacities[i]["chiller"]
                                        }
                                      />
                                      {/* <label htmlFor="floatingInput">
                                        Chiller {i + 1} - Capacity {`(${formData?.selected_units})`}
                                      </label> */}
                                      {/* {formData?.capacities[i]["chiller"]  && (
                                  <p style={{ color: "red" }}>*Required</p>
                                )} */}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="input_label" htmlFor="">
                                If total capacity is lower than minimum, will
                                the chiller start?
                              </label>
                            </div>
                            <div className="col-lg-6 col-md-6 position-relative">
                              <div className="d-flex">
                                <div className="radiocust">
                                  <label className="text-black">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="if_capacity_is_lower_than_minimum_will_the_chiller_start"
                                      value="No"
                                      onChange={handleInputChange}
                                      checked={
                                        formData.if_capacity_is_lower_than_minimum_will_the_chiller_start ===
                                          "No" ||
                                        formData.if_capacity_is_lower_than_minimum_will_the_chiller_start ===
                                          "No, switch-off" ||
                                        formData.if_capacity_is_lower_than_minimum_will_the_chiller_start ===
                                          ""
                                      }
                                    />
                                    No
                                  </label>
                                </div>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <div className="radiocust">
                                  <label className="text-black">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="if_capacity_is_lower_than_minimum_will_the_chiller_start"
                                      value="Yes"
                                      onChange={handleInputChange}
                                      checked={
                                        formData.if_capacity_is_lower_than_minimum_will_the_chiller_start ===
                                          "Yes" ||
                                        formData.if_capacity_is_lower_than_minimum_will_the_chiller_start ===
                                          "Yes, by-pass"
                                      }
                                    />
                                    Yes
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-content-center">
                              <label
                                className="input_label"
                                htmlFor="remember2"
                              >
                                {`Nominal COP (${formData?.selected_units})`}
                                {/* Nominal COP (Kw/kw) */}
                              </label>
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                  name="nominal_cop"
                                  value={formData.nominal_cop}
                                  onChange={handleInputChange}
                                />
                                {/* <label htmlFor="floatingInput">
                                  Nominal COP (Kw/kw)
                                </label> */}

                                {formDataError.nominal_cop && (
                                  <div className="text-danger">
                                    {formDataError.nominal_cop}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-content-center">
                              <label
                                className="input_label"
                                htmlFor="remember2"
                              >
                                Minimum Startup Capacity (%)
                              </label>
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                  name="minimum_start_up_capacity"
                                  value={formData.minimum_start_up_capacity}
                                  onChange={handleInputChange}
                                />

                                {formDataError.minimum_start_up_capacity && (
                                  <div className="text-danger">
                                    {formDataError.minimum_start_up_capacity}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-content-center">
                              <label className="input_label">
                                Cooling Load Profile (TRH){" "}
                                <span
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#00d9ff",
                                  }}
                                  className="text-danger"
                                >
                                  *
                                </span>
                                &nbsp;
                                <a
                                  style={{ borderRadius: "10px" }}
                                  href={CsvFileDownlaod}
                                  className="button btn-xs btn-success text-nowrap"
                                  download={true}
                                >
                                  <i className="fe-download fa-lg"></i>&nbsp;
                                  Sample Template
                                </a>
                              </label>
                              <div className="form-floating mb-0 input-width-100">
                                <input
                                  type="file"
                                  className="filestyle form-control py-1 px-2 inputclass-name-pd-0"
                                  name="cooling_load_profile_file"
                                  onChange={coolingLoadingProfileUpload}
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                                {formData.cooling_load_profile_file ? (
                                  <span>
                                    <p className="input_label">
                                      Previous File: &nbsp;
                                      <a
                                        rel="noreferrer"
                                        style={{
                                          color: "#00d9ff",
                                          fontWeight: "700",
                                        }}
                                        href={`${BaseURL}${formData?.cooling_load_profile_file}`}
                                        target="_blank"
                                      >
                                        <i className="fa fa-file"></i> Click
                                        Here
                                      </a>
                                    </p>
                                  </span>
                                ) : (
                                  ""
                                )}

                                {formDataError.cooling_load_profile_file && (
                                  <div className="text-danger">
                                    {formDataError.cooling_load_profile_file}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-12">
                              <label
                                style={{ fontWeight: "700" }}
                                className="input_label table-title  page-sub-title-underline"
                              >
                                Temperature{" "}
                                <span style={{ color: "red" }}>
                                  {" "}
                                  {formData?.selected_units === "hp"
                                    ? "°F"
                                    : "°C"}
                                </span>
                              </label>
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{ padding: "0 0 0 19px" }}
                          >
                            <div className="col-lg-4 col-md-4 col-sm-6">
                              <label
                                className="input_label"
                                htmlFor="floatingInput"
                              >
                                Design Condenser
                              </label>
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                  name="condenser_temperature"
                                  value={formData.condenser_temperature}
                                  onChange={handleInputChange}
                                />

                                {formDataError.condenser_temperature && (
                                  <div className="text-danger">
                                    {formDataError.condenser_temperature}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                              <label
                                className="input_label"
                                htmlFor="floatingInput"
                              >
                                Design Evaporator
                              </label>
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                  name="evaporator_temperature"
                                  value={formData.evaporator_temperature}
                                  onChange={handleInputChange}
                                />

                                {formDataError.evaporator_temperature && (
                                  <div className="text-danger">
                                    {formDataError.evaporator_temperature}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                              <label
                                className="input_label"
                                htmlFor="floatingInput"
                              >
                                Design Condenser Delta T
                              </label>
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                  name="condenser_delta_t"
                                  value={formData.condenser_delta_t}
                                  onChange={handleInputChange}
                                />

                                {formDataError.condenser_delta_t && (
                                  <div className="text-danger">
                                    {formDataError.condenser_delta_t}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className="row"
                            style={{ padding: "0 0 0 19px" }}
                          >
                            <div className="col-lg-4 col-md-4">
                              <label
                                className="input_label"
                                htmlFor="floatingInput"
                              >
                                Distribution Losses (%)
                              </label>
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  name="distribution_losses"
                                  value={formData.distribution_losses}
                                  onChange={handleInputChange}
                                />
                                {formDataError.distribution_losses && (
                                  <div className="text-danger">
                                    {formDataError.distribution_losses}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                              <label
                                className="input_label"
                                htmlFor="distribution_losses_type"
                              >
                                Distribution Loss Type
                              </label>
                              <div className="form-floating mb-2">
                                <select
                                  name="distribution_losses_type"
                                  id=""
                                  className="form-select w-100"
                                  value={formData.distribution_losses_type}
                                  defaultValue="Default"
                                  onChange={handleInputChange}
                                >
                                  <option value="Default" hidden>
                                    -------------------- Distribution Loss Type
                                    --------------------
                                  </option>
                                  <option
                                    value={
                                      formData.distribution_losses_type ===
                                        "Percentage" || "Percentage (%)"
                                    }
                                  >
                                    Percentage
                                  </option>
                                  <option
                                    value="User Input"
                                    selected={
                                      formData.distribution_losses_type ===
                                      "User Input"
                                    }
                                  >
                                    User Input
                                  </option>
                                </select>

                                {formDataError.distribution_losses_type && (
                                  <div className="text-danger">
                                    {formDataError.distribution_losses_type}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* <div className="row">
                            <div className="col-lg-12 col-md-12 mb-2">
                              <FileUpload formData={formData} fileDocName="chiller_other_document_file" setFormData={setFormData} />
                            </div>
                          </div> */}

                          <div className="row">
                            <div className="col-lg-6 col-md-6 mb-2">
                              <label className="input_label">
                                Document Upload
                              </label>
                              <div className="form-floating input-width-100 mb-0">
                                <input
                                  type="file"
                                  className="filestyle form-control py-1 px-2 inputclass-name-pd-0"
                                  name="chiller_other_document_file"
                                  onChange={handleFileUpload}
                                />
                                {formData.chiller_other_document_file ? (
                                  <span>
                                    <p className="input_label">
                                      Previous File: &nbsp;
                                      <a
                                        rel="noreferrer"
                                        style={{ color: "#00d9ff" }}
                                        href={`${BaseURL}${formData?.chiller_other_document_file}`}
                                        target="_blank"
                                      >
                                        Click Here
                                      </a>
                                    </p>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="form-group row justify-content-end prj">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
                              <button
                                onClick={() => navigate("/projectinformation")}
                                // disabled={readonly}
                                type="submit"
                                className="btn-black mr-1 waves-effect waves-light"
                              >
                                <i className="fas fa-angle-left"></i> Back
                              </button>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                              <button
                                type="submit"
                                className="btn-pryellow mr-1 waves-effect waves-light"
                              >
                                Next <i className="fas fa-angle-right"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Chiller;
