import React from "react";
import LoadingGif from "../../images/Arcadia_loading2.gif";

const Spinner = () => { 
  return ( 
    <div className="overlay-spinner">
      <img src={LoadingGif} alt="loadingspinner" style={{height:"180px"}} />
    </div> 
  );
};
 
export default Spinner;
