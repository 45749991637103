import React from "react";
import Userdashsidebar from "../Userdashsidebar";

const DownloadButton = () => {
  const downloadFile = async () => {
    try {
      const response = await fetch(
        "http://43.205.20.110:5000/static/uploads/user_manual/sample.pdf"
      );
      const blob = await response.blob();
      const fileName = "myfile.pdf";
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  return (
    <>
      <button
        onClick={downloadFile}
        className="button btn-link btn-warning text-nowrap"
        download={true}
        style={{
          padding: "9px 11px ",
          borderRadius: "100px",
        }}
      >
        <i className="fe-download fa-lg"></i>&nbsp; Download User Manual
      </button>
    </>
  );
};

const UserManual = () => {
  return (
    <>
      <Userdashsidebar currentPage={"user-manual"} />
      <div className="content-page mt-0 class-back-ground border-colo-top">
        <div className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="card transparent">
                  <div className="card-body pt-1">
                    <div className="row profilepg">
                      <div className="col-lg-12">
                        <div className="card border-0">
                          <div className="card-header bg-success">
                            <h4 className="card-title text-white mb-0">
                              <div className="row">
                                <div className=" col-md-6">
                                  <h4 className="card-title text-white mb-0">
                                    <b>User Manual</b>
                                  </h4>
                                </div>
                                <div className=" col-md-6  text-right">
                                  <DownloadButton />
                                </div>
                              </div>
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className=""></div>
                            <iframe
                              src="https://docs.google.com/viewer?url=https://arxiv.org/pdf/quant-ph/0410100.pdf&embedded=true"
                              frameborder="0"
                              height="700px"
                              width="100%"
                            >
                              Loading....
                            </iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManual;
