import React from "react";

export default function CoefficientDefault({
  formData,
  handleInputChange,
  selectedValue,
  selectedTemperature,
  handleSubmit,
}) {
  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="card transparent">
                  <div className="card-body pt-1">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="page-title-box">
                          <h4 className="page-title">
                            Electric Chiller Model Curves
                          </h4>
                        </div>
                      </div>
                    </div>
                    <form action="" onSubmit={handleSubmit} novalidate>
                      <div className="row justify-content-between">
                        {/* <div className="col-lg-4 col-md-5 col-sm-6">
                          <div className="form-group mb-3">
                            <div className="form-floating">
                              <select
                                name="curvature_coefficient_type"
                                onChange={handleInputChange}
                                value={selectedValue}
                                id=""
                                className="form-select w-100"
                              >
                                <option value="" hidden>
                                  --------- Curvature Coefficient Type ---------
                                </option>
                                <option value="Default" selected={formData.curvature_coefficient_type === ("Default" || formData.curvature_coefficient_type)}>Default</option>
                                <option value="User Input" selected={formData.curvature_coefficient_type === ("User Input" || formData.curvature_coefficient_type)}> User Input </option>
                              </select>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-lg-4 col-md-5 col-sm-6 mb-3">
                          <div className="form-group mb-1">
                            <div className="form-floating">
                              <select
                                name="temperature"
                                onChange={handleInputChange}
                                value={selectedTemperature}
                                id=""
                                className="form-select w-100"
                              >
                                <option value="" hidden>
                                  --------- Temperature ---------
                                </option>
                                <option
                                  value="Celsius"
                                  selected={
                                    formData.temperature ===
                                    ("Celsius" || formData.temperature)
                                  }
                                >
                                  Celsius
                                </option>
                                <option
                                  value="Fahrenheit"
                                  selected={
                                    formData.temperature ===
                                    ("Fahrenheit" || formData.temperature)
                                  }
                                >
                                  Fahrenheit
                                </option>
                                <option
                                  value="Kelvin"
                                  selected={
                                    formData.temperature ===
                                    ("Kelvin" || formData.temperature)
                                  }
                                >
                                  Kelvin
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="table-responsive">
                            <table className="table table-blk">
                              <thead>
                                <tr className="bg-success">
                                  <th></th>
                                  <th>a</th>
                                  <th>b</th>
                                  <th>c</th>
                                  <th>d</th>
                                  <th>e</th>
                                  <th>f</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>CAPFT</td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.capft_a}
                                        onChange={handleInputChange}
                                        name="capft_a"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.capft_b}
                                        onChange={handleInputChange}
                                        name="capft_b"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.capft_c}
                                        onChange={handleInputChange}
                                        name="capft_c"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.capft_d}
                                        onChange={handleInputChange}
                                        name="capft_d"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.capft_e}
                                        onChange={handleInputChange}
                                        name="capft_e"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.capft_f}
                                        onChange={handleInputChange}
                                        name="capft_f"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>EIRFT</td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.eirft_a}
                                        onChange={handleInputChange}
                                        name="eirft_a"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.eirft_b}
                                        onChange={handleInputChange}
                                        name="eirft_b"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.eirft_c}
                                        onChange={handleInputChange}
                                        name="eirft_c"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.eirft_d}
                                        onChange={handleInputChange}
                                        name="eirft_d"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.eirft_e}
                                        onChange={handleInputChange}
                                        name="eirft_e"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.eirft_f}
                                        onChange={handleInputChange}
                                        name="eirft_f"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>EIRFPLR</td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.eirplr_a}
                                        onChange={handleInputChange}
                                        name="eirplr_a"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.eirplr_b}
                                        onChange={handleInputChange}
                                        name="eirplr_b"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        value={formData.eirplr_c}
                                        onChange={handleInputChange}
                                        name="eirplr_c"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row justify-content-end">
                        <div className="col-lg-12 text-right">
                          <button
                            type="submit"
                            className="btn btn-primary mr-1 waves-effect waves-light"
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
