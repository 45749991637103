import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../css/dashboardapp.css";
import "../../css/dashboardnewstyles.css";
const Dashboardsidebar = ({ currentPage }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentPhase, setCurrentPhase] = useState("");
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function openNav() {
    setIsNavOpen(true);
  }

  function closeNav() {
    setIsNavOpen(false);
  }
  const navStyle = {
    width: isNavOpen ? "260px" : "0",
    display: isNavOpen ? "block" : "none",
  };

  if (windowWidth >= 992) {
    navStyle.width = "260px";
    navStyle.display = "block";
  } else if (windowWidth <= 992) {
    navStyle.width = "-260px";
  }
  useState(() => {
    setCurrentPhase(localStorage.getItem("phase"));

    if (currentPhase === "") {
    }
  });
  return (
    <div>
      <div id="wrapper">
        <div className="d-flex d-lg-none d-block">
          <button
            className="button-menu-mobile waves-effect waves-light"
            onClick={openNav}
          >
            <i className="fe-menu"></i>
          </button>
          <div className="sdlogo">
            <img src="assets/images/logo.png" alt="" height="60" />
          </div>
        </div>
        {currentPage !== "projectinformation" &&
          currentPhase === "Design Phase" &&
          currentPhase === "As Operated" &&
          currentPhase === "Design and As Operated" && (
            <div className="logo-two">
              <Link to="/projectinformation">
                <img src="assets/images/logo-1.png" alt="" />
              </Link>
            </div>
          )}
        <div className="left-side-menu" style={navStyle}>
          <a href="#" id="side-nav-close" onClick={closeNav}>
            <i className="fal fa-times"></i>
          </a>
          <div className="slimscroll-menu1">
            <div id="sidebar-menu1">
              <div className="logo text-center">
                <Link to="/dashboard">
                  <img src="assets/images/logo.png" alt="" height="60" />
                </Link>
              </div>
              {currentPage === "final-result" ? (
                <>
                  <ul className="stepsnomenu">
                    <li
                      style={{
                        display:
                          currentPage === "final-result" ? "block" : "none",
                      }}
                    >
                      <Link>
                        Final Results
                        {currentPage === "final-result" ? (
                          <span className="circle">
                            <i className="fe-check fa-lg"></i>{" "}
                          </span>
                        ) : currentPage === "final-result" ? (
                          <span className="circle completed">
                            <i className="fe-check fa-lg"></i>{" "}
                          </span>
                        ) : (
                          <span className="circle">1</span>
                        )}
                      </Link>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <ul className="stepsnomenu">
                    <li className="compstep">
                      <Link style={{pointerEvents:"none"}}>
                        Project Information
                        <span className="circle completed">
                          <i className="fe-check fa-lg"></i>
                        </span>
                      </Link>
                    </li>
                    {currentPage !== "projectinformation" &&
                      currentPhase === "Design and As Operated" && (
                        <>
                          <li>
                            <Link to="/chiller">
                              Chiller
                              <span
                                className={`circle ${
                                  currentPage === "chiller" ? "" : "completed"
                                }`}
                              >
                                <i className="fe-check fa-lg"></i>
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/thermalstore">
                              Thermal Store
                              {currentPage === "thermalstore" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "pumps" ||
                                currentPage === "auxaliries" ||
                                currentPage === "fans" ||
                                currentPage === "cooling-towers" ||
                                currentPage === "result-as-built" ||
                                currentPage === "pumoperationalps" ||
                                currentPage === "co-efficients" ||
                                currentPage === "operational" ||
                                currentPage === "resultasbuilt" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">3</span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link to="/pumps">
                              Pumps
                              {currentPage === "pumps" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "auxaliries" ||
                                currentPage === "fans" ||
                                currentPage === "cooling-towers" ||
                                currentPage === "pumoperationalps" ||
                                currentPage === "co-efficients" ||
                                currentPage === "operational" ||
                                currentPage === "result-as-built" ||
                                currentPage === "resultasbuilt" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">4</span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link to="/auxiliaries">
                              Auxiliaries
                              {currentPage === "auxaliries" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "cooling-towers" ||
                                currentPage === "fans" ||
                                currentPage === "co-efficients" ||
                                currentPage === "pumoperationalps" ||
                                currentPage === "operational" ||
                                currentPage === "result-as-built" ||
                                currentPage === "resultasbuilt" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">5</span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cooling-towers">
                              Cooling Tower
                              {currentPage === "cooling-towers" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "fans" ||
                                currentPage === "co-efficients" ||
                                currentPage === "operational" ||
                                currentPage === "result-as-built" ||
                                currentPage === "resultasbuilt" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">6</span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link to="/fans">
                              Cooling Tower Fan Motors
                              {currentPage === "fans" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "co-efficients" ||
                                currentPage === "operational" ||
                                currentPage === "result-as-built" ||
                                currentPage === "resultasbuilt" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">7</span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link to="/co-efficients">
                              Coefficient Component
                              {currentPage === "co-efficients" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "result-as-built" ||
                                currentPage === "operational" ||
                                currentPage === "resultasbuilt" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">8</span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link to="/operationalboth">
                              As Operated
                              {currentPage === "operational" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "result-as-built" ||
                                currentPage === "resultasbuilt" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">9</span>
                              )}
                            </Link>
                          </li>
                          <li
                            style={{
                              display:
                                currentPage === "result-as-built"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <Link>
                              Final Result
                              {currentPage === "result-as-built" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "result-as-built" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">10</span>
                              )}
                            </Link>
                          </li>
                        </>
                      )}
                    {currentPage !== "projectinformation" &&
                      currentPhase === "Design Phase" && (
                        <>
                          <li>
                            <Link style={{pointerEvents:"none"}}>
                              Chiller
                              <span
                                className={`circle ${
                                  currentPage === "chiller" ? "" : "completed"
                                }`}
                              >
                                <i className="fe-check fa-lg"></i>
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link style={{pointerEvents:"none"}}>
                              Thermal Store
                              {currentPage === "thermalstore" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "resultasbuilt" ||
                                currentPage === "pumps" ||
                                currentPage === "auxaliries" ||
                                currentPage === "cooling-towers" ||
                                currentPage === "fans" ||
                                currentPage === "pumoperationalps" ||
                                currentPage === "co-efficients" ||
                                currentPage === "operational" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">3</span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link style={{pointerEvents:"none"}}>
                              Pumps
                              {currentPage === "pumps" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "resultasbuilt" ||
                                currentPage === "auxaliries" ||
                                currentPage === "cooling-towers" ||
                                currentPage === "fans" ||
                                currentPage === "pumoperationalps" ||
                                currentPage === "co-efficients" ||
                                currentPage === "operational" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">4</span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link style={{pointerEvents:"none"}}>
                              Auxiliaries
                              {currentPage === "auxaliries" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "resultasbuilt" ||
                                currentPage === "cooling-towers" ||
                                currentPage === "fans" ||
                                currentPage === "pumoperationalps" ||
                                currentPage === "co-efficients" ||
                                currentPage === "operational" ||
                                currentPage === "result-as-built" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">5</span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link style={{pointerEvents:"none"}}>
                              Cooling Tower
                              {currentPage === "cooling-towers" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "resultasbuilt" ||
                                currentPage === "fans" ||
                                currentPage === "co-efficients" ||
                                currentPage === "operational" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">6</span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link style={{pointerEvents:"none"}}>
                              Cooling Tower Fan Motors
                              {currentPage === "fans" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "resultasbuilt" ||
                                currentPage === "co-efficients" ||
                                currentPage === "operational" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">7</span>
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link style={{pointerEvents:"none"}}>
                              Coefficient Component
                              {currentPage === "co-efficients" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "resultasbuilt" ||
                                currentPage === "operational" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">8</span>
                              )}
                            </Link>
                          </li>
                          <li
                            style={{
                              display:
                                currentPage === "resultasbuilt"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <Link style={{pointerEvents:"none"}}>
                              Final Result
                              {currentPage === "resultasbuilt" ? (
                                <span className="circle">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : currentPage === "resultasbuilt" ? (
                                <span className="circle completed">
                                  <i className="fe-check fa-lg"></i>{" "}
                                </span>
                              ) : (
                                <span className="circle">9</span>
                              )}
                            </Link>
                          </li>
                        </>
                      )}
                    {currentPhase === "As Operated" && (
                      <li>
                        <Link to="/operational">
                          As Operated
                          <span className="circle">
                            <i className="fe-check fa-lg"></i>
                          </span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </>
              )}
              <div className="logo text-center pading-logo">
                <Link to="/dashboard">
                  <img
                    src="assets/images/footerlogo.png"
                    alt=""
                    height="29"
                    width="100"
                  />
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboardsidebar;
