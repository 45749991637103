import React from "react";
import SEERICON from "../../../images/icon2.png";

const AsOperated = (props) => {
  return (
    <div style={{ padding: "0px", border: "0px solid white" }}>
      <div className="row justify-content-between">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="row justify-content-between">
            <div className="col-lg-10 col-md-10 col-sm-9 col-10">
              <ul className="efficientbox">
                <li
                  className={
                    props.result.gsas_energy_efficiency_op === "A*"
                      ? "blink_me"
                      : ""
                  }
                  style={{ width: "20%" }}
                >
                  A*
                </li>
                <li
                  className={
                    props.result.gsas_energy_efficiency_op === "A"
                      ? "blink_me"
                      : ""
                  }
                  style={{ width: "28%" }}
                >
                  A
                </li>
                <li
                  className={
                    props.result.gsas_energy_efficiency_op === "B"
                      ? "blink_me"
                      : ""
                  }
                  style={{ width: "38%" }}
                >
                  B
                </li>
                <li
                  className={
                    props.result.gsas_energy_efficiency_op === "C"
                      ? "blink_me"
                      : ""
                  }
                  style={{ width: "48%" }}
                >
                  C
                </li>
                <li
                  className={
                    props.result.gsas_energy_efficiency_op === "D"
                      ? "blink_me"
                      : ""
                  }
                  style={{ width: "58%" }}
                >
                  D
                </li>
                <li
                  className={
                    props.result.gsas_energy_efficiency_op === "E"
                      ? "blink_me"
                      : ""
                  }
                  style={{ width: "68%" }}
                >
                  E
                </li>
                <li
                  className={
                    props.result.gsas_energy_efficiency_op === "F"
                      ? "blink_me"
                      : ""
                  }
                  style={{ width: "78%" }}
                >
                  F
                </li>
                <li
                  className={
                    props.result.gsas_energy_efficiency_op === "G"
                      ? "blink_me"
                      : ""
                  }
                  style={{ width: "88%" }}
                >
                  G
                </li>
              </ul>
            </div>
            {/* <div className="col-lg-2">
                            <div className="gradeA">{props.result.gsas_energy_efficiency_op || "--"}</div>
                        </div> */}
            <div
              style={{
                borderRight: "3px solid #0f7617",
                height: "335px",
                position: "relative",
                right: "10px",
              }}
              className="col-lg-2 col-md-2 col-2 col-sm-3"
            >
              <div
                className={`gradeAplus ${
                  props.result.gsas_energy_efficiency_op === "A*"
                    ? "visible"
                    : "hidden"
                }`}
              >
                {props.result.gsas_energy_efficiency_op}
              </div>
              <div
                className={`gradeA ${
                  props.result.gsas_energy_efficiency_op === "A"
                    ? "visible"
                    : "hidden"
                }`}
              >
                {props.result.gsas_energy_efficiency_op}
              </div>
              <div
                className={`gradeB ${
                  props.result.gsas_energy_efficiency_op === "B"
                    ? "visible"
                    : "hidden"
                }`}
              >
                {props.result.gsas_energy_efficiency_op}
              </div>
              <div
                className={`gradeC ${
                  props.result.gsas_energy_efficiency_op === "C"
                    ? "visible"
                    : "hidden"
                }`}
              >
                {props.result.gsas_energy_efficiency_op}
              </div>
              <div
                className={`gradeD ${
                  props.result.gsas_energy_efficiency_op === "D"
                    ? "visible"
                    : "hidden"
                }`}
              >
                {props.result.gsas_energy_efficiency_op}
              </div>
              <div
                className={`gradeE ${
                  props.result.gsas_energy_efficiency_op === "E"
                    ? "visible"
                    : "hidden"
                }`}
              >
                {props.result.gsas_energy_efficiency_op}
              </div>
              <div
                className={`gradeF ${
                  props.result.gsas_energy_efficiency_op === "F"
                    ? "visible"
                    : "hidden"
                }`}
              >
                {props.result.gsas_energy_efficiency_op}
              </div>
              <div
                className={`gradeG ${
                  props.result.gsas_energy_efficiency_op === "G"
                    ? "visible"
                    : "hidden"
                }`}
              >
                {props.result.gsas_energy_efficiency_op}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "102%",
                  transform: "translate(-50%, 0)",
                  width: "8px",
                  height: "8px",
                  borderRadius: "100%",
                  background: "#bde31c",
                  content: "''",
                }}
              />

              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "102%",
                  transform: "translate(-50%, 0)",
                  width: "8px",
                  height: "8px",
                  borderRadius: "100%",
                  background: "#bde31c",
                  content: "''",
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div
                style={{
                  backgroundColor: "#06267a",
                  borderRadius: "16px",
                  border: "3px solid #3f6522",
                  height: "80px",
                }}
                className="card widget-box-two"
              >
                <div className="card-body py-2 px-3">
                  <div className="media">
                    <div className="media-body wigdet-two-content">
                      <p
                        className="m-0 fz-20"
                        style={{ fontFamily: "DINNextLTPro-Condensed" }}
                      >
                        System Performance (kW/ton)
                      </p>
                      <h2
                        className="systemseerval"
                        style={{ fontFamily: "DINNextLTPro-BoldCondensed" }}
                      >
                        <span data-plugin="counterup">
                          {Number(props.result.trh).toFixed(2) || 0}
                        </span>{" "}
                        <i className="mdi mdi-arrow-up font-22"></i>
                      </h2>
                    </div>
                    <div className="avatar-lg ml-2 align-self-center">
                      <img className="iconImage" src={SEERICON} alt="icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div
                style={{
                  backgroundColor: "#06267a",
                  borderRadius: "16px",
                  border: "3px solid #3f6522",
                  height: "80px",
                }}
                className="card widget-box-two "
              >
                <div className="card-body py-2 px-3">
                  <div className="media">
                    <div className="media-body wigdet-two-content">
                      <p
                        className="m-0 fz-20"
                        style={{ fontFamily: "DINNextLTPro-Condensed" }}
                      >
                        System SEER
                      </p>
                      <h2
                        className="systemseerval"
                        style={{ fontFamily: "DINNextLTPro-BoldCondensed" }}
                      >
                        <span data-plugin="counterup">
                          {Number(props.result.system_performance_op).toFixed(
                            2
                          ) || 0}
                        </span>{" "}
                        <i className="mdi mdi-arrow-up font-22"></i>
                      </h2>
                    </div>
                    <div className="avatar-lg ml-2 align-self-center">
                      <img className="iconImage" src={SEERICON} alt="icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div
                style={{
                  backgroundColor: "#06267a",
                  borderRadius: "16px",
                  border: "3px solid #3f6522",
                  height: "80px",
                }}
                className="card widget-box-two "
              >
                <div className="card-body py-2 px-3">
                  <div className="media">
                    <div className="media-body wigdet-two-content">
                      <p
                        className="m-0 fz-20"
                        style={{ fontFamily: "DINNextLTPro-Condensed" }}
                      >
                        {" "}
                        Energy Efficiency Rating
                      </p>
                      <h2
                        className="systemseerval"
                        style={{ fontFamily: "DINNextLTPro-BoldCondensed" }}
                      >
                        {props.result.gsas_energy_efficiency_op || ""}
                      </h2>
                    </div>
                    <div className="avatar-lg ml-2 align-self-center">
                      <i
                        style={{ color: "#4b9f39" }}
                        className="dripicons-trophy font-22 avatar-title "
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsOperated;
