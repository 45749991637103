import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BaseURL,
  coEfficientUrl,
  projectDetails,
  uploadFileOrImage,
} from "../../globalURL";
import Dashboardsidebar from "./Dashboardsidebar";
import axios from "axios";
import Spinner from "./Spinner";
import CoefficientDefault from "./default-coefficients";
import "../../css/background_image.css";

import { toast } from "react-toastify";
// import FileUpload from "../dashboard_pages/otherdocumentfile";
// import TemperatureInput from "./TemperatureInput";
const CoEfficients = () => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    evaporator_chilled_water_outlet_1: "",
    evaporator_chilled_water_outlet_2: "",
    evaporator_chilled_water_outlet_3: "",
    evaporator_chilled_water_outlet_4: "",
    evaporator_chilled_water_outlet_5: "",

    curvature_coefficient_type: "User Input",
    temperature: "Celsius",
    co_efficients_other_document_file: "",
    condenser_temp_1: "",
    condenser_temp_2: "",
    condenser_temp_3: "",
    condenser_temp_4: "",
    condenser_temp_5: "",

    capacity_kw_1: "",
    capacity_kw_2: "",
    capacity_kw_3: "",
    capacity_kw_4: "",
    capacity_kw_5: "",
    capacity_kw_6: "",
    capacity_kw_7: "",
    capacity_kw_8: "",
    capacity_kw_9: "",
    capacity_kw_10: "",
    capacity_kw_11: "",
    capacity_kw_12: "",
    capacity_kw_13: "",
    capacity_kw_14: "",
    capacity_kw_15: "",
    capacity_kw_16: "",
    capacity_kw_17: "",
    capacity_kw_18: "",
    capacity_kw_19: "",
    capacity_kw_20: "",
    capacity_kw_21: "",
    capacity_kw_22: "",
    capacity_kw_23: "",
    capacity_kw_24: "",
    capacity_kw_25: "",

    power_input_kw_1: "",
    power_input_kw_2: "",
    power_input_kw_3: "",
    power_input_kw_4: "",
    power_input_kw_5: "",
    power_input_kw_6: "",
    power_input_kw_7: "",
    power_input_kw_8: "",
    power_input_kw_9: "",
    power_input_kw_10: "",
    power_input_kw_11: "",
    power_input_kw_12: "",
    power_input_kw_13: "",
    power_input_kw_14: "",
    power_input_kw_15: "",
    power_input_kw_16: "",
    power_input_kw_17: "",
    power_input_kw_18: "",
    power_input_kw_19: "",
    power_input_kw_20: "",
    power_input_kw_21: "",
    power_input_kw_22: "",
    power_input_kw_23: "",
    power_input_kw_24: "",
    power_input_kw_25: "",

    partial_chiller_capacity_kw_1: "",
    partial_chiller_capacity_kw_2: "",
    partial_chiller_capacity_kw_3: "",
    partial_chiller_capacity_kw_4: "",

    partial_power_input_kw_1: "",
    partial_power_input_kw_2: "",
    partial_power_input_kw_3: "",
    partial_power_input_kw_4: "",

    capft_a: -0.29861976,
    capft_b: 0.02996076,
    capft_c: -0.00080125,
    capft_d: 0.0736268,
    capft_e: -0.00032606,
    capft_f: 0.0003139,

    eirft_a: 0.51777196,
    eirft_b: -0.00400363,
    eirft_c: 0.00002028,
    eirft_d: 0.00698793,
    eirft_e: 0.0000829,
    eirft_f: -0.00015467,

    eirplr_a: 0.17149273,
    eirplr_b: 0.58820208,
    eirplr_c: 0.23737257,

    _id: localStorage.getItem("project_id"),
    access_token: localStorage.getItem("access_token"),
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  function handleFileUpload(event) {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    fetch(uploadFileOrImage, {
      method: "POST",
      body: formDataImage,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log("sss",data["data"]["full_file_address"]);
        setFormData({
          ...formData,
          ["co_efficients_other_document_file"]:
            data["data"]["full_file_address"],
        });
      });

    // console.log("fileDocName", fileDocName);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        coEfficientUrl,
        JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response?.data?.data?.id) {
        // setIsLoading(false)
        const { id } = response?.data?.data;
        // console.log("project_id", id);
        // alert(localStorage.getItem("phase"));
        if (localStorage.getItem("phase") === "Design and As Operated") {
          // operational
          navigate(`/operationalboth?project_id=${id}`);
        } else {
          navigate(`/final-result?project_id=${id}`);
        }
      } else {
        // alert(`${response.data.message}`);
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      // alert("An error occurred while submitting the form.");
      toast.error("Something error occured..!, Please try again.");
      console.error(error);
    }
  };

  const defaultProps = { formData, handleInputChange, handleSubmit };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    axios
      .post(
        projectDetails,
        JSON.stringify({ project_id: localStorage.getItem("project_id") }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        let result = res["data"]["data"];
        setFormData(result);
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dashboardsidebar currentPage="co-efficients" />
          {formData?.curvature_coefficient_type === "Default" ? (
            <CoefficientDefault
              {...defaultProps}
              selectedValue={formData?.curvature_coefficient_type}
              selectedTemperature={formData?.temperature}
            />
          ) : (
            <>
              <div className="content-page mt-0  class-back-ground border-colo-top">
                <div className="content">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-lg-11">
                        <div className="card transparent">
                          <div className="card-body pt-1">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="page-title-box">
                                  <h4 className="page-title colyellow-chiller">
                                    Electric chiller coefficient component
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <form action="" onSubmit={handleSubmit}>
                              <div className="row justify-content-end">
                                <div className="col-lg-4 col-md-5 col-sm-6">
                                  <div className="form-group mb-1">
                                    <div className="form-floating">
                                      <select
                                        name="curvature_coefficient_type"
                                        onChange={handleInputChange}
                                        defaultValue={"User Input"}
                                        value={"User Input"}
                                        id=""
                                        className="form-select w-100"
                                      >
                                        <option value="" hidden>
                                          --------- Curvature Coefficient Type
                                          ---------
                                        </option>
                                        {/* <option value="Default" selected={formData?.curvature_coefficient_type === ("Default" || formData?.curvature_coefficient_type)}>Default</option> */}
                                        <option
                                          value="User Input"
                                          selected={true}
                                        >
                                          User Input
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-4 col-md-5 col-sm-6">
                                  <div className="form-group mb-1">
                                    <div className="form-floating">
                                      <select
                                        name="temperature"
                                        onChange={handleInputChange}
                                        value={formData?.temperature}
                                        id=""
                                        className="form-select w-100"
                                      >
                                        <option value="" hidden>
                                          --------- Temperature ---------
                                        </option>
                                        <option
                                          value="Celsius"
                                          selected={
                                            formData?.temperature ===
                                            ("Celsius" || formData?.temperature)
                                          }
                                        >
                                          Celsius
                                        </option>
                                        <option
                                          value="Fahrenheit"
                                          selected={
                                            formData?.temperature ===
                                            ("Fahrenheit" ||
                                              formData?.temperature)
                                          }
                                        >
                                          Fahrenheit
                                        </option>
                                        <option
                                          value="Kelvin"
                                          selected={
                                            formData?.temperature ===
                                            ("Kelvin" || formData?.temperature)
                                          }
                                        >
                                          Kelvin
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="page-title-box">
                                    <h4 className="page-title pb-0 colyellow-chiller">
                                      Coefficient for Cooling Capacity
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-lg-12 coeffbox">
                                  <fieldset>
                                    <div className="row">
                                      <div className="col-lg-12 text-center ">
                                        <h4 style={{ color: "#000000" }}>
                                          Full Load Ratio
                                        </h4>
                                        <p
                                          style={{
                                            color: "#000000 !important",
                                          }}
                                        >
                                          <strong>
                                            Condenser Water Leaving Temperature
                                          </strong>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="table-responsive effcfz">
                                          <table className="table new-td-color">
                                            <tbody>
                                              <tr>
                                                <td rowSpan="11">
                                                  <strong>
                                                    Evaporator Chilled Water
                                                    Outlet Temperature
                                                  </strong>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.condenser_temp_1
                                                    }
                                                    onChange={handleInputChange}
                                                    name="condenser_temp_1"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.condenser_temp_2
                                                    }
                                                    onChange={handleInputChange}
                                                    name="condenser_temp_2"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.condenser_temp_3
                                                    }
                                                    onChange={handleInputChange}
                                                    name="condenser_temp_3"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.condenser_temp_4
                                                    }
                                                    onChange={handleInputChange}
                                                    name="condenser_temp_4"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.condenser_temp_5
                                                    }
                                                    onChange={handleInputChange}
                                                    name="condenser_temp_5"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>

                                              <tr>
                                                <td rowSpan="2">
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.evaporator_chilled_water_outlet_1
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="evaporator_chilled_water_outlet_1"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>{`Capacity (${formData.selected_units})`}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_1
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_1"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_2
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_2"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_3
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_3"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_4
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_4"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_5
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_5"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>{`Power Input (${formData?.selected_units})`}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_1
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_1"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_2
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_2"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  {/* <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_3
                                                    }
                                                    onChange={handleInputChange}
                                                     step="any"
                                                    name="power_input_kw_3"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  /> */}

                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_3
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_3"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  {/* <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_4
                                                    }
                                                    onChange={handleInputChange}
                                                     step="any"
                                                    name="power_input_kw_4"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  /> */}
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_4
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_4"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_5
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_5"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>

                                              <tr>
                                                <td rowSpan="2">
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.evaporator_chilled_water_outlet_2
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="evaporator_chilled_water_outlet_2"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>{`Capacity (${formData.selected_units})`}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_6
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_6"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_7
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_7"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_8
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_8"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_9
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_9"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_10
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_10"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>{`Power Input (${formData?.selected_units})`}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_6
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_6"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_7
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_7"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_8
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_8"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_9
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_9"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_10
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_10"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td rowSpan="2">
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.evaporator_chilled_water_outlet_3
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="evaporator_chilled_water_outlet_3"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>{`Capacity (${formData.selected_units})`}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_11
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_11"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_12
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_12"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_13
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_13"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_14
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_14"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_15
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_15"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>

                                              <tr>
                                                <td>{`Power Input (${formData?.selected_units})`}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_11
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_11"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_12
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_12"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_13
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_13"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_14
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_14"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_15
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_15"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td rowSpan="2">
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.evaporator_chilled_water_outlet_4
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="evaporator_chilled_water_outlet_4"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>{`Capacity (${formData.selected_units})`}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_16
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_16"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_17
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_17"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_18
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_18"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_19
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_19"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_20
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_20"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>{`Power Input (${formData?.selected_units})`}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_16
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_16"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_17
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_17"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_18
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_18"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_19
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_19"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_20
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_20"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td rowSpan="2">
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.evaporator_chilled_water_outlet_5
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="evaporator_chilled_water_outlet_5"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>{`Capacity (${formData.selected_units})`}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_21
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_21"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_22
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_22"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_23
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_23"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_24
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_24"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.capacity_kw_25
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="capacity_kw_25"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>{`Power Input (${formData?.selected_units})`}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_21
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_21"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_22
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_22"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_23
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_23"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_24
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_24"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_25
                                                    }
                                                    onChange={handleInputChange}
                                                    step="any"
                                                    name="power_input_kw_25"
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="page-title-box">
                                    <h4
                                      style={{
                                        color: "#000000",
                                        display: "inline-block",
                                      }}
                                    >
                                      Part Load Ratio
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="table-responsive">
                                    <table className="table custom_table_design">
                                      <thead className="">
                                        <tr className="head-color-table">
                                          <th>Chiller Load (%)</th>
                                          <th>{`Chiller Capacity (${formData.selected_units})`}</th>
                                          <th>{`Power Input (${formData?.selected_units})`}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>100</td>
                                          <td className="">
                                            <div className="table-form-input backtra">
                                              <input
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                                value={
                                                  formData?.partial_chiller_capacity_kw_1
                                                }
                                                onChange={handleInputChange}
                                                step="any"
                                                name="partial_chiller_capacity_kw_1"
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <div className="table-form-input backtra">
                                              <input
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                                value={
                                                  formData?.partial_power_input_kw_1
                                                }
                                                onChange={handleInputChange}
                                                step="any"
                                                name="partial_power_input_kw_1"
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>75</td>
                                          <td className="middleclas">
                                            <div className="table-form-input backtra">
                                              <input
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                                value={
                                                  formData?.partial_chiller_capacity_kw_2
                                                }
                                                onChange={handleInputChange}
                                                step="any"
                                                name="partial_chiller_capacity_kw_2"
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <div className="table-form-input backtra">
                                              <input
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                                value={
                                                  formData?.partial_power_input_kw_2
                                                }
                                                onChange={handleInputChange}
                                                step="any"
                                                name="partial_power_input_kw_2"
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>50</td>
                                          <td className="middleclas">
                                            <div className="table-form-input backtra">
                                              <input
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                                value={
                                                  formData?.partial_chiller_capacity_kw_3
                                                }
                                                onChange={handleInputChange}
                                                step="any"
                                                name="partial_chiller_capacity_kw_3"
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <div className="table-form-input backtra">
                                              <input
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                                value={
                                                  formData?.partial_power_input_kw_3
                                                }
                                                onChange={handleInputChange}
                                                step="any"
                                                name="partial_power_input_kw_3"
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>25</td>
                                          <td className="middleclas">
                                            <div className="table-form-input backtra">
                                              <input
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                                value={
                                                  formData?.partial_chiller_capacity_kw_4
                                                }
                                                onChange={handleInputChange}
                                                step="any"
                                                name="partial_chiller_capacity_kw_4"
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <div className="table-form-input backtra">
                                              <input
                                                type="number"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Ex.1144"
                                                value={
                                                  formData?.partial_power_input_kw_4
                                                }
                                                onChange={handleInputChange}
                                                step="any"
                                                name="partial_power_input_kw_4"
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="row">
                                <div className="col-lg-12 col-md-12 mb-2">
                                  <FileUpload
                                    formData={formData}
                                    fileDocName="co_efficients_other_document_file"
                                    setFormData={setFormData}
                                  />
                                </div>
                              </div> */}

                              <div className="row">
                                <div className="col-lg-4 col-md-6 mb-2">
                                  <label className="colyellow py-1">
                                    <span
                                      style={{
                                        color: "rgb(0, 0, 0)",
                                        fontWeight: "700",
                                      }}
                                      className="pl-2 pb-2 mb-2"
                                    >
                                      Document Upload
                                    </span>
                                  </label>
                                  <div className="form-floating mb-0 input-width-100">
                                    <input
                                      type="file"
                                      className="filestyle form-control py-1 px-2 inputclass-name-pd-0"
                                      name="co_efficients_other_document_file"
                                      onChange={handleFileUpload}
                                    />
                                    {formData.co_efficients_other_document_file ? (
                                      <span>
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            color: "yellow",
                                          }}
                                        >
                                          Previous File: &nbsp;
                                          <a
                                            rel="noreferrer"
                                            style={{
                                              color: "rgb(0, 217, 255)",
                                            }}
                                            href={`${BaseURL}${formData?.co_efficients_other_document_file}`}
                                            target="_blank"
                                          >
                                            Click Here
                                          </a>
                                        </p>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row justify-content-end prj">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
                                  <button
                                    type="submit"
                                    onClick={() => navigate("/fans")}
                                    className="btn-black mr-1 waves-effect waves-light"
                                  >
                                    <i className="fas fa-angle-left"></i> Back
                                  </button>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                                  <button
                                    type="submit"
                                    className="btn-pryellow mr-1 waves-effect waves-light"
                                  >
                                    Verify & Calculate{" "}
                                    <i className="fas fa-angle-right"></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CoEfficients;
