import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Dashboardsidebar from "./Dashboardsidebar";
// import CoolingTower from "./coolingtower";
import {
  BaseURL,
  fansUrl,
  projectDetails,
  uploadFileOrImage,
} from "../../globalURL";
import Spinner from "./Spinner";
import "../../css/background_image.css";

import { toast } from "react-toastify";
// import FileUpload from "../dashboard_pages/otherdocumentfile";
// import { CondenserContext } from "../../App";
const Fans = (props) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const [noOfFansValue, setNoOfFansValue] = useState(0);

  const initialFormData = {
    no_of_fans: noOfFansValue,
    fans_other_document_file: "",
    fan1_type: "",
    fan1_power_load_100: "",
    fan1_power_load_75: "",
    fan1_power_load_50: "",
    fan1_power_load_25: "",
    fan1_power_input_100: "",
    fan1_power_input_75: "",
    fan1_power_input_50: "",
    fan1_power_input_25: "",
    fan2_type: "",
    fan2_power_load_100: "",
    fan2_power_load_75: "",
    fan2_power_load_50: "",
    fan2_power_load_25: "",
    fan2_power_input_100: "",
    fan2_power_input_75: "",
    fan2_power_input_50: "",
    fan2_power_input_25: "",
    fan3_type: "",
    fan3_power_load_100: "",
    fan3_power_load_75: "",
    fan3_power_load_50: "",
    fan3_power_load_25: "",
    fan3_power_input_100: "",
    fan3_power_input_75: "",
    fan3_power_input_50: "",
    fan3_power_input_25: "",
    fan4_type: "",
    fan4_power_load_100: "",
    fan4_power_load_75: "",
    fan4_power_load_50: "",
    fan4_power_load_25: "",
    fan4_power_input_100: "",
    fan4_power_input_75: "",
    fan4_power_input_50: "",
    fan4_power_input_25: "",
    fan5_type: "",
    fan5_power_load_100: "",
    fan5_power_load_75: "",
    fan5_power_load_50: "",
    fan5_power_load_25: "",
    fan5_power_input_100: "",
    fan5_power_input_75: "",
    fan5_power_input_50: "",
    fan5_power_input_25: "",
    fan6_type: "",
    fan6_power_load_100: "",
    fan6_power_load_75: "",
    fan6_power_load_50: "",
    fan6_power_load_25: "",
    fan6_power_input_100: "",
    fan6_power_input_75: "",
    fan6_power_input_50: "",
    fan6_power_input_25: "",
    fan7_type: "",
    fan7_power_load_100: "",
    fan7_power_load_75: "",
    fan7_power_load_50: "",
    fan7_power_load_25: "",
    fan7_power_input_100: "",
    fan7_power_input_75: "",
    fan7_power_input_50: "",
    fan7_power_input_25: "",
    fan8_type: "",
    fan8_power_load_100: "",
    fan8_power_load_75: "",
    fan8_power_load_50: "",
    fan8_power_load_25: "",
    fan8_power_input_100: "",
    fan8_power_input_75: "",
    fan8_power_input_50: "",
    fan8_power_input_25: "",
    fan9_type: "",
    fan9_power_load_100: "",
    fan9_power_load_75: "",
    fan9_power_load_50: "",
    fan9_power_load_25: "",
    fan9_power_input_100: "",
    fan9_power_input_75: "",
    fan9_power_input_50: "",
    fan9_power_input_25: "",
    fan10_type: "",
    fan10_power_load_100: "",
    fan10_power_load_75: "",
    fan10_power_load_50: "",
    fan10_power_load_25: "",
    fan10_power_input_100: "",
    fan10_power_input_75: "",
    fan10_power_input_50: "",
    fan10_power_input_25: "",
    wet_bulb_temp_1: "",
    wet_bulb_temp_2: "",
    wet_bulb_temp_3: "",
    wet_bulb_temp_4: "",
    wet_bulb_temp_5: "",
    wet_bulb_temp_6: "",
    wet_bulb_temp_7: "",
    wet_bulb_temp_8: "",
    wet_bulb_temp_9: "",
    wet_bulb_temp_10: "",
    corresponding_leaving_water_temp_1: "",
    corresponding_leaving_water_temp_2: "",
    corresponding_leaving_water_temp_3: "",
    corresponding_leaving_water_temp_4: "",
    corresponding_leaving_water_temp_5: "",
    corresponding_leaving_water_temp_6: "",
    corresponding_leaving_water_temp_7: "",
    corresponding_leaving_water_temp_8: "",
    corresponding_leaving_water_temp_9: "",
    corresponding_leaving_water_temp_10: "",
    _id: localStorage.getItem("project_id"),
    access_token: localStorage.getItem("access_token"),
  };

  const [formData, setFormData] = useState(initialFormData);

  function handleIncrement() {
    if (noOfFansValue < 10) {
      setNoOfFansValue(noOfFansValue + 1);
      setFormData({ ...formData, ["no_of_fans"]: noOfFansValue + 1 });
    }
  }

  function handleDecrement() {
    if (noOfFansValue > 0) {
      setNoOfFansValue(noOfFansValue - 1);
      setFormData({ ...formData, ["no_of_fans"]: noOfFansValue - 1 });
    }
  }

  function handleFileUpload(event) {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    fetch(uploadFileOrImage, {
      method: "POST",
      body: formDataImage,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log("sss",data["data"]["full_file_address"]);
        setFormData({
          ...formData,
          ["fans_other_document_file"]: data["data"]["full_file_address"],
        });
      });

    // console.log("fileDocName", fileDocName);
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    axios
      .post(
        projectDetails,
        JSON.stringify({ project_id: localStorage.getItem("project_id") }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        let result = res["data"]["data"];
        setFormData(result);
        setNoOfFansValue(result.no_of_fans || 0);
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log("noOfFansValue: "+ noOfFansValue)
    // setFormData({ ...formData, ["no_of_fans"]: noOfFansValue });
    formData.no_of_fans = noOfFansValue;
    // console.log("formData", formData.no_of_fans);

    try {
      const response = await axios.post(fansUrl, JSON.stringify(formData), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      if (response?.data?.data?.id) {
        // const { id } = response?.data?.data;
        // navigate("/cooling-towers");
        navigate("/co-efficients");
      } else {
        // alert(`${response.data.message}`);
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      // alert("An error occurred while submitting the form.");
      toast.error("Something error occured..!, Please try again.");
      console.log(error);
    }
  };

  const FansData = () => {
    return (
      <>
        <div className="content-page mt-0 class-back-ground border-colo-top">
          <div className="content">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="card transparent">
                    <div className="card-body pt-1">
                      {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}

                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            className="page-title-box float-left mr-2"
                            style={{ padding: "40px 0px 30px 0" }}
                          >
                            <h4 className="table-title page-title-underline">
                              Cooling Tower Fan Motor
                            </h4>
                          </div>
                        </div>
                      </div>

                      {formData.condenser_cooling_media === "Air-cooled" ? (
                        <>
                          <div className="alert alert-success text-center">
                            <h3>
                              <b>Fans</b> Data Not Applicable to "Air Cooled
                              Chiller"{" "}
                            </h3>
                          </div>
                          <div className="row justify-content-between align-items-center prj">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
                              <Link
                                to={"/auxiliaries"}
                                className="btn-black mr-1 waves-effect waves-light"
                              >
                                Back
                              </Link>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                              <Link
                                className="btn-pryellow mr-1 waves-effect waves-light"
                                to="/cooling-towers"
                              >
                                Skip
                              </Link>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-lg-2 col-md-4">
                              <div
                                style={{ color: "#000000" }}
                                className="pt-2 fz-12 lablecolod"
                              >
                                Number Of Fans
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfFansValue}
                                    name="no_of_fans"
                                    className="text-center form-control"
                                    onChange={handleChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <h1>Fans : {noOfFansValue}</h1> */}
                          <form action="" method="POST" onSubmit={handleSubmit}>
                            <div className="row">
                              {noOfFansValue >= 1 ? (
                                <div className="col-lg-6 col-md-12">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="page-title-box">
                                        <h4 className="table-title page-sub-title-underline">
                                          Fan 1
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-floating mb-2">
                                        <select
                                          name="fan1_type"
                                          value={formData.fan1_type}
                                          defaultValue={"DEFAULT"}
                                          onChange={handleChange}
                                          id=""
                                          className="form-select w-100"
                                        >
                                          <option value="DEFAULT" hidden>
                                            Type
                                          </option>
                                          <option
                                            value="Fixed"
                                            selected={
                                              formData.fan1_type === "Fixed"
                                            }
                                          >
                                            Fixed
                                          </option>
                                          <option
                                            value="Variable"
                                            selected={
                                              formData.fan1_type === "Variable"
                                            }
                                          >
                                            {" "}
                                            Variable
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                      <div className="table-responsive">
                                        <table className="table custom_table_design">
                                          <thead>
                                            <tr className="head-color-table">
                                              <th>
                                                Chiller&nbsp;Load&nbsp;(%)
                                              </th>
                                              <th>Fan Load&nbsp;(%)</th>
                                              <th>{`Power Input (${formData?.selected_units})`}</th>
                                            </tr>
                                          </thead>
                                          <tbody className="table-form-input ">
                                            <tr>
                                              <td>100</td>
                                              <td className="middleclas">
                                                <div className="form-floating backtra">
                                                  <input
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    name="fan1_power_load_100"
                                                    value={
                                                      formData?.fan1_power_load_100
                                                    }
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-floating backtra">
                                                  <input
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    name="fan1_power_input_100"
                                                    value={
                                                      formData?.fan1_power_input_100
                                                    }
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>75</td>
                                              <td className="middleclas">
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan1_power_load_75"
                                                    value={
                                                      formData?.fan1_power_load_75
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan1_power_input_75"
                                                    value={
                                                      formData?.fan1_power_input_75
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>50</td>
                                              <td className="middleclas">
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan1_power_load_50"
                                                    value={
                                                      formData?.fan1_power_load_50
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan1_power_input_50"
                                                    value={
                                                      formData?.fan1_power_input_50
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>25</td>
                                              <td className="middleclas">
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan1_power_load_25"
                                                    value={
                                                      formData?.fan1_power_load_25
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan1_power_input_25"
                                                    value={
                                                      formData?.fan1_power_input_25
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    min={0}
                                                    step="0.01"
                                                    placeholder="Ex.1144"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              {noOfFansValue >= 2 ? (
                                <div className="col-lg-6 col-md-12">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="page-title-box">
                                        <h4 className="table-title page-sub-title-underline">
                                          Fan 2
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-floating mb-2">
                                        <select
                                          name="fan2_type"
                                          value={formData.fan2_type}
                                          onChange={handleChange}
                                          defaultValue={"DEFAULT"}
                                          id=""
                                          className="form-select w-100"
                                        >
                                          <option value="DEFAULT" hidden>
                                            Type
                                          </option>
                                          <option
                                            value="Fixed"
                                            selected={
                                              formData?.fan2_type ===
                                              ("Fixed" || formData.fan2_type)
                                            }
                                          >
                                            Fixed
                                          </option>
                                          <option
                                            value="Variable"
                                            selected={
                                              formData?.fan2_type ===
                                              ("Variable" || formData.fan2_type)
                                            }
                                          >
                                            {" "}
                                            Variable
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                      <div className="table-responsive">
                                        <table className="table custom_table_design">
                                          <thead>
                                            <tr className="head-color-table">
                                              <th>
                                                Chiller&nbsp;Load&nbsp;(%)
                                              </th>
                                              <th>Fan Load&nbsp;(%)</th>
                                              <th>{`Power Input (${formData?.selected_units})`}</th>
                                            </tr>
                                          </thead>
                                          <tbody className="table-form-input">
                                            <tr>
                                              <td>100</td>
                                              <td className="middleclas">
                                                <div className="form-floating backtra">
                                                  <input
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    name="fan2_power_load_100"
                                                    value={
                                                      formData?.fan2_power_load_100
                                                    }
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-floating backtra">
                                                  <input
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    name="fan2_power_input_100"
                                                    value={
                                                      formData?.fan2_power_input_100
                                                    }
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>75</td>
                                              <td className="middleclas">
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan2_power_load_75"
                                                    value={
                                                      formData?.fan2_power_load_75
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan2_power_input_75"
                                                    value={
                                                      formData?.fan2_power_input_75
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    min={0}
                                                    step="0.01"
                                                    placeholder="Ex.1144"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>50</td>
                                              <td className="middleclas">
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan2_power_load_50"
                                                    value={
                                                      formData?.fan2_power_load_50
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan2_power_input_50"
                                                    value={
                                                      formData?.fan2_power_input_50
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    min={0}
                                                    step="0.01"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>25</td>
                                              <td className="middleclas">
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan2_power_load_25"
                                                    value={
                                                      formData?.fan2_power_load_25
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-floating backtra">
                                                  <input
                                                    name="fan2_power_input_25"
                                                    value={
                                                      formData?.fan2_power_input_25
                                                    }
                                                    onChange={handleChange}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="Ex.1144"
                                                    min={0}
                                                    step="0.01"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              {noOfFansValue >= 3 ? (
                                <>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="page-title-box">
                                          <h4 className="table-title page-sub-title-underline">
                                            Fan 3
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-floating mb-2">
                                          <select
                                            name="fan3_type"
                                            value={formData.fan3_type}
                                            onChange={handleChange}
                                            defaultValue={"DEFAULT"}
                                            className="form-select w-100"
                                          >
                                            <option value="DEFAULT" hidden>
                                              Type
                                            </option>
                                            <option
                                              value="Fixed"
                                              selected={
                                                formData?.fan3_type ===
                                                ("Fixed" || formData.fan3_type)
                                              }
                                            >
                                              Fixed
                                            </option>
                                            <option
                                              value="Variable"
                                              selected={
                                                formData?.fan3_type ===
                                                ("Variable" ||
                                                  formData.fan3_type)
                                              }
                                            >
                                              {" "}
                                              Variable
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="table-responsive">
                                          <table className="table custom_table_design">
                                            <thead>
                                              <tr className="head-color-table">
                                                <th>
                                                  Chiller&nbsp;Load&nbsp;(%)
                                                </th>
                                                <th>Fan Load&nbsp;(%)</th>
                                                <th>{`Power Input (${formData?.selected_units})`}</th>
                                              </tr>
                                            </thead>
                                            <tbody className="table-form-input">
                                              <tr>
                                                <td>100</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      name="fan3_power_load_100"
                                                      value={
                                                        formData?.fan3_power_load_100
                                                      }
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      name="fan3_power_input_100"
                                                      value={
                                                        formData?.fan3_power_input_100
                                                      }
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>75</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan3_power_load_75"
                                                      value={
                                                        formData?.fan3_power_load_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan3_power_input_75"
                                                      value={
                                                        formData?.fan3_power_input_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>50</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan3_power_load_50"
                                                      value={
                                                        formData?.fan3_power_load_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan3_power_input_50"
                                                      value={
                                                        formData?.fan3_power_input_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>25</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan3_power_load_25"
                                                      value={
                                                        formData?.fan3_power_load_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan3_power_input_25"
                                                      value={
                                                        formData?.fan3_power_input_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {noOfFansValue >= 4 ? (
                                <>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="page-title-box">
                                          <h4 className="table-title page-sub-title-underline">
                                            Fan 4
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-floating mb-2">
                                          <select
                                            name="fan4_type"
                                            value={formData.fan4_type}
                                            onChange={handleChange}
                                            defaultValue={"DEFAULT"}
                                            id=""
                                            className="form-select w-100"
                                          >
                                            <option value="DEFAULT" hidden>
                                              Type
                                            </option>
                                            <option
                                              value="Fixed"
                                              selected={
                                                formData?.fan4_type ===
                                                ("Fixed" || formData.fan4_type)
                                              }
                                            >
                                              Fixed
                                            </option>
                                            <option
                                              value="Variable"
                                              selected={
                                                formData?.fan4_type ===
                                                ("Variable" ||
                                                  formData.fan4_type)
                                              }
                                            >
                                              {" "}
                                              Variable
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="table-responsive">
                                          <table className="table custom_table_design">
                                            <thead>
                                              <tr className="head-color-table">
                                                <th>
                                                  Chiller&nbsp;Load&nbsp;(%)
                                                </th>
                                                <th>Fan Load&nbsp;(%)</th>
                                                <th>{`Power Input (${formData?.selected_units})`}</th>
                                              </tr>
                                            </thead>
                                            <tbody className="table-form-input">
                                              <tr>
                                                <td>100</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      name="fan4_power_load_100"
                                                      value={
                                                        formData?.fan4_power_load_100
                                                      }
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      name="fan4_power_input_100"
                                                      value={
                                                        formData?.fan4_power_input_100
                                                      }
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>75</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan4_power_load_75"
                                                      value={
                                                        formData?.fan4_power_load_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan4_power_input_75"
                                                      value={
                                                        formData?.fan4_power_input_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>50</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan4_power_load_50"
                                                      value={
                                                        formData?.fan4_power_load_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan4_power_input_50"
                                                      value={
                                                        formData?.fan4_power_input_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      min={0}
                                                      step="0.01"
                                                      placeholder="Ex.1144"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>25</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan4_power_load_25"
                                                      value={
                                                        formData?.fan4_power_load_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan4_power_input_25"
                                                      value={
                                                        formData?.fan4_power_input_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {noOfFansValue >= 5 ? (
                                <>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="page-title-box">
                                          <h4 className="table-title page-sub-title-underline">
                                            Fan 5
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-floating mb-2">
                                          <select
                                            name="fan5_type"
                                            value={formData.fan5_type}
                                            onChange={handleChange}
                                            id=""
                                            defaultValue={"DEFAULT"}
                                            className="form-select w-100"
                                          >
                                            <option value="DEFAULT" hidden>
                                              Type
                                            </option>
                                            <option
                                              value="Fixed"
                                              selected={
                                                formData?.fan5_type ===
                                                ("Fixed" || formData.fan5_type)
                                              }
                                            >
                                              Fixed
                                            </option>
                                            <option
                                              value="Variable"
                                              selected={
                                                formData?.fan5_type ===
                                                ("Variable" ||
                                                  formData.fan5_type)
                                              }
                                            >
                                              {" "}
                                              Variable
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="table-responsive">
                                          <table className="table custom_table_design">
                                            <thead>
                                              <tr className="head-color-table">
                                                <th>
                                                  Chiller&nbsp;Load&nbsp;(%)
                                                </th>
                                                <th>Fan Load&nbsp;(%)</th>
                                                <th>{`Power Input (${formData?.selected_units})`}</th>
                                              </tr>
                                            </thead>
                                            <tbody className="table-form-input">
                                              <tr>
                                                <td>100</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      name="fan5_power_load_100"
                                                      value={
                                                        formData?.fan5_power_load_100
                                                      }
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      name="fan5_power_input_100"
                                                      value={
                                                        formData?.fan5_power_input_100
                                                      }
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>75</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan5_power_load_75"
                                                      value={
                                                        formData?.fan5_power_load_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan5_power_input_75"
                                                      value={
                                                        formData?.fan5_power_input_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>50</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan5_power_load_50"
                                                      value={
                                                        formData?.fan5_power_load_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan5_power_input_50"
                                                      value={
                                                        formData?.fan5_power_input_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>25</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan5_power_load_25"
                                                      value={
                                                        formData?.fan5_power_load_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan5_power_input_25"
                                                      value={
                                                        formData?.fan5_power_input_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {noOfFansValue >= 6 ? (
                                <>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="page-title-box">
                                          <h4 className="table-title page-sub-title-underline">
                                            Fan 6
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-floating mb-2">
                                          <select
                                            name="fan6_type"
                                            value={formData.fan6_type}
                                            onChange={handleChange}
                                            defaultValue={"DEFAULT"}
                                            id=""
                                            className="form-select w-100"
                                          >
                                            <option value="DEFAULT" hidden>
                                              Type
                                            </option>
                                            <option
                                              value="Fixed"
                                              selected={
                                                formData?.fan6_type ===
                                                ("Fixed" || formData.fan6_type)
                                              }
                                            >
                                              Fixed
                                            </option>
                                            <option
                                              value="Variable"
                                              selected={
                                                formData?.fan6_type ===
                                                ("Variable" ||
                                                  formData.fan6_type)
                                              }
                                            >
                                              {" "}
                                              Variable
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="table-responsive">
                                          <table className="table custom_table_design">
                                            <thead>
                                              <tr className="head-color-table">
                                                <th>
                                                  Chiller&nbsp;Load&nbsp;(%)
                                                </th>
                                                <th>Fan Load&nbsp;(%)</th>
                                                <th>{`Power Input (${formData?.selected_units})`}</th>
                                              </tr>
                                            </thead>
                                            <tbody className="table-form-input">
                                              <tr>
                                                <td>100</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      name="fan6_power_load_100"
                                                      value={
                                                        formData?.fan6_power_load_100
                                                      }
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      name="fan6_power_input_100"
                                                      value={
                                                        formData?.fan6_power_input_100
                                                      }
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>75</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan6_power_load_75"
                                                      value={
                                                        formData?.fan6_power_load_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan6_power_input_75"
                                                      value={
                                                        formData?.fan6_power_input_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>50</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan6_power_load_50"
                                                      value={
                                                        formData?.fan6_power_load_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan6_power_input_50"
                                                      value={
                                                        formData?.fan6_power_input_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>25</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan6_power_load_25"
                                                      value={
                                                        formData?.fan6_power_load_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan6_power_input_25"
                                                      value={
                                                        formData?.fan6_power_input_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {noOfFansValue >= 7 ? (
                                <>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="page-title-box">
                                          <h4 className="table-title page-sub-title-underline">
                                            Fan 7
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-floating mb-2">
                                          <select
                                            name="fan7_type"
                                            value={formData.fan7_type}
                                            onChange={handleChange}
                                            defaultValue={"DEFAULT"}
                                            className="form-select w-100"
                                          >
                                            <option value="DEFAULT" hidden>
                                              Type
                                            </option>
                                            <option
                                              value="Fixed"
                                              selected={
                                                formData?.fan7_type ===
                                                ("Fixed" || formData.fan7_type)
                                              }
                                            >
                                              Fixed
                                            </option>
                                            <option
                                              value="Variable"
                                              selected={
                                                formData?.fan7_type ===
                                                ("Variable" ||
                                                  formData.fan7_type)
                                              }
                                            >
                                              {" "}
                                              Variable
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="table-responsive">
                                          <table className="table custom_table_design">
                                            <thead>
                                              <tr className="head-color-table">
                                                <th>
                                                  Chiller&nbsp;Load&nbsp;(%)
                                                </th>
                                                <th>Fan Load&nbsp;(%)</th>
                                                <th>{`Power Input (${formData?.selected_units})`}</th>
                                              </tr>
                                            </thead>
                                            <tbody className="table-form-input">
                                              <tr>
                                                <td>100</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      name="fan7_power_load_100"
                                                      value={
                                                        formData?.fan7_power_load_100
                                                      }
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      name="fan7_power_input_100"
                                                      value={
                                                        formData?.fan7_power_input_100
                                                      }
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>75</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan7_power_load_75"
                                                      value={
                                                        formData?.fan7_power_load_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan7_power_input_75"
                                                      value={
                                                        formData?.fan7_power_input_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>50</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan7_power_load_50"
                                                      value={
                                                        formData?.fan7_power_load_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan7_power_input_50"
                                                      value={
                                                        formData?.fan7_power_input_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>25</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan7_power_load_25"
                                                      value={
                                                        formData?.fan7_power_load_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan7_power_input_25"
                                                      value={
                                                        formData?.fan7_power_input_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {noOfFansValue >= 8 ? (
                                <>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="page-title-box">
                                          <h4 className="table-title page-sub-title-underline">
                                            Fan 8
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-floating mb-2">
                                          <select
                                            name="fan8_type"
                                            value={formData.fan8_type}
                                            onChange={handleChange}
                                            defaultValue={"DEFAULT"}
                                            id=""
                                            className="form-select w-100"
                                          >
                                            <option value="DEFAULT" hidden>
                                              Type
                                            </option>
                                            <option
                                              value="Fixed"
                                              selected={
                                                formData?.fan8_type ===
                                                ("Fixed" || formData.fan8_type)
                                              }
                                            >
                                              Fixed
                                            </option>
                                            <option
                                              value="Variable"
                                              selected={
                                                formData?.fan8_type ===
                                                ("Variable" ||
                                                  formData.fan8_type)
                                              }
                                            >
                                              {" "}
                                              Variable
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="table-responsive">
                                          <table className="table custom_table_design">
                                            <thead>
                                              <tr className="head-color-table">
                                                <th>
                                                  Chiller&nbsp;Load&nbsp;(%)
                                                </th>
                                                <th>Fan Load&nbsp;(%)</th>
                                                <th>{`Power Input (${formData?.selected_units})`}</th>
                                              </tr>
                                            </thead>
                                            <tbody className="table-form-input">
                                              <tr>
                                                <td>100</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      name="fan8_power_load_100"
                                                      value={
                                                        formData?.fan8_power_load_100
                                                      }
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      name="fan8_power_input_100"
                                                      value={
                                                        formData?.fan8_power_input_100
                                                      }
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>75</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan8_power_load_75"
                                                      value={
                                                        formData?.fan8_power_load_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan8_power_input_75"
                                                      value={
                                                        formData?.fan8_power_input_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>50</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan8_power_load_50"
                                                      value={
                                                        formData?.fan8_power_load_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan8_power_input_50"
                                                      value={
                                                        formData?.fan8_power_input_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>25</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan8_power_load_25"
                                                      value={
                                                        formData?.fan8_power_load_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan8_power_input_25"
                                                      value={
                                                        formData?.fan8_power_input_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {noOfFansValue >= 9 ? (
                                <>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="page-title-box">
                                          <h4 className="table-title page-sub-title-underline">
                                            Fan 9
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-floating mb-2">
                                          <select
                                            name="fan9_type"
                                            value={formData.fan9_type}
                                            onChange={handleChange}
                                            defaultValue={"DEFAULT"}
                                            id=""
                                            className="form-select w-100"
                                          >
                                            <option value="DEFAULT" hidden>
                                              Type
                                            </option>
                                            <option
                                              value="Fixed"
                                              selected={
                                                formData?.fan9_type ===
                                                ("Fixed" || formData.fan9_type)
                                              }
                                            >
                                              Fixed
                                            </option>
                                            <option
                                              value="Variable"
                                              selected={
                                                formData?.fan9_type ===
                                                ("Variable" ||
                                                  formData.fan9_type)
                                              }
                                            >
                                              {" "}
                                              Variable
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="table-responsive">
                                          <table className="table custom_table_design">
                                            <thead>
                                              <tr className="head-color-table">
                                                <th>
                                                  Chiller&nbsp;Load&nbsp;(%)
                                                </th>
                                                <th>Fan Load&nbsp;(%)</th>
                                                <th>{`Power Input (${formData?.selected_units})`}</th>
                                              </tr>
                                            </thead>
                                            <tbody className="table-form-input">
                                              <tr>
                                                <td>100</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      name="fan9_power_load_100"
                                                      value={
                                                        formData?.fan9_power_load_100
                                                      }
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      name="fan9_power_input_100"
                                                      value={
                                                        formData?.fan9_power_input_100
                                                      }
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>75</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan9_power_load_75"
                                                      value={
                                                        formData?.fan9_power_load_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan9_power_input_75"
                                                      value={
                                                        formData?.fan9_power_input_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>50</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan9_power_load_50"
                                                      value={
                                                        formData?.fan9_power_load_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan9_power_input_50"
                                                      value={
                                                        formData?.fan9_power_input_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>25</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan9_power_load_25"
                                                      value={
                                                        formData?.fan9_power_load_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan9_power_input_25"
                                                      value={
                                                        formData?.fan9_power_input_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {noOfFansValue >= 10 ? (
                                <>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="page-title-box">
                                          <h4 className="table-title page-sub-title-underline">
                                            Fan 10
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-floating mb-2">
                                          <select
                                            name="fan10_type"
                                            value={formData.fan10_type}
                                            onChange={handleChange}
                                            defaultValue={"DEFAULT"}
                                            id=""
                                            className="form-select w-100"
                                          >
                                            <option value="DEFAULT" hidden>
                                              Type
                                            </option>
                                            <option
                                              value="Fixed"
                                              selected={
                                                formData?.fan10_type ===
                                                ("Fixed" || formData.fan10_type)
                                              }
                                            >
                                              Fixed
                                            </option>
                                            <option
                                              value="Variable"
                                              selected={
                                                formData?.fan10_type ===
                                                ("Variable" ||
                                                  formData.fan10_type)
                                              }
                                            >
                                              {" "}
                                              Variable
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="table-responsive">
                                          <table className="table custom_table_design">
                                            <thead>
                                              <tr className="head-color-table">
                                                <th>
                                                  Chiller&nbsp;Load&nbsp;(%)
                                                </th>
                                                <th>Fan Load&nbsp;(%)</th>
                                                <th>{`Power Input (${formData?.selected_units})`}</th>
                                              </tr>
                                            </thead>
                                            <tbody className="table-form-input">
                                              <tr>
                                                <td>100</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      name="fan10_power_load_100"
                                                      value={
                                                        formData?.fan10_power_load_100
                                                      }
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      name="fan10_power_input_100"
                                                      value={
                                                        formData?.fan10_power_input_100
                                                      }
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>75</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan10_power_load_75"
                                                      value={
                                                        formData?.fan10_power_load_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan10_power_input_75"
                                                      value={
                                                        formData?.fan10_power_input_75
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>50</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan10_power_load_50"
                                                      value={
                                                        formData?.fan10_power_load_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan10_power_input_50"
                                                      value={
                                                        formData?.fan10_power_input_50
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>25</td>
                                                <td className="middleclas">
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan10_power_load_25"
                                                      value={
                                                        formData?.fan10_power_load_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-floating backtra">
                                                    <input
                                                      name="fan10_power_input_25"
                                                      value={
                                                        formData?.fan10_power_input_25
                                                      }
                                                      onChange={handleChange}
                                                      type="number"
                                                      pattern="[0-9]*"
                                                      className="form-control"
                                                      id="floatingInput"
                                                      placeholder="Ex.1144"
                                                      min={0}
                                                      step="0.01"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            {/* <div className="row">
                              <div className="col-lg-12 col-md-12 mb-2">
                                <FileUpload
                                  formData={formData}
                                  fileDocName="fans_other_document_file"
                                  setFormData={setFormData}
                                />
                              </div>
                            </div> */}

                            <div className="row">
                              <div className="col-lg-12 col-md-12 mb-2">
                                <label className="colyellow py-1">
                                  <span
                                    className="pl-2 pb-2 mb-2 "
                                    style={{ color: "#000000" }}
                                  >
                                    Document Upload
                                  </span>
                                </label>
                                <div className="form-floating mb-0">
                                  <input
                                    type="file"
                                    className="filestyle form-control py-1 px-2 inputclass-name-pd-0"
                                    name="fans_other_document_file"
                                    onChange={handleFileUpload}
                                  />
                                  {formData.fans_other_document_file ? (
                                    <span>
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          color: "yellow",
                                        }}
                                      >
                                        Previous File: &nbsp;
                                        <a
                                          rel="noreferrer"
                                          style={{ color: "#FFFF00" }}
                                          href={`${BaseURL}${formData?.fans_other_document_file}`}
                                          target="_blank"
                                        >
                                          Click Here
                                        </a>
                                      </p>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group row justify-content-end prj">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
                                <button
                                  type="submit"
                                  onClick={() => navigate("/cooling-towers")}
                                  className="btn-black mr-1 waves-effect waves-light"
                                >
                                  <i className="fas fa-angle-left"></i> Back
                                </button>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6  text-right">
                                <button
                                  type="submit"
                                  className="btn-pryellow mr-1 waves-effect waves-light"
                                >
                                  Next <i className="fas fa-angle-right"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      )}

                      {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {/* {!embedded && <Dashboardsidebar currentPage={"fans"} />} */}
      {/* {showFCT ? <CoolingTower /> : FansData()} */}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dashboardsidebar currentPage={"fans"} />
          {FansData()}
        </>
      )}
    </>
  );
};

export default Fans;
