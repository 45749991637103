// export const BaseURL = "https://apis.arcadia-suite.com/";
// const url = new URL(window.location.href);
// alert(url.hostname);

const getBaseUrl = () => {
  return process.env.REACT_APP_API_URL || "http://localhost:5000/";
  // let baseurl = "";
  // if (url.hostname === 'app.arcadia-suite.com') {
  //     baseurl = "https://apis.arcadia-suite.com/";
  // } else if (url.hostname === 'staging.apis.arcadia-suite.com') {
  //     baseurl = "https://apis.arcadia-suite.com/";
  // } else if (url.hostname === 'localhost') {
  //     baseurl = "https://staging.apis.arcadia-suite.com/"
  // } else {
  //     baseurl = "https://staging.apis.arcadia-suite.com/";
  // }
  // return baseurl;
};

export const BaseURL = getBaseUrl();
// alert(BaseURL);
export const GoogleMapApiKey = "AIzaSyAz7eBSILkvevhoc65tDmnqOIMJVw9BEpw";
export const jsonLoadUrl = BaseURL + "user/downoadfile";
export const projectDetails = BaseURL + "project/details";
export const projectSteponeURL = BaseURL + "project/stepone";
export const chillersUrl = BaseURL + "project/steptwo";
export const thermalStoreTwoUrl = BaseURL + "project/stepthree";
export const pumpsAndOthersUrl = BaseURL + "project/stepfour";
export const pumpsAndOthersAuxUrl = BaseURL + "project/stepfour_aux";
export const fansAndCoolingTowersUrl = BaseURL + "project/stepfive";
export const fansUrl = BaseURL + "project/stepfive_fans";
export const coolingUrl = BaseURL + "project/stepfive_cooling";
export const coEfficientUrl = BaseURL + "project/stepsix";
export const operationPhaseURL = BaseURL + "project/stepseven";
export const resultAsBuiltUrl = BaseURL + "project/result-";

export const websiteVisitCountIncrementUrl =
  BaseURL + "user/visit_count_increse";

export const packagesUrl = BaseURL + "user/packages";
export const feedbacksUrl = BaseURL + "user/feedbacks";
export const totalProjectsUrl = BaseURL + "user/my_projects";
export const passwordChangeUrl = BaseURL + "user/change_password";
export const forgotPasswordURL = BaseURL + "user/forgot_password";
export const designPhaseCalcUrlUsingProjectId =
  BaseURL + "project/design_phase_all";

export const operationURL = BaseURL + "project/operation";
export const LoginURL = BaseURL + "auth/login";
export const aboutUsURL = BaseURL + "cmspages";
export const cmsPagesURL = BaseURL + "cmspages/list";
export const termsAndConditionsURL = BaseURL + "cmspages/terms";
export const privacyURL = BaseURL + "cmspages/privacy";
export const countriesListURL = BaseURL + "countries";
export const statesListURL = BaseURL + "states";
export const citiesListURL = BaseURL + "cities";
export const checkEmailExistsURL = BaseURL + "auth/check_email_exists";
export const checkUsernameExistsURL = BaseURL + "auth/check_username_exists";
export const RegisterURL = BaseURL + "auth/register";
export const resendOtpURL = BaseURL + "auth/resend_otp";
export const verifyOtpURL = BaseURL + "auth/verify_otp";
export const userProfileURL = BaseURL + "user/details";
export const userProfileUpdateURL = BaseURL + "user/profile_update";

export const uploadFileOrImage = BaseURL + "upload";

export const supportRequestsUrl = BaseURL + "user/support_requests";
export const addSupportRequestUrl = BaseURL + "user/add_support_request";

export const deleteProjectUrl = BaseURL + "project/delete";
export const userDashboardStatusUrl = BaseURL + "user/dashboard";

export const mainPackages = BaseURL + "admin/subscriptions/list";
export const subPackages = BaseURL + "admin/subscriptions/subs";
export const allPackages = BaseURL + "admin/subscriptions/packages";

export const packageDetailsSingleUrl = BaseURL + "admin/subscriptions/details";

export const projectLocationUpdate = BaseURL + "user/project_loc_update";

export const paymentTransactionCreation =
  BaseURL + "payments/create_transaction";
export const paymentTransactionListUrl = BaseURL + "payments/list";
export const paymentTransactionDetailsUrl =
  BaseURL + "payments/transaction_details";

export const landingPageDataURL = BaseURL + "cmspages/landingpage";

export const sendContactEmailUrl = BaseURL + "user/send_contact_mail";
export const sendSubscribeEmailUrl = BaseURL + "user/news_letter_email_req";
export const uploadJSONUrl = BaseURL + "user/site_settings";
export const updateProjectStatusPendingUrl =
  BaseURL + "project/update_project_status_as_pending";
export const paymentZeroTransactionUrl = BaseURL + "payments/zero_payment";

export const deletePaymentTransacionUrl =
  BaseURL + "payments/delete_transaction";
export const feedbackAddUrl = BaseURL + "user/add_feedbacks";
