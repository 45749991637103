import React, { useState, useRef, useEffect } from "react";
import Dashboardsidebar from "./Dashboardsidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { operationURL, projectDetails } from "../../globalURL";
import { toast } from "react-toastify";
const OperationalPhase = () => {
  const [isReadonlyEnable, setIsReadonlyEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // const [isThermalSubscribed, setIsThermalSubscribed] = useState(false);
  // const [isRenewalSubscribed, setIsRenewalSubscribed] = useState(false);
  // const [isSubmeterSubscribed, setIsSubmeterSubscribed] = useState(false);

  const [noOfChillersOpValue, setNoOfChillersOpValue] = useState(1);
  const [noOfTRHsOpValue, setNoOfTRHsOpValue] = useState(1);
  const [noOfPrimaryPumpsValue, setNoOfPrimaryPumpsValue] = useState(1);
  const [noOfSecondaryPumpsValue, setNoOfSecondaryPumpsValue] = useState(1);
  const [noOfCondenserPumpsValue, setNoOfCondenserPumpsValue] = useState(1);
  const [noOfTertiaryPumpsValue, setNoOfTertiaryPumpsValue] = useState(1);
  const [noOfCoolingTowersValue, setNoOfCoolingTowersValue] = useState(1);
  const [noOfSubMetersValue, setNoOfSubMetersValue] = useState(1);
  const [noOfYearlyPowerConsumptionValue, setNoOfYearlyPowerConsumptionValue] =
    useState(1);

  const [is_thermal_store_available_op, setIs_thermal_store_available_op] =
    useState("No");
  const [is_renewal_energy_applicable_op, setIs_renewal_energy_applicable_op] =
    useState("No");
  const [is_submeter_available_op, setIs_submeter_available_op] =
    useState("No");

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setIs_thermal_store_available_op(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      is_thermal_store_available_op: value,
    }));
  };

  const handleRenewalRadioChange = (e) => {
    const value = e.target.value;
    setIs_renewal_energy_applicable_op(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      is_renewal_energy_applicable_op: value,
    }));
  };

  const handleSubmeterRadioChange = (e) => {
    const value = e.target.value;
    setIs_submeter_available_op(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      is_submeter_available_op: value,
    }));
  };

  const initialFormData = {
    condenser_cooling_media_op: "",
    compressor_type_op: "",
    nominal_cop_op: "",
    minimum_start_up_capacity_op: "",
    is_thermal_store_available_op: "No",
    chiller_1_capacity_op: "",
    chiller_2_capacity_op: "",
    chiller_3_capacity_op: "",
    chiller_4_capacity_op: "",
    chiller_5_capacity_op: "",
    chiller_6_capacity_op: "",
    chiller_7_capacity_op: "",
    chiller_8_capacity_op: "",
    chiller_9_capacity_op: "",
    chiller_10_capacity_op: "",
    capacity_of_trhs_op: "",
    capacity_of_trhs_2_op: "",
    capacity_of_trhs_3_op: "",
    capacity_of_trhs_4_op: "",
    capacity_of_trhs_5_op: "",
    capacity_of_trhs_6_op: "",
    capacity_of_trhs_7_op: "",
    capacity_of_trhs_8_op: "",
    capacity_of_trhs_9_op: "",
    capacity_of_trhs_10_op: "",

    cooling_tower_type_op: "",
    no_of_primary_pumps_op: noOfPrimaryPumpsValue,
    no_of_secondary_pumps_op: noOfSecondaryPumpsValue,
    no_of_condenser_pumps_op: noOfCondenserPumpsValue,
    no_of_tertiary_pumps_op: noOfTertiaryPumpsValue,
    no_of_cooling_towers_op: noOfCoolingTowersValue,
    no_of_sub_meters_op: noOfSubMetersValue,
    no_of_yearly_power_consumption_op: noOfYearlyPowerConsumptionValue,
    thermal_store_capacity_op: "",

    operating_hours_per_year_op: "",
    primary_pump_type_op: "",
    capacity_of_primary_pumps_op: "",
    capacity_of_primary_pumps_2_op: "",
    capacity_of_primary_pumps_3_op: "",
    capacity_of_primary_pumps_4_op: "",
    capacity_of_primary_pumps_5_op: "",
    capacity_of_primary_pumps_6_op: "",
    capacity_of_primary_pumps_7_op: "",
    capacity_of_primary_pumps_8_op: "",
    capacity_of_primary_pumps_9_op: "",
    capacity_of_primary_pumps_10_op: "",
    secondary_pump_type_op: "",
    capacity_of_secondary_pumps_op: "",
    capacity_of_secondary_pumps_2_op: "",
    capacity_of_secondary_pumps_3_op: "",
    capacity_of_secondary_pumps_4_op: "",
    capacity_of_secondary_pumps_5_op: "",
    capacity_of_secondary_pumps_6_op: "",
    capacity_of_secondary_pumps_7_op: "",
    capacity_of_secondary_pumps_8_op: "",
    capacity_of_secondary_pumps_9_op: "",
    capacity_of_secondary_pumps_10_op: "",
    condenser_pump_type_op: "",
    capacity_of_condenser_pumps_op: "",
    capacity_of_condenser_pumps_2_op: "",
    capacity_of_condenser_pumps_3_op: "",
    capacity_of_condenser_pumps_4_op: "",
    capacity_of_condenser_pumps_5_op: "",
    capacity_of_condenser_pumps_6_op: "",
    capacity_of_condenser_pumps_7_op: "",
    capacity_of_condenser_pumps_8_op: "",
    capacity_of_condenser_pumps_9_op: "",
    capacity_of_condenser_pumps_10_op: "",
    tertiary_pump_type_op: "",
    capacity_of_tertiary_pumps_op: "",
    capacity_of_tertiary_pumps_2_op: "",
    capacity_of_tertiary_pumps_3_op: "",
    capacity_of_tertiary_pumps_4_op: "",
    capacity_of_tertiary_pumps_5_op: "",
    capacity_of_tertiary_pumps_6_op: "",
    capacity_of_tertiary_pumps_7_op: "",
    capacity_of_tertiary_pumps_8_op: "",
    capacity_of_tertiary_pumps_9_op: "",
    capacity_of_tertiary_pumps_10_op: "",
    capacity_of_cooling_towers_op: "",
    capacity_of_cooling_towers_2_op: "",
    capacity_of_cooling_towers_3_op: "",
    capacity_of_cooling_towers_4_op: "",
    capacity_of_cooling_towers_5_op: "",
    capacity_of_cooling_towers_6_op: "",
    capacity_of_cooling_towers_7_op: "",
    capacity_of_cooling_towers_8_op: "",
    capacity_of_cooling_towers_9_op: "",
    capacity_of_cooling_towers_10_op: "",
    power_consumption_per_years_op: "",
    power_consumption_per_years_2_op: "",
    power_consumption_per_years_3_op: "",
    power_consumption_per_years_4_op: "",
    power_consumption_per_years_5_op: "",
    power_consumption_per_years_6_op: "",
    power_consumption_per_years_7_op: "",
    power_consumption_per_years_8_op: "",
    power_consumption_per_years_9_op: "",
    power_consumption_per_years_10_op: "",

    sub_meter_primary_pump_power_input: "",
    sub_meter_secondary_pump_power_input: "",
    sub_meter_condenser_pump_power_input: "",
    sub_meter_tertiary_pump_power_input: "",
    sub_meter_cooling_tower_power_input: "",
    sub_meter_chiller_infomation: "",
    sub_meter_termal_store_infomation: "",
    sub_meter_renewal_energy_infomation: "",

    is_renewal_energy_generated_op: "",
    _id: localStorage.getItem("project_id"),
  };

  const [formData, setFormData] = useState({
    // is_thermal_store_available_op: "No",
    // is_renewal_energy_applicable_op: "No",
    // is_submeter_available_op: "No",

    condenser_cooling_media_op: "",
    compressor_type_op: "",
    nominal_cop_op: "",
    minimum_start_up_capacity_op: "",
    chiller_1_capacity_op: "",
    chiller_2_capacity_op: "",
    chiller_3_capacity_op: "",
    chiller_4_capacity_op: "",
    chiller_5_capacity_op: "",
    chiller_6_capacity_op: "",
    chiller_7_capacity_op: "",
    chiller_8_capacity_op: "",
    chiller_9_capacity_op: "",
    chiller_10_capacity_op: "",

    capacity_of_trhs_op: "",
    capacity_of_trhs_2_op: "",
    capacity_of_trhs_3_op: "",
    capacity_of_trhs_4_op: "",
    capacity_of_trhs_5_op: "",
    capacity_of_trhs_6_op: "",
    capacity_of_trhs_7_op: "",
    capacity_of_trhs_8_op: "",
    capacity_of_trhs_9_op: "",
    capacity_of_trhs_10_op: "",

    cooling_tower_type_op: "",
    no_of_chillers_op: noOfChillersOpValue,
    no_of_trhs_op: noOfTRHsOpValue,
    no_of_primary_pumps_op: noOfPrimaryPumpsValue,
    no_of_secondary_pumps_op: noOfSecondaryPumpsValue,
    no_of_condenser_pumps_op: noOfCondenserPumpsValue,
    no_of_tertiary_pumps_op: noOfTertiaryPumpsValue,
    no_of_cooling_towers_op: noOfCoolingTowersValue,
    no_of_sub_meters_op: noOfSubMetersValue,
    no_of_yearly_power_consumption_op: noOfYearlyPowerConsumptionValue,
    thermal_store_capacity_op: "",

    operating_hours_per_year_op: "",
    primary_pump_type_op: "",
    capacity_of_primary_pumps_op: "",
    capacity_of_primary_pumps_2_op: "",
    capacity_of_primary_pumps_3_op: "",
    capacity_of_primary_pumps_4_op: "",
    capacity_of_primary_pumps_5_op: "",
    capacity_of_primary_pumps_6_op: "",
    capacity_of_primary_pumps_7_op: "",
    capacity_of_primary_pumps_8_op: "",
    capacity_of_primary_pumps_9_op: "",
    capacity_of_primary_pumps_10_op: "",
    secondary_pump_type_op: "",
    capacity_of_secondary_pumps_op: "",
    capacity_of_secondary_pumps_2_op: "",
    capacity_of_secondary_pumps_3_op: "",
    capacity_of_secondary_pumps_4_op: "",
    capacity_of_secondary_pumps_5_op: "",
    capacity_of_secondary_pumps_6_op: "",
    capacity_of_secondary_pumps_7_op: "",
    capacity_of_secondary_pumps_8_op: "",
    capacity_of_secondary_pumps_9_op: "",
    capacity_of_secondary_pumps_10_op: "",
    condenser_pump_type_op: "",
    capacity_of_condenser_pumps_op: "",
    capacity_of_condenser_pumps_2_op: "",
    capacity_of_condenser_pumps_3_op: "",
    capacity_of_condenser_pumps_4_op: "",
    capacity_of_condenser_pumps_5_op: "",
    capacity_of_condenser_pumps_6_op: "",
    capacity_of_condenser_pumps_7_op: "",
    capacity_of_condenser_pumps_8_op: "",
    capacity_of_condenser_pumps_9_op: "",
    capacity_of_condenser_pumps_10_op: "",
    tertiary_pump_type_op: "",
    capacity_of_tertiary_pumps_op: "",
    capacity_of_tertiary_pumps_2_op: "",
    capacity_of_tertiary_pumps_3_op: "",
    capacity_of_tertiary_pumps_4_op: "",
    capacity_of_tertiary_pumps_5_op: "",
    capacity_of_tertiary_pumps_6_op: "",
    capacity_of_tertiary_pumps_7_op: "",
    capacity_of_tertiary_pumps_8_op: "",
    capacity_of_tertiary_pumps_9_op: "",
    capacity_of_tertiary_pumps_10_op: "",
    capacity_of_cooling_towers_op: "",
    capacity_of_cooling_towers_2_op: "",
    capacity_of_cooling_towers_3_op: "",
    capacity_of_cooling_towers_4_op: "",
    capacity_of_cooling_towers_5_op: "",
    capacity_of_cooling_towers_6_op: "",
    capacity_of_cooling_towers_7_op: "",
    capacity_of_cooling_towers_8_op: "",
    capacity_of_cooling_towers_9_op: "",
    capacity_of_cooling_towers_10_op: "",
    power_consumption_per_years_op: "",
    power_consumption_per_years_2_op: "",
    power_consumption_per_years_3_op: "",
    power_consumption_per_years_4_op: "",
    power_consumption_per_years_5_op: "",
    power_consumption_per_years_6_op: "",
    power_consumption_per_years_7_op: "",
    power_consumption_per_years_8_op: "",
    power_consumption_per_years_9_op: "",
    power_consumption_per_years_10_op: "",

    sub_meter_primary_pump_power_input: "",
    sub_meter_secondary_pump_power_input: "",
    sub_meter_condenser_pump_power_input: "",
    sub_meter_tertiary_pump_power_input: "",
    sub_meter_cooling_tower_power_input: "",
    sub_meter_chiller_infomation: "",
    sub_meter_termal_store_infomation: "",
    sub_meter_renewal_energy_infomation: "",

    is_renewal_energy_generated_op: "",
    _id: localStorage.getItem("project_id"),
  });

  const [formDataErrors, setFormDataErrors] = useState(initialFormData);

  const project_id = localStorage.getItem("project_id");

  // console.log("project_id operational", project_id)

  function handleChillersOpIncrement() {
    if (noOfChillersOpValue < 10) {
      setNoOfChillersOpValue(noOfChillersOpValue + 1);
      setFormData({
        ...formData,
        ["no_of_chillers_op"]: noOfChillersOpValue + 1,
      });
    }
  }

  function handleChillersOpDecrement() {
    if (noOfChillersOpValue > 1) {
      setNoOfChillersOpValue(noOfChillersOpValue - 1);
      setFormData({
        ...formData,
        ["no_of_chillers_op"]: noOfChillersOpValue - 1,
      });
    }
  }

  function handleTRHsOpIncrement() {
    if (noOfTRHsOpValue < 10) {
      setNoOfTRHsOpValue(noOfTRHsOpValue + 1);
      setFormData({
        ...formData,
        ["no_of_trhs_op"]: noOfTRHsOpValue + 1,
      });
    }
  }

  function handleTRHsOpDecrement() {
    if (noOfTRHsOpValue > 1) {
      setNoOfTRHsOpValue(noOfTRHsOpValue - 1);
      setFormData({
        ...formData,
        ["no_of_trhs_op"]: noOfTRHsOpValue - 1,
      });
    }
  }

  function handlePrimaryPumpsIncrement() {
    if (noOfPrimaryPumpsValue < 10) {
      setNoOfPrimaryPumpsValue(noOfPrimaryPumpsValue + 1);
      setFormData({
        ...formData,
        ["no_of_primary_pumps_op"]: noOfPrimaryPumpsValue + 1,
      });
    }
  }

  function handlePrimaryPumpsDecrement() {
    if (noOfPrimaryPumpsValue > 0) {
      setNoOfPrimaryPumpsValue(noOfPrimaryPumpsValue - 1);
      setFormData({
        ...formData,
        ["no_of_primary_pumps_op"]: noOfPrimaryPumpsValue - 1,
      });
    }
  }

  function handleSecondaryPumpsIncrement() {
    if (noOfSecondaryPumpsValue < 10) {
      setNoOfSecondaryPumpsValue(noOfSecondaryPumpsValue + 1);
      setFormData({
        ...formData,
        ["no_of_secondary_pumps_op"]: noOfSecondaryPumpsValue + 1,
      });
    }
  }

  function handleSecondaryPumpsDecrement() {
    if (noOfSecondaryPumpsValue > 0) {
      setNoOfSecondaryPumpsValue(noOfSecondaryPumpsValue - 1);
      setFormData({
        ...formData,
        ["no_of_secondary_pumps_op"]: noOfSecondaryPumpsValue - 1,
      });
    }
  }

  function handleCondenserPumpsIncrement() {
    if (noOfCondenserPumpsValue < 10) {
      setNoOfCondenserPumpsValue(noOfCondenserPumpsValue + 1);
      setFormData({
        ...formData,
        ["no_of_condenser_pumps_op"]: noOfCondenserPumpsValue + 1,
      });
    }
  }

  function handleCondenserPumpsDecrement() {
    if (noOfCondenserPumpsValue > 0) {
      setNoOfCondenserPumpsValue(noOfCondenserPumpsValue - 1);
      setFormData({
        ...formData,
        ["no_of_condenser_pumps_op"]: noOfCondenserPumpsValue - 1,
      });
    }
  }

  function handleTertiaryPumpsIncrement() {
    if (noOfTertiaryPumpsValue < 10) {
      setNoOfTertiaryPumpsValue(noOfTertiaryPumpsValue + 1);
      setFormData({
        ...formData,
        ["no_of_tertiary_pumps_op"]: noOfTertiaryPumpsValue + 1,
      });
    }
  }

  function handleTertiaryPumpsDecrement() {
    if (noOfTertiaryPumpsValue > 0) {
      setNoOfTertiaryPumpsValue(noOfTertiaryPumpsValue - 1);
      setFormData({
        ...formData,
        ["no_of_tertiary_pumps_op"]: noOfTertiaryPumpsValue - 1,
      });
    }
  }

  function handleCoolingTowersIncrement() {
    if (noOfCoolingTowersValue < 10) {
      setNoOfCoolingTowersValue(noOfCoolingTowersValue + 1);
      setFormData({
        ...formData,
        ["no_of_cooling_towers_op"]: noOfCoolingTowersValue + 1,
      });
    }
  }

  function handleCoolingTowersDecrement() {
    if (noOfCoolingTowersValue > 0) {
      setNoOfCoolingTowersValue(noOfCoolingTowersValue - 1);
      setFormData({
        ...formData,
        ["no_of_cooling_towers_op"]: noOfCoolingTowersValue - 1,
      });
    }
  }

  function handleSubMetersIncrement() {
    if (noOfSubMetersValue < 10) {
      setNoOfSubMetersValue(noOfSubMetersValue + 1);
      setFormData({
        ...formData,
        ["no_of_sub_meters_op"]: noOfSubMetersValue + 1,
      });
    }
  }

  function handleSubMetersDecrement() {
    if (noOfSubMetersValue > 0) {
      setNoOfSubMetersValue(noOfSubMetersValue - 1);
      setFormData({
        ...formData,
        ["no_of_sub_meters_op"]: noOfSubMetersValue - 1,
      });
    }
  }

  function handleYearlyPowerConsumptionIncrement() {
    if (noOfYearlyPowerConsumptionValue < 10) {
      setNoOfYearlyPowerConsumptionValue(noOfYearlyPowerConsumptionValue + 1);
      setFormData({
        ...formData,
        ["no_of_yearly_power_consumption_op"]:
          noOfYearlyPowerConsumptionValue + 1,
      });
    }
  }

  function handleYearlyPowerConsumptionDecrement() {
    if (noOfYearlyPowerConsumptionValue > 1) {
      setNoOfYearlyPowerConsumptionValue(noOfYearlyPowerConsumptionValue - 1);
      setFormData({
        ...formData,
        ["no_of_yearly_power_consumption_op"]:
          noOfYearlyPowerConsumptionValue - 1,
      });
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (formDataErrors[name] && value) {
      setFormDataErrors({ ...formDataErrors, [name]: "" });
    }
  };

  const validateFormData = () => {
    const errors = {};
    const requiredFields = [
      // "minimum_start_up_capacity_op",
      // "secondary_pump_type_op",
      "chiller_1_capacity_op",
      // "primary_pump_type_op",
      // "nominal_cop_op",
      // "condenser_pump_type_op",
      // "capacity_of_trhs_op",
      // "tertiary_pump_type_op",
      // "cooling_tower_type_op",
      // "capacity_of_primary_pumps_op",
      // "capacity_of_secondary_pumps_op",
      // "capacity_of_condenser_pumps_op",
      // "capacity_of_tertiary_pumps_op",
      // "capacity_of_cooling_towers_op",
      // "condenser_cooling_media_op",
      // "compressor_type_op",
      // "operating_hours_per_year_op",
      "no_of_yearly_power_consumption_op",
      "power_consumption_per_years_op",
      "capacity_of_trhs_op",
    ];

    requiredFields.forEach((field) => {
      if (formData[field] === "") {
        errors[field] = `* Please enter ${field}`;
      }
    });

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.no_of_chillers_op = noOfChillersOpValue;
    formData.no_of_trhs_op = noOfTRHsOpValue;
    formData.no_of_primary_pumps_op = noOfPrimaryPumpsValue;
    formData.no_of_secondary_pumps_op = noOfSecondaryPumpsValue;
    formData.no_of_condenser_pumps_op = noOfCondenserPumpsValue;
    formData.no_of_tertiary_pumps_op = noOfTertiaryPumpsValue;
    formData.no_of_cooling_towers_op = noOfCoolingTowersValue;
    formData.no_of_yearly_power_consumption_op =
      noOfYearlyPowerConsumptionValue;
    // console.log(" :::::: ", formData);
    // return false
    // setIsLoading(true)
    // navigate("/as-operated-result",{state:{"message":"TEST"}})
    // return false;

    const errors = validateFormData();

    if (Object.keys(errors).length > 0) {
      setFormDataErrors(errors);
      return;
    }
    setFormDataErrors({});

    if (Object.keys(errors).length === 0) {
      // console.log("formData", formData);
    }

    try {
      const response = await axios.post(
        operationURL,
        JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("response", response);
      const { gsas_energy_efficiency, system_performance, system_seer, trh } =
        response?.data;
      if (response.data.status === "valid") {
        // navigate(`/as-operated-result?project_id=${project_id}`);
        navigate("/result-as-built?project_id=" + project_id, {
          state: { message: "TEST" },
        });
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const dataFetchedRef = useRef(false);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    axios
      .post(
        projectDetails,
        JSON.stringify({ project_id: localStorage.getItem("project_id") }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        if (res?.data?.data?.re_edit_project === "yes") {
          setIsReadonlyEnable(true);
        } else {
          setIsReadonlyEnable(false);
        }
        // alert(res["data"]["data"]["no_of_chillers_op_op"])
        let result = res["data"]["data"];
        // console.log("oper", result);
        // const noOfChillersOp = result?.no_of_chillers_op;

        // alert(result?.no_of_chillers_op);
        setNoOfChillersOpValue(parseInt(result?.no_of_chillers_op || 1));
        setNoOfTRHsOpValue(parseInt(result.no_of_trhs_op) || 1);
        setNoOfPrimaryPumpsValue(result.no_of_primary_pumps_op || 1);
        setNoOfSecondaryPumpsValue(result.no_of_secondary_pumps_op || 1);
        setNoOfCondenserPumpsValue(result.no_of_condenser_pumps_op || 1);
        setNoOfTertiaryPumpsValue(result.no_of_tertiary_pumps_op || 1);
        setNoOfCoolingTowersValue(result.no_of_cooling_towers_op || 1);
        setNoOfYearlyPowerConsumptionValue(
          result.no_of_yearly_power_consumption_op || 1
        );
        setFormData(result);
        setIsLoading(false);
        // alert(noOfChillers)
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {/* {
        isLoading ? <Spinner /> :
        (<> */}
      <Dashboardsidebar currentPage="operational" />
      <div className="content-page mt-0 class-back-ground border-colo-top">
        <div className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="card transparent">
                  <div className="card-body pt-1">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className="page-title-box">
                          <h4 className="page-title colyellow-chiller">
                            Operational Phases
                          </h4>
                        </div>
                      </div>
                    </div>
                    <form
                      action="#"
                      onSubmit={handleSubmit}
                      method="post"
                      encType="multipart/form-data"
                      autoComplete="off"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label
                            style={{
                              color: "#00d9ff",
                              fontSize: "14px",
                              fontWeight: "700",
                            }}
                            htmlFor=""
                            className=""
                          >
                            Chiller Type
                          </label>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-6">
                          <div className="form-floating mb-2">
                            <select
                              name="condenser_cooling_media_op"
                              disabled={isReadonlyEnable}
                              value={formData?.condenser_cooling_media_op}
                              className="form-select w-100"
                              onChange={handleInputChange}
                            >
                              <option value="" disabled>
                                {" "}
                                ------------------------- Cooling
                                Media-------------------------
                              </option>
                              <option
                                value="Water-cooled"
                                selected={
                                  formData?.condenser_cooling_media_op ===
                                  ("Water-cooled" ||
                                    formData?.condenser_cooling_media_op)
                                }
                              >
                                Water-cooled
                              </option>
                              <option
                                value="Air-cooled"
                                selected={
                                  formData?.condenser_cooling_media_op ===
                                  ("Air-cooled" ||
                                    formData?.condenser_cooling_media_op)
                                }
                              >
                                Air-cooled
                              </option>
                            </select>

                            {formDataErrors.condenser_cooling_media_op && (
                              <div className="text-danger">
                                {formDataErrors.condenser_cooling_media_op}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label className="lable-colord" htmlFor="">
                            Compressor Type
                          </label>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-6">
                          <div className="form-floating mb-2">
                            <select
                              disabled={isReadonlyEnable}
                              name="compressor_type_op"
                              id=""
                              className="form-select w-100"
                              value={formData?.compressor_type_op}
                              onChange={handleInputChange}
                            >
                              <option value="" disabled>
                                ------------------------- Compressor Type
                                -------------------------
                              </option>
                              <option value="Screw"> Screw</option>
                              <option value="Scroll">Scroll </option>
                              <option value="Reciprocating">
                                Reciprocating
                              </option>
                              <option
                                value="Centrifugal"
                                style={{
                                  display:
                                    formData?.condenser_cooling_media_op ===
                                    "Water-cooled"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                Centrifugal
                              </option>
                            </select>

                            {formDataErrors.compressor_type_op && (
                              <div className="text-danger">
                                {formDataErrors.compressor_type_op}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center my-2">
                          <label
                            htmlFor=""
                            className="styblue"
                            style={{ color: "'#00d9ff" }}
                          >
                            Chiller Information
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-8">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <i className="fe-arrow-right fa-lg chiler-color"></i>
                              <label
                                className="lable-colord"
                                htmlFor="floatingInput"
                              >
                                Number Of Chillers
                              </label>
                            </div>
                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      disabled={isReadonlyEnable}
                                      onClick={handleChillersOpDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfChillersOpValue}
                                    name="no_of_chillers_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      disabled={isReadonlyEnable}
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleChillersOpIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {Array.from(
                          { length: noOfChillersOpValue },
                          (_, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                              <div className="form-floating mb-2">
                                <input
                                  disabled={isReadonlyEnable}
                                  value={
                                    formData[`chiller_${index + 1}_capacity_op`]
                                  }
                                  onChange={handleInputChange}
                                  name={`chiller_${index + 1}_capacity_op`}
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity (kW)
                                </label>
                                {formDataErrors?.[
                                  `chiller_${index + 1}_capacity_op`
                                ] && (
                                  <div className="text-danger">** required</div>
                                )}

                                {/* {formDataErrors[
                                  `chiller_${index + 1}_capacity_op`
                                ] && (
                                  <div className="row">
                                    <div className="text-danger">
                                      {
                                        formDataErrors[
                                          `chiller_${index + 1}_capacity_op`
                                        ]
                                      }
                                    </div>
                                  </div>
                                )} */}
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-12  col-md-6">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label className="lable-colord" htmlFor="">
                            Total Annual Operating Hours
                          </label>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-floating mb-2">
                            <input
                              value={formData?.operating_hours_per_year_op}
                              onChange={handleInputChange}
                              name="operating_hours_per_year_op"
                              type="number"
                              pattern="[0-9]*"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Ex.1144"
                            />
                            <label htmlFor="floatingInput">
                              Operating Hours per year
                            </label>
                            {formDataErrors.operating_hours_per_year_op && (
                              <div className="text-danger">
                                ** Please enter Operating Hours per year
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label
                                className="lable-colord"
                                htmlFor="floatingInput"
                              >
                                TRH
                              </label>
                            </div>

                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleTRHsOpDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfTRHsOpValue}
                                    name="no_of_trhs_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleTRHsOpIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {Array.from({ length: noOfTRHsOpValue }).map(
                          (_, index) => {
                            const capacityName =
                              index === 0
                                ? "capacity_of_trhs_op"
                                : `capacity_of_trhs_${index + 1}_op`;

                            return (
                              <div key={index} className="col-lg-3 col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    value={formData?.[capacityName]}
                                    onChange={handleInputChange}
                                    name={capacityName}
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Ex.1144"
                                  />
                                  <label htmlFor="floatingInput">
                                    Year-{index + 1}
                                  </label>
                                  {formDataErrors?.[capacityName] && (
                                    <div className="text-danger">
                                      ** required
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-6">
                          <div className="">
                            <i className="fe-arrow-right fa-lg chiler-color"></i>
                            <label className="lable-colord" htmlFor="remember2">
                              Nominal COP (Kw/kw)
                            </label>
                          </div>
                          <div className="form-floating mb-2">
                            <input
                              type="number"
                              pattern="[0-9]*"
                              step="any"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Ex.1144"
                              name="nominal_cop_op"
                              value={formData?.nominal_cop_op}
                              onChange={handleInputChange}
                            />

                            {formDataErrors.nominal_cop_op && (
                              <div className="text-danger">
                                {formDataErrors.nominal_cop_op}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-6 col-sm-5 col-12">
                          <div className="">
                            <i className="fe-arrow-right fa-lg chiler-color"></i>
                            <label
                              className="lable-colord pl-0"
                              htmlFor="remember2"
                            >
                              Minimum Startup Capacity (%)
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                          <div className="form-floating mb-2">
                            <input
                              type="number"
                              pattern="[0-9]*"
                              step="any"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Ex.1144"
                              name="minimum_start_up_capacity_op"
                              value={formData?.minimum_start_up_capacity_op}
                              onChange={handleInputChange}
                            />

                            {formDataErrors.minimum_start_up_capacity_op && (
                              <div className="text-danger">
                                {formDataErrors.minimum_start_up_capacity_op}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label
                            className="lable-colord pl-0 yellowcol"
                            htmlFor=""
                          >
                            Thermal Store Information
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-5">
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-6">
                              <label htmlFor="" className="text-black">
                                Is Thermal Store Available?
                              </label>
                            </div>

                            <div className="col-lg-6 col-md-12 position-relative">
                              <div className="d-flex">
                                <div className="radiocust">
                                  <label className="text-black">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_thermal_store_available_op"
                                      value="No"
                                      // onChange={handleInputChange}
                                      onChange={handleRadioChange}
                                      checked={
                                        is_thermal_store_available_op ===
                                          "No" ||
                                        formData?.is_thermal_store_available_op ===
                                          "No" ||
                                        formData?.is_thermal_store_available_op ===
                                          ""
                                      }
                                    />
                                    No
                                  </label>
                                </div>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <div className="radiocust">
                                  <label className="text-black">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_thermal_store_available_op"
                                      value="Yes"
                                      onChange={handleRadioChange}
                                      // onChange={handleInputChange}
                                      checked={
                                        is_thermal_store_available_op ===
                                          "Yes" ||
                                        formData?.is_thermal_store_available_op ===
                                          "Yes"
                                      }
                                    />
                                    Yes
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {formData?.is_thermal_store_available_op === "Yes" && (
                          <>
                            <div className="col-lg-12">
                              <div className="form-floating mb-2">
                                <input
                                  value={formData?.thermal_store_capacity_op}
                                  onChange={handleInputChange}
                                  name="thermal_store_capacity_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity (kW)
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label className="lable-colord pl-0 yellowcol">
                            Pumps Information
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          {" "}
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label
                            htmlFor=""
                            className="lable-colord pl-0 yellowcol"
                          >
                            Primary Pumps
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              name="primary_pump_type_op"
                              value={formData?.primary_pump_type_op}
                              onChange={handleInputChange}
                              id=""
                              className="form-select w-100"
                            >
                              <option value="" disabled>
                                ------------------------- Motor Type
                                -------------------------
                              </option>
                              <option
                                value="Fixed"
                                selected={
                                  formData?.primary_pump_type_op ===
                                  ("Fixed" || formData?.primary_pump_type_op)
                                }
                              >
                                Fixed
                              </option>
                              <option
                                value="Varible"
                                selected={
                                  formData?.primary_pump_type_op ===
                                  ("Varible" || formData?.primary_pump_type_op)
                                }
                              >
                                Varible
                              </option>
                            </select>
                            {formDataErrors.primary_pump_type_op && (
                              <div
                                style={{ width: 400 }}
                                className="text-danger"
                              >
                                {formDataErrors.primary_pump_type_op}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label htmlFor="floatingInput text-black clas-blac">
                                Number of Primary Pumps
                              </label>
                            </div>
                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handlePrimaryPumpsDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfPrimaryPumpsValue}
                                    name="no_of_primary_pumps_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handlePrimaryPumpsIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {Array.from({ length: noOfPrimaryPumpsValue }).map(
                          (_, index) => {
                            const capacityName =
                              index === 0
                                ? "capacity_of_primary_pumps_op"
                                : `capacity_of_primary_pumps_${index + 1}_op`;

                            return (
                              <div key={index} className="col-lg-3 col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    value={formData?.[capacityName]}
                                    onChange={handleInputChange}
                                    name={capacityName}
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Ex.1144"
                                  />
                                  <label htmlFor="floatingInput">
                                    Capacity(kW)
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label
                            htmlFor=""
                            className="lable-colord pl-0 yellowcol"
                          >
                            Secondary Pumps
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              name="secondary_pump_type_op"
                              value={formData?.secondary_pump_type_op}
                              onChange={handleInputChange}
                              id=""
                              className="form-select w-100"
                            >
                              <option value="" disabled>
                                ------------------------- Motor Type
                                -------------------------
                              </option>
                              <option
                                value="Fixed"
                                selected={
                                  formData?.secondary_pump_type_op ===
                                  ("Fixed" || formData?.secondary_pump_type_op)
                                }
                              >
                                Fixed
                              </option>
                              <option
                                value="Varible"
                                selected={
                                  formData?.secondary_pump_type_op ===
                                  ("Varible" ||
                                    formData?.secondary_pump_type_op)
                                }
                              >
                                Varible
                              </option>
                            </select>
                            {formDataErrors.secondary_pump_type_op && (
                              <div
                                style={{ width: 400 }}
                                className="text-danger"
                              >
                                {formDataErrors.secondary_pump_type_op}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label htmlFor="floatingInput">
                                Number of Secondary Pumps
                              </label>
                            </div>

                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleSecondaryPumpsDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfSecondaryPumpsValue}
                                    name="no_of_secondary_pumps_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleSecondaryPumpsIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {Array.from({ length: noOfSecondaryPumpsValue }).map(
                          (_, index) => {
                            const capacityName =
                              index === 0
                                ? "capacity_of_secondary_pumps_op"
                                : `capacity_of_secondary_pumps_${index + 1}_op`;

                            return (
                              <div key={index} className="col-lg-3 col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    value={formData?.[capacityName]}
                                    onChange={handleInputChange}
                                    name={capacityName}
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Ex.1144"
                                  />
                                  <label htmlFor="floatingInput">
                                    Capacity(kW)
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label
                            htmlFor=""
                            className="lable-colord pl-0 yellowcol"
                          >
                            Condenser Pumps
                          </label>
                        </div>

                        <div className="col-lg-6 col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              name="condenser_pump_type_op"
                              value={formData?.condenser_pump_type_op}
                              onChange={handleInputChange}
                              id=""
                              className="form-select w-100"
                            >
                              <option value="" disabled>
                                ------------------------- Motor Type
                                -------------------------
                              </option>
                              <option
                                value="Fixed"
                                selected={
                                  formData?.condenser_pump_type_op ===
                                  ("Fixed" || formData?.condenser_pump_type_op)
                                }
                              >
                                Fixed
                              </option>
                              <option
                                value="Varible"
                                selected={
                                  formData?.condenser_pump_type_op ===
                                  ("Varible" ||
                                    formData?.condenser_pump_type_op)
                                }
                              >
                                Varible
                              </option>
                            </select>
                            {formDataErrors.condenser_pump_type_op && (
                              <div
                                style={{ width: 400 }}
                                className="text-danger"
                              >
                                {formDataErrors.condenser_pump_type_op}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label htmlFor="floatingInput">
                                Number of Condenser Pumps
                              </label>
                            </div>
                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleCondenserPumpsDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfCondenserPumpsValue}
                                    name="no_of_condenser_pumps_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleCondenserPumpsIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {Array.from({ length: noOfCondenserPumpsValue }).map(
                          (_, index) => {
                            const capacityName =
                              index === 0
                                ? "capacity_of_condenser_pumps_op"
                                : `capacity_of_condenser_pumps_${index + 1}_op`;

                            return (
                              <div key={index} className="col-lg-3 col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    value={formData?.[capacityName]}
                                    onChange={handleInputChange}
                                    name={capacityName}
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Ex.1144"
                                  />
                                  <label htmlFor="floatingInput">
                                    Capacity(kW)
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label
                            htmlFor=""
                            className="lable-colord pl-0 yellowcol"
                          >
                            Tertiary Pumps
                          </label>
                        </div>

                        <div className="col-lg-6 col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              name="tertiary_pump_type_op"
                              value={formData?.tertiary_pump_type_op}
                              onChange={handleInputChange}
                              id=""
                              className="form-select w-100"
                            >
                              <option value="" disabled>
                                ------------------------- Motor Type
                                -------------------------
                              </option>
                              <option
                                value="Fixed"
                                selected={
                                  formData?.tertiary_pump_type_op ===
                                  ("Fixed" || formData?.tertiary_pump_type_op)
                                }
                              >
                                Fixed
                              </option>
                              <option
                                value="Varible"
                                selected={
                                  formData?.tertiary_pump_type_op ===
                                  ("Varible" || formData?.tertiary_pump_type_op)
                                }
                              >
                                Varible
                              </option>
                            </select>
                            {formDataErrors.tertiary_pump_type_op && (
                              <div
                                style={{ width: 400 }}
                                className="text-danger"
                              >
                                {formDataErrors.tertiary_pump_type_op}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label htmlFor="floatingInput">
                                Number of Tertiary Pumps
                              </label>
                            </div>
                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleTertiaryPumpsDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfTertiaryPumpsValue}
                                    name="no_of_tertiary_pumps_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleTertiaryPumpsIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {Array.from({ length: noOfTertiaryPumpsValue }).map(
                          (_, index) => {
                            const capacityName =
                              index === 0
                                ? "capacity_of_tertiary_pumps_op"
                                : `capacity_of_tertiary_pumps_${index + 1}_op`;

                            return (
                              <div key={index} className="col-lg-3 col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    value={formData?.[capacityName]}
                                    onChange={handleInputChange}
                                    name={capacityName}
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Ex.1144"
                                  />
                                  <label htmlFor="floatingInput">
                                    Capacity(kW)
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label
                            htmlFor=""
                            className="lable-colord pl-0 yellowcol"
                          >
                            Cooling Tower Information
                          </label>
                        </div>

                        <div className="col-lg-6 col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              name="cooling_tower_type_op"
                              value={formData?.cooling_tower_type_op}
                              onChange={handleInputChange}
                              id=""
                              className="form-select w-100"
                            >
                              <option value="" disabled>
                                ------------------------- Motor Type
                                -------------------------
                              </option>
                              <option
                                value="Fixed"
                                selected={
                                  formData?.cooling_tower_type_op ===
                                  ("Fixed" || formData?.cooling_tower_type_op)
                                }
                              >
                                Fixed
                              </option>
                              <option
                                value="Varible"
                                selected={
                                  formData?.cooling_tower_type_op ===
                                  ("Varible" || formData?.cooling_tower_type_op)
                                }
                              >
                                Varible
                              </option>
                            </select>
                            {formDataErrors.cooling_tower_type_op && (
                              <div
                                style={{ width: 400 }}
                                className="text-danger"
                              >
                                {formDataErrors.cooling_tower_type_op}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label htmlFor="floatingInput">
                                Number of Cooling Towers
                              </label>
                            </div>
                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleCoolingTowersDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfCoolingTowersValue}
                                    name="no_of_cooling_towers_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleCoolingTowersIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {Array.from({ length: noOfCoolingTowersValue }).map(
                          (_, index) => {
                            const capacityName =
                              index === 0
                                ? "capacity_of_cooling_towers_op"
                                : `capacity_of_cooling_towers_${index + 1}_op`;

                            return (
                              <div key={index} className="col-lg-3 col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    value={formData?.[capacityName]}
                                    onChange={handleInputChange}
                                    name={capacityName}
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Ex.1144"
                                  />
                                  <label htmlFor="floatingInput">
                                    Capacity(kW)
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label
                            htmlFor=""
                            className="lable-colord pl-0 yellowcol"
                          >
                            Renewable Energy Information
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              <i className="fe-arrow-right fa-lg chiler-color"></i>
                              <label
                                htmlFor=""
                                className="lable-colord pl-0 yellowcol"
                              >
                                Is Renewable Energy Available?
                              </label>
                            </div>

                            <div className="col-lg-12 col-md-12 position-relative">
                              <div className="d-flex">
                                <div className="radiocust">
                                  <label className="text-black">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_renewal_energy_applicable_op"
                                      value="No"
                                      onChange={handleRenewalRadioChange}
                                      // onChange={handleInputChange}
                                      checked={
                                        is_renewal_energy_applicable_op ===
                                          "No" ||
                                        formData.is_renewal_energy_applicable_op ===
                                          "No" ||
                                        formData.is_renewal_energy_applicable_op ===
                                          ""
                                      }
                                    />
                                    No
                                  </label>
                                </div>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <div className="radiocust">
                                  <label className="text-black ">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_renewal_energy_applicable_op"
                                      value="Yes"
                                      // onChange={handleInputChange}
                                      onChange={handleRenewalRadioChange}
                                      checked={
                                        is_renewal_energy_applicable_op ===
                                          "Yes" ||
                                        formData?.is_renewal_energy_applicable_op ===
                                          "Yes"
                                      }
                                    />
                                    Yes
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {formData?.is_renewal_energy_applicable_op ===
                          "Yes" && (
                          <>
                            <div className="col-lg-12">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.is_renewal_energy_generated_op
                                  }
                                  onChange={handleInputChange}
                                  name="is_renewal_energy_generated_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  % of Energy Generated from Renewable Sources
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label
                            htmlFor=""
                            className="lable-colord pl-0 yellowcol"
                          >
                            Sub-metering Information
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="row">
                            <div className="col-lg-6">
                              <label htmlFor="" className="text-black">
                                Is Sub-metering Available?
                              </label>
                            </div>
                            <div className="col-lg-6 col-md-12 position-relative">
                              <div className="d-flex">
                                <div className="radiocust">
                                  <label className="text-black">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_submeter_available_op"
                                      value="No"
                                      onChange={handleSubmeterRadioChange}
                                      checked={
                                        // is_submeter_available_op === "No" ||
                                        formData?.is_submeter_available_op ===
                                          "No" ||
                                        is_submeter_available_op === "No" ||
                                        formData?.is_submeter_available_op ===
                                          ""
                                      }
                                      // onChange={handleInputChange}
                                    />
                                    No
                                  </label>
                                </div>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <div className="radiocust">
                                  <label className="text-black">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_submeter_available_op"
                                      onChange={handleSubmeterRadioChange}
                                      value="Yes"
                                      // onChange={handleInputChange}
                                      checked={
                                        formData?.is_submeter_available_op ===
                                          "Yes" ||
                                        is_submeter_available_op === "Yes"
                                      }
                                    />
                                    Yes
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center">
                        {formData?.is_submeter_available_op === "Yes" && (
                          <>
                            <div className="col-lg-12 col-md-12">
                              <div className="table-responsive">
                                <table className="table ">
                                  <thead className="bg-success-border-green">
                                    <tr className="head-color-table">
                                      <th>Pump type</th>
                                      <th>Power Input (kWh / year)</th>
                                    </tr>
                                  </thead>
                                  <tbody className="border-blue-sky">
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-black">
                                            Primary Pump
                                          </p>
                                        </div>
                                      </td>
                                      <td className="middleclas">
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_primary_pump_power_input
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_primary_pump_power_input"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control backtra"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-black">
                                            Secondary Pump
                                          </p>
                                        </div>
                                      </td>
                                      <td className="middleclas">
                                        <div className="form-floating form-color-none">
                                          <input
                                            value={
                                              formData?.sub_meter_secondary_pump_power_input
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_secondary_pump_power_input"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control backtra"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-black">
                                            Condenser Pump
                                          </p>
                                        </div>
                                      </td>
                                      <td className="middleclas">
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_condenser_pump_power_input
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_condenser_pump_power_input"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control backtra"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-black">
                                            Tertiary Pump
                                          </p>
                                        </div>
                                      </td>
                                      <td className="middleclas">
                                        <div className="form-floating form-color-none">
                                          <input
                                            value={
                                              formData?.sub_meter_tertiary_pump_power_input
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_tertiary_pump_power_input"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control backtra"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-black">
                                            Cooling Tower
                                          </p>
                                        </div>
                                      </td>
                                      <td className="middleclas">
                                        <div className="form-floating form-color-none">
                                          <input
                                            name="sub_meter_cooling_tower_power_input"
                                            value={
                                              formData.sub_meter_cooling_tower_power_input
                                            }
                                            onChange={handleInputChange}
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control backtra"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-black">
                                            Chiller information
                                          </p>
                                        </div>
                                      </td>
                                      <td className="middleclas">
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_chiller_infomation
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_chiller_infomation"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control backtra"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-black">
                                            Thermal Store Infomation
                                          </p>
                                        </div>
                                      </td>
                                      <td className="middleclas">
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_termal_store_infomation
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_termal_store_infomation"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control backtra"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-black">
                                            Renewable energy information
                                          </p>
                                        </div>
                                      </td>
                                      <td className="middleclas">
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_renewal_energy_infomation
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_renewal_energy_infomation"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control backtra"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <i className="fe-arrow-right fa-lg chiler-color"></i>
                          <label
                            htmlFor=""
                            className="lable-colord pl-0 yellowcol"
                          >
                            Yearly Power Consumption Per Year
                          </label>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-lg-6 align-self-center">
                            <label
                              className="font-weight-bold text-black"
                              htmlFor="floatingInput"
                            >
                              Number of Years
                            </label>
                          </div>
                          <div className="col-lg-5">
                            <div className="row">
                              {/* <label className="font-weight-bold" htmlFor="floatingInput">Number of Years</label> */}
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={
                                        handleYearlyPowerConsumptionDecrement
                                      }
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfYearlyPowerConsumptionValue}
                                    name="no_of_yearly_power_consumption_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={
                                        handleYearlyPowerConsumptionIncrement
                                      }
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        {Array.from({
                          length: noOfYearlyPowerConsumptionValue,
                        }).map((_, index) => {
                          const capacityName =
                            index === 0
                              ? "power_consumption_per_years_op"
                              : `power_consumption_per_years_${index + 1}_op`;

                          return (
                            <div key={index} className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={formData?.[capacityName]}
                                  onChange={handleInputChange}
                                  name={capacityName}
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                              </div>
                              {formDataErrors.power_consumption_per_years_op && (
                                <div className="text-danger">
                                  *** Required Power Consumption Per Year
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="form-group row justify-content-end prj">
                        <div className="col-lg-12 text-right">
                          <button
                            type="submit"
                            className="btn btn-pryellow mr-1 waves-effect waves-light"
                          >
                            Verify & Calculate
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </>)
      } */}
    </>
  );
};

export default OperationalPhase;
