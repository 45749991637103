import React from 'react';
import Dashboardsidebar from './Dashboardsidebar';
// import {  } from 'simplify-commerce';
const Payments = () => {
        // const {project_id} = route.props
//     // const simplify = new Simplify({
//     //     publicKey: 'sbpb_Y2M4MWYwODktOTcxZi00ZTMwLWE1NTctMTM2MGIwYWYyNTdh',
//     //     privateKey: 'vnGpWmw8Mn1CujP09U350l6KSh8pu1OuW6uk+7H/4ed5YFFQL0ODSXAOkNtXTToq'
//     // });
//     Simplify.getClient({ 
//         publicKey: 'sbpb_Y2M4MWYwODktOTcxZi00ZTMwLWE1NTctMTM2MGIwYWYyNTdh',
//         privateKey: 'vnGpWmw8Mn1CujP09U350l6KSh8pu1OuW6uk+7H/4ed5YFFQL0ODSXAOkNtXTToq'
//     });
    // const [paymentForm, setPaymentForm] = useState({
    //     amount: '',
    //     currency: '',
    //     description: '',
    //     reference: ''
    // });

    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     setPaymentForm({
    //         ...paymentForm,
    //         [name]: value
    //     });
    // };
    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     const payment = await Simplify.payment.create({
    //         amount: paymentForm.amount,
    //         currency: paymentForm.currency,
    //         description: paymentForm.description,
    //         reference: paymentForm.reference
    //     });
    //     console.log(payment);
    // };
    return (
        <div>
            <Dashboardsidebar />
            <div className="content-page mt-0">
                <div className="content">
                    <div className="container-fluid">
                        {/* <form onSubmit={handleSubmit}>
                            <label>
                                Amount:
                                <input type="text" name="amount" value={paymentForm.amount} onChange={handleInputChange} />
                            </label>
                            <br />
                            <label>
                                Currency:
                                <input type="text" name="currency" value={paymentForm.currency} onChange={handleInputChange} />
                            </label>
                            <br />
                            <label>
                                Description:
                                <input type="text" name="description" value={paymentForm.description} onChange={handleInputChange} />
                            </label>
                            <br />
                            <label>
                                Reference:
                                <input type="text" name="reference" value={paymentForm.reference} onChange={handleInputChange} />
                            </label>
                            <br />
                            <button type="submit">Pay</button>
                        </form> */}
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card transparent">
                                    <div className="card-body pt-1">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12">
                                                <div className="trasparent-box pt-0">
                                                    <div align="center" className="pt-3">
                                                        <img src="assets/images/checkmark1.gif" alt="" height="70" />
                                                    </div>
                                                    <div className="page-title-box">
                                                        <h4 className="page-title pb-0 border-bottom">Your Payment Completed Successfully</h4>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-center">
                                                            <h3 className="yellowcol">Amount</h3>
                                                            <h5 className="text-white">39868</h5>
                                                        </div>
                                                        <div className="col-6 text-center">
                                                            <h3 className="yellowcol">TR</h3>
                                                            <h5 className="text-white">39868</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payments
