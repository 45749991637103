import React from 'react'
import { Navigate } from "react-router-dom";


const Authenticate = ({children}) => {
    
    if(localStorage.getItem("isLogin")){ 
        return <> {children} </>
    }
    
    return <Navigate to="/login" /> 
}

export default Authenticate;
