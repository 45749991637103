import React, { useEffect, useRef, useState } from "react";
import Userdashsidebar from "../Userdashsidebar";
import UseServices from "../../services/api";
import {
  packageDetailsSingleUrl,
  paymentTransactionCreation,
  paymentZeroTransactionUrl,
} from "../../globalURL";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
import Spinner from "../project/Spinner";
export const BuyPackage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [packageDetails, setPackageDetails] = useState(false);
  const [transactionId, setTransactionId] = useState({});
  const [payTrasDetails, setPayTrasDetails] = useState({});

  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const pid = queryParams.get("pid");

  const withAdvisroySelected = queryParams.get("was");

  useEffect(() => {
    // alert()
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (pid === null) {
      navigate("/packages");
    }
    getPackageDetails();
    // createTransaction();
    // createZeroTransaction();
  }, [pid]);
  const getPackageDetails = () => {
    UseServices.fetchPostData(packageDetailsSingleUrl, {
      access_token: localStorage.getItem("access_token"),
      id: pid,
    })
      .then((response) => {
        setIsLoading(false);
        // console.log("result : ")
        // console.log(response.data)
        // set user details based on response data
        if (response.data) {
          // console.log("packagedet", response.data)
          setPackageDetails(response.data);
        } else {
          toast.error("Getting data faied try again");
          // alert("TEST")
          // localStorage.clear();
          // navigate("/login");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Somthing error occured..!, Please try again.");
        console.error(error);
      });
  };

  const createTransaction = () => {
    setIsLoading(true);
    UseServices.fetchPostData(paymentTransactionCreation, {
      access_token: localStorage.getItem("access_token"),
      package_id: pid,
      with_advisory_selected: "No",
    })
      .then((response) => {
        setTimeout(() => {
          setIsLoading(false);
          console.log("create trans::: ", response.data);
          setPayTrasDetails(response.data);
          setTransactionId(response.data._id.$oid);

          if (response.data._id.$oid) {
            navigate(
              "/payment_success?transaction_id=" +
                response.data._id.$oid +
                "&status=Processing"
            );
          } else {
            toast.error("Somthing error occured..!, Please try again.");
          }
        }, 2000);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Somthing error occured..!, Please try again.");
        console.error(error);
      });
  };

  const createZeroTransaction = () => {
    setIsLoading(true);
    UseServices.fetchPostData(paymentZeroTransactionUrl, {
      access_token: localStorage.getItem("access_token"),
      package_id: pid,
    })
      .then((response) => {
        setIsLoading(false);
        // console.log('create zero trans', response);
        if (response.status === "valid") {
          navigate(
            "/payment_success?transaction_id=" + response.id + "&status=ACCEPT"
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Somthing error occured..!, Please try again.");
        console.error(error);
      });
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div>
            <Userdashsidebar />
            <div className="content-page mt-0 class-back-ground border-colo-top">
              <div className="content">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="card transparent">
                        <div className="card-body pt-1">
                          <div className="row justify-content-center">
                            <div className="col-lg-10">
                              <div className="page-title-box">
                                <h4 className="page-title">Selected Package</h4>
                              </div>
                            </div>
                            <div className="col-lg-2 ">
                              <Link
                                className="btn btn-md btn-success"
                                style={{ padding: "4px 20px" }}
                                to={"/packages"}
                              >
                                <i className="fe-arrow-left fa-lg"></i> Back
                              </Link>
                            </div>
                            <div className="col-lg-12">
                              <div className="table-responsive">
                                <table className="table table-colorchild table-blk table-bacg-color">
                                  <thead
                                    style={{
                                      background: "#2e8800",
                                      color: "#fff",
                                    }}
                                  >
                                    <tr className="bg-success text-white">
                                      <th className="text-white"> Title </th>
                                      <td> </td>
                                      <td className="text-white">Details </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th className="text-black">
                                        Package Title
                                      </th>
                                      <td>:</td>
                                      <td>{packageDetails.title} </td>
                                    </tr>
                                    <tr>
                                      <th className="text-black">
                                        Package Details
                                      </th>
                                      <td>:</td>
                                      <td>{packageDetails.desc} </td>
                                    </tr>
                                    <tr>
                                      <th className="text-black">
                                        Package Validity
                                      </th>
                                      <td>:</td>
                                      <td>{packageDetails.validity} Months</td>
                                    </tr>
                                    <tr>
                                      <th className="text-black">
                                        No. Of Projects Allowed
                                      </th>
                                      <td>:</td>
                                      <td>
                                        {packageDetails.no_of_projects_allow}
                                        Project
                                      </td>
                                    </tr>
                                    {packageDetails.tr_required === "Yes" && (
                                      <>
                                        <tr>
                                          <th className="text-black">
                                            Package Tr
                                          </th>
                                          <td>:</td>
                                          <td>
                                            {
                                              packageDetails.tr_gtn_or_ltn_gtne_or_ltne
                                            }
                                            {packageDetails.tr}
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    <tr>
                                      <th className="text-black">
                                        Air Cooled Chiller Enable
                                      </th>
                                      <td>:</td>
                                      <td>
                                        {
                                          packageDetails.air_cooled_chiller_enable
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className="text-black">
                                        Water Cooled Chiller Enable
                                      </th>
                                      <td>:</td>
                                      <td>
                                        {
                                          packageDetails.water_cooled_chiller_enable
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className="text-black">
                                        Thermal Store Enable
                                      </th>
                                      <td>:</td>
                                      <td>
                                        {packageDetails.thermal_store_enable}
                                      </td>
                                    </tr>

                                    <tr>
                                      <th className="text-black">
                                        Package Price
                                      </th>
                                      <td>:</td>
                                      <td>${packageDetails.price} </td>
                                    </tr>
                                  </tbody>
                                  <tfoot
                                    style={{
                                      color: "#000",
                                    }}
                                    className="bg-success"
                                  >
                                    <tr
                                      style={{
                                        color: "#fff",
                                        fontSize: "18px",
                                      }}
                                    >
                                      <th>Total Payable Amount </th>
                                      <td>:</td>
                                      <th>${packageDetails.price}</th>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                            <div className="col-lg-12 text-center">
                              {/* {JSON.stringify(payTrasDetails)} */}
                              {packageDetails.price !== "0" ? (
                                <>
                                  <input
                                    onClick={() => createTransaction()}
                                    type="submit"
                                    value="Continue to Payment "
                                    className="btn btn-sm btn-success"
                                  />
                                  {/* <form method='post' action='https://testsecureacceptance.cybersource.com/pay'>
                                                        <input type="hidden" className="form-control" name="access_key" value={payTrasDetails?.payment_referance_obj?.access_key} />
                                                        <input type="hidden" className="form-control" name="profile_id" value={payTrasDetails?.payment_referance_obj?.profile_id}  />
                                                        <input type="hidden" className="form-control" name="override_custom_cancel_page"  value={payTrasDetails?.payment_referance_obj?.override_custom_cancel_page}  />
                                                        <input type="hidden" className="form-control" name="override_custom_receipt_page" value={payTrasDetails?.payment_referance_obj?.override_custom_receipt_page} />
                                                        <input type="hidden" className="form-control" name="signed_date_time" value={payTrasDetails?.payment_referance_obj?.signed_date_time} />
                                                        <input type="hidden" className="form-control" name="signed_field_names" value="override_custom_cancel_page,override_custom_receipt_page,access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency" />
                                                        <input type="hidden" className="form-control" name="unsigned_field_names" value={payTrasDetails?.payment_referance_obj?.unsigned_field_names} />
                                                        <input type="hidden" className="form-control" name="locale" value="en" />
                                                        <input type="hidden" className="form-control" name="transaction_type" size="25" value="authorization" />
                                                        <input type="hidden" className="form-control" name="currency" size="25" value="USD" />
                                                        <input type="hidden" className="form-control" name="transaction_uuid" value={payTrasDetails?.payment_referance_obj?.transaction_uuid} />
                                                        <input type="hidden" className="form-control" name="reference_number" size="25"value={payTrasDetails?.payment_referance_obj?.reference_number} />
                                                        <input type="hidden" className="form-control" name="amount" size="25"value={payTrasDetails?.payment_referance_obj?.amount} />
                                                        <input type="hidden" className="form-control" name="signature" size="25"value={payTrasDetails?.payment_referance_obj?.signature} />
                                                         
                                                        <input type="submit" value="Continue to Payment " className='btn btn-sm btn-success' />
                                                    </form> */}
                                </>
                              ) : (
                                <Link
                                  onClick={createZeroTransaction}
                                  className="btn btn-pryellow"
                                >
                                  Continue to Submit
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
