import React, { useEffect, useRef, useState } from "react";
import Dashboardsidebar from "./Dashboardsidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { operationPhaseURL, projectDetails } from "../../globalURL";
import { toast } from "react-toastify";
import { getProjectLocalId } from "../../common/common_helper";
const DEOperationalPhase = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // const [noOfChillers, setNoOfChillers] = useState(0);
  const [noOfTRHs, setNoOfTRHs] = useState(1);
  const [noOfPrimaryPumpsValue, setNoOfPrimaryPumpsValue] = useState(1);
  const [noOfSecondaryPumpsValue, setNoOfSecondaryPumpsValue] = useState(1);
  const [noOfCondenserPumpsValue, setNoOfCondenserPumpsValue] = useState(1);
  const [noOfTertiaryPumpsValue, setNoOfTertiaryPumpsValue] = useState(1);
  const [noOfCoolingTowersValue, setNoOfCoolingTowersValue] = useState(1);
  const [noOfSubMetersValue, setNoOfSubMetersValue] = useState(1);
  const [noOfYearlyPowerConsumptionValue, setNoOfYearlyPowerConsumptionValue] =
    useState(1);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const dataFetchedRef = useRef(false);
  const [is_thermal_store_available_op, setIs_thermal_store_available_op] =
    useState("No");
  const [is_renewal_energy_applicable_op, setIs_renewal_energy_applicable_op] =
    useState("No");
  const [is_submeter_available_op, setIs_submeter_available_op] =
    useState("No");
  const [value, setValue] = useState(0);

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setIs_thermal_store_available_op(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      is_thermal_store_available_op: value,
    }));
  };

  const handleRenewalRadioChange = (e) => {
    const value = e.target.value;
    setIs_renewal_energy_applicable_op(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      is_renewal_energy_applicable_op: value,
    }));
  };

  const handleSubmeterRadioChange = (e) => {
    const value = e.target.value;
    setIs_submeter_available_op(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      is_submeter_available_op: value,
    }));
  };

  function handleTRHsIncrement() {
    if (noOfTRHs < 10) {
      setNoOfTRHs(noOfTRHs + 1);
      setFormData({
        ...formData,
        ["no_of_trhs_op"]: noOfTRHs + 1,
      });
    }
  }

  function handleTRHsDecrement() {
    if (noOfTRHs > 1) {
      setNoOfTRHs(noOfTRHs - 1);
      setFormData({
        ...formData,
        ["no_of_trhs_op"]: noOfTRHs - 1,
      });
    }
  }

  function handlePrimaryPumpsIncrement() {
    if (noOfPrimaryPumpsValue < 10) {
      setNoOfPrimaryPumpsValue(noOfPrimaryPumpsValue + 1);
      setFormData({
        ...formData,
        ["no_of_primary_pumps_op"]: noOfPrimaryPumpsValue + 1,
      });
    }
  }

  // function handleChillersIncrement() {
  //     if (noOfChillers < 10) {
  //         setNoOfChillers(noOfChillers + 1);
  //         setFormData({
  //             ...formData,
  //             ["no_of_chillers_op"]: noOfChillers + 1,
  //         });
  //     }
  // }

  // function handleChillersDecrement() {
  //     if (noOfChillers > 1) {
  //         setNoOfChillers(noOfChillers - 1);
  //         setFormData({
  //             ...formData,
  //             ["no_of_chillers_op"]: noOfChillers - 1,
  //         });
  //     }
  // }

  function handlePrimaryPumpsDecrement() {
    if (noOfPrimaryPumpsValue > 0) {
      setNoOfPrimaryPumpsValue(noOfPrimaryPumpsValue - 1);
      setFormData({
        ...formData,
        ["no_of_primary_pumps_op"]: noOfPrimaryPumpsValue - 1,
      });
    }
  }

  function handleSecondaryPumpsIncrement() {
    if (noOfSecondaryPumpsValue < 10) {
      setNoOfSecondaryPumpsValue(noOfSecondaryPumpsValue + 1);
      setFormData({
        ...formData,
        ["no_of_secondary_pumps_op"]: noOfSecondaryPumpsValue + 1,
      });
    }
  }

  function handleSecondaryPumpsDecrement() {
    if (noOfSecondaryPumpsValue > 0) {
      setNoOfSecondaryPumpsValue(noOfSecondaryPumpsValue - 1);
      setFormData({
        ...formData,
        ["no_of_secondary_pumps_op"]: noOfSecondaryPumpsValue - 1,
      });
    }
  }

  function handleCondenserPumpsIncrement() {
    if (noOfCondenserPumpsValue < 10) {
      setNoOfCondenserPumpsValue(noOfCondenserPumpsValue + 1);
      setFormData({
        ...formData,
        ["no_of_condenser_pumps_op"]: noOfCondenserPumpsValue + 1,
      });
    }
  }

  function handleCondenserPumpsDecrement() {
    if (noOfCondenserPumpsValue > 0) {
      setNoOfCondenserPumpsValue(noOfCondenserPumpsValue - 1);
      setFormData({
        ...formData,
        ["no_of_condenser_pumps_op"]: noOfCondenserPumpsValue - 1,
      });
    }
  }

  function handleTertiaryPumpsIncrement() {
    if (noOfTertiaryPumpsValue < 10) {
      setNoOfTertiaryPumpsValue(noOfTertiaryPumpsValue + 1);
      setFormData({
        ...formData,
        ["no_of_tertiary_pumps_op"]: noOfTertiaryPumpsValue + 1,
      });
    }
  }

  function handleTertiaryPumpsDecrement() {
    if (noOfTertiaryPumpsValue > 0) {
      setNoOfTertiaryPumpsValue(noOfTertiaryPumpsValue - 1);
      setFormData({
        ...formData,
        ["no_of_tertiary_pumps_op"]: noOfTertiaryPumpsValue - 1,
      });
    }
  }

  function handleCoolingTowersIncrement() {
    if (noOfCoolingTowersValue < 10) {
      setNoOfCoolingTowersValue(noOfCoolingTowersValue + 1);
      setFormData({
        ...formData,
        ["no_of_cooling_towers_op"]: noOfCoolingTowersValue + 1,
      });
    }
  }

  function handleCoolingTowersDecrement() {
    if (noOfCoolingTowersValue > 0) {
      setNoOfCoolingTowersValue(noOfCoolingTowersValue - 1);
      setFormData({
        ...formData,
        ["no_of_cooling_towers_op"]: noOfCoolingTowersValue - 1,
      });
    }
  }

  function handleSubMetersIncrement() {
    if (noOfSubMetersValue < 10) {
      setNoOfSubMetersValue(noOfSubMetersValue + 1);
      setFormData({
        ...formData,
        ["no_of_sub_meters_op"]: noOfSubMetersValue + 1,
      });
    }
  }

  function handleSubMetersDecrement() {
    if (noOfSubMetersValue > 0) {
      setNoOfSubMetersValue(noOfSubMetersValue - 1);
      setFormData({
        ...formData,
        ["no_of_sub_meters_op"]: noOfSubMetersValue - 1,
      });
    }
  }

  function handleYearlyPowerConsumptionIncrement() {
    if (noOfYearlyPowerConsumptionValue < 10) {
      setNoOfYearlyPowerConsumptionValue(noOfYearlyPowerConsumptionValue + 1);
      setFormData({
        ...formData,
        ["no_of_yearly_power_consumption_op"]:
          noOfYearlyPowerConsumptionValue + 1,
      });
    }
  }

  function handleYearlyPowerConsumptionDecrement() {
    if (noOfYearlyPowerConsumptionValue > 1) {
      setNoOfYearlyPowerConsumptionValue(noOfYearlyPowerConsumptionValue - 1);
      setFormData({
        ...formData,
        ["no_of_yearly_power_consumption_op"]:
          noOfYearlyPowerConsumptionValue - 1,
      });
    }
  }

  const initialFormData = {
    condenser_cooling_media_op: "",
    compressor_type_op: "",
    nominal_cop_op: "",
    minimum_start_up_capacity_op: "",
    no_of_chillers_op: 0,
    capacities_op: [],
    // no_of_chillers_op: noOfChillers,
    // chiller_1_capacity_op: "",
    // chiller_2_capacity_op: "",
    // chiller_3_capacity_op: "",
    // chiller_4_capacity_op: "",
    // chiller_5_capacity_op: "",
    // chiller_6_capacity_op: "",
    // chiller_7_capacity_op: "",
    // chiller_8_capacity_op: "",
    // chiller_9_capacity_op: "",
    // chiller_10_capacity_op: "",

    no_of_trhs_op: noOfTRHs,
    capacity_of_trhs_op: "",
    capacity_of_trhs_2_op: "",
    capacity_of_trhs_3_op: "",
    capacity_of_trhs_4_op: "",
    capacity_of_trhs_5_op: "",
    capacity_of_trhs_6_op: "",
    capacity_of_trhs_7_op: "",
    capacity_of_trhs_8_op: "",
    capacity_of_trhs_9_op: "",
    capacity_of_trhs_10_op: "",
    cooling_tower_type_op: "",
    no_of_primary_pumps_op: noOfPrimaryPumpsValue,
    no_of_secondary_pumps_op: noOfSecondaryPumpsValue,
    no_of_condenser_pumps_op: noOfCondenserPumpsValue,
    no_of_tertiary_pumps_op: noOfTertiaryPumpsValue,
    no_of_cooling_towers_op: noOfCoolingTowersValue,
    no_of_sub_meters_op: noOfSubMetersValue,
    no_of_yearly_power_consumption_op: noOfYearlyPowerConsumptionValue,
    thermal_store_capacity_op: "",

    // capacities_op: [],
    capacities_trh_op: [],
    operating_hours_per_year_op: "",
    primary_pump_type_op: "",
    capacity_of_primary_pumps_op: "",
    capacity_of_primary_pumps_2_op: "",
    capacity_of_primary_pumps_3_op: "",
    capacity_of_primary_pumps_4_op: "",
    capacity_of_primary_pumps_5_op: "",
    capacity_of_primary_pumps_6_op: "",
    capacity_of_primary_pumps_7_op: "",
    capacity_of_primary_pumps_8_op: "",
    capacity_of_primary_pumps_9_op: "",
    capacity_of_primary_pumps_10_op: "",
    secondary_pump_type_op: "",
    capacity_of_secondary_pumps_op: "",
    capacity_of_secondary_pumps_2_op: "",
    capacity_of_secondary_pumps_3_op: "",
    capacity_of_secondary_pumps_4_op: "",
    capacity_of_secondary_pumps_5_op: "",
    capacity_of_secondary_pumps_6_op: "",
    capacity_of_secondary_pumps_7_op: "",
    capacity_of_secondary_pumps_8_op: "",
    capacity_of_secondary_pumps_9_op: "",
    capacity_of_secondary_pumps_10_op: "",
    condenser_pump_type_op: "",
    capacity_of_condenser_pumps_op: "",
    capacity_of_condenser_pumps_2_op: "",
    capacity_of_condenser_pumps_3_op: "",
    capacity_of_condenser_pumps_4_op: "",
    capacity_of_condenser_pumps_5_op: "",
    capacity_of_condenser_pumps_6_op: "",
    capacity_of_condenser_pumps_7_op: "",
    capacity_of_condenser_pumps_8_op: "",
    capacity_of_condenser_pumps_9_op: "",
    capacity_of_condenser_pumps_10_op: "",
    tertiary_pump_type_op: "",
    capacity_of_tertiary_pumps_op: "",
    capacity_of_tertiary_pumps_2_op: "",
    capacity_of_tertiary_pumps_3_op: "",
    capacity_of_tertiary_pumps_4_op: "",
    capacity_of_tertiary_pumps_5_op: "",
    capacity_of_tertiary_pumps_6_op: "",
    capacity_of_tertiary_pumps_7_op: "",
    capacity_of_tertiary_pumps_8_op: "",
    capacity_of_tertiary_pumps_9_op: "",
    capacity_of_tertiary_pumps_10_op: "",
    capacity_of_cooling_towers_op: "",
    capacity_of_cooling_towers_2_op: "",
    capacity_of_cooling_towers_3_op: "",
    capacity_of_cooling_towers_4_op: "",
    capacity_of_cooling_towers_5_op: "",
    capacity_of_cooling_towers_6_op: "",
    capacity_of_cooling_towers_7_op: "",
    capacity_of_cooling_towers_8_op: "",
    capacity_of_cooling_towers_9_op: "",
    capacity_of_cooling_towers_10_op: "",
    sub_meter_cooling_tower_power_input: "",
    power_consumption_per_years_op: "",
    power_consumption_per_years_2_op: "",
    power_consumption_per_years_3_op: "",
    power_consumption_per_years_4_op: "",
    power_consumption_per_years_5_op: "",
    power_consumption_per_years_6_op: "",
    power_consumption_per_years_7_op: "",
    power_consumption_per_years_8_op: "",
    power_consumption_per_years_9_op: "",
    power_consumption_per_years_10_op: "",

    sub_meter_primary_pump_power_input: "",
    sub_meter_secondary_pump_power_input: "",
    sub_meter_condenser_pump_power_input: "",
    sub_meter_tertiary_pump_power_input: "",
    sub_meter_chiller_infomation: "",
    sub_meter_termal_store_infomation: "",
    sub_meter_renewal_energy_infomation: "",

    is_renewal_energy_applicable_op: "No",
    is_renewal_energy_generated_op: "",

    is_thermal_store_available_op: "",

    energy_applicable_op: "No",
    is_submeter_available_op: "No",
    _id: getProjectLocalId(),
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formDataErrors, setFormDataErrors] = useState(initialFormData);

  const handleCapacitiesChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...formData?.capacities_op];
    list[index][name] = value;
    setFormData({ ...formData, ["capacities_op"]: list });
    // console.log(formData);
  };

  // function handleIncrement() {
  //     if (noOfChillers < 10) {
  //         setNoOfChillers(noOfChillers + 1);

  //         const updatedCapacitiesOperated = [...formData?.capacities_op, { chiller: "" }];
  //         const updatedCapacities = [...formData?.capacities, { chiller: "" }];

  //         setFormData({ ...formData, no_of_chillers_op: noOfChillers + 1, capacities_op: updatedCapacitiesOperated, capacities: updatedCapacities });
  //     }
  // }

  function handleIncrement() {
    if (value < 10) {
      setValue(value + 1);
      const updatedCapacities = [...(formData?.capacities_op || [])];
      updatedCapacities.push({ chiller: "" });
      setFormData({
        ...formData,
        no_of_chillers_op: value + 1,
        capacities_op: updatedCapacities,
      });
    }
  }

  function handleDecrement() {
    if (value > 0) {
      setValue(value - 1);
      const updatedCapacities = [...(formData?.capacities_op || [])];
      updatedCapacities.pop();
      setFormData({
        ...formData,
        no_of_chillers_op: value - 1,
        capacities_op: updatedCapacities,
      });
    }
  }

  // function handleDecrement() {
  //     if (noOfChillers > 0) {
  //         setNoOfChillers(noOfChillers - 1);

  //         const updatedCapacitiesOperated = [...formData?.capacities_op];
  //         updatedCapacitiesOperated.pop();

  //         const updatedCapacities = [...formData?.capacities];
  //         updatedCapacities.pop();

  //         setFormData({ ...formData, no_of_chillers_op: noOfChillers - 1, capacities_op: updatedCapacitiesOperated, capacities: updatedCapacities });
  //     }
  // }

  // const handleCapacitiesChange = (e, index) => {
  //     const { name, value } = e.target;
  //     const capacitiesOpList = [...formData.capacities_op];
  //     capacitiesOpList[index][name] = value;

  //     const capacitiesList = [...formData.capacities];
  //     capacitiesList[index][name] = value;

  //     setFormData({
  //         ...formData,
  //         capacities_op: capacitiesOpList,
  //         capacities: capacitiesList
  //     });
  //     // console.log(formData);
  // };

  const handleChangeRenewalEnergy = (event) => {
    if (event.target.checked) {
      setFormData({ ...formData, ["is_renewal_energy_applicable_op"]: true });
      console.log("✅ Checkbox is checked");
    } else {
      setFormData({ ...formData, ["is_renewal_energy_applicable_op"]: false });
      console.log("⛔️ Checkbox is NOT checked");
    }
    setIsSubscribed((current) => !current);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value || 0 });
    if (formDataErrors[name] && value) {
      setFormDataErrors({ ...formDataErrors, [name]: "" });
    }
  };

  const validateFormData = () => {
    const errors = {};
    const requiredFields = [
      // "no_of_yearly_power_consumption_op",
      "power_consumption_per_years_op",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = `** Please enter ${field}`;
      }
    });

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Operational Phase : ", formData);
    // return;
    const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      toast.error("Error: data not entered.Please check once");
      setFormDataErrors(errors);
      //   setIsLoading(false);
    } else {
      setFormDataErrors({});
      // setIsLoading(true);
      try {
        //   alert("kkk")
        const response = await axios.post(
          operationPhaseURL,
          JSON.stringify(formData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response", response);
        // setIsLoading(false);
        if (response?.data?.data?.id) {
          navigate("/result-as-built?project_id=" + response?.data?.data?.id, {
            state: { message: "TEST" },
          });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        // setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        console.log(error);
      }
    }

    if (Object.keys(errors).length === 0) {
      // console.log("formData", formData);
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    axios
      .post(
        projectDetails,
        JSON.stringify({ project_id: localStorage.getItem("project_id") }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        // alert(res["data"]["data"]["no_of_chillers_op"])
        console.log(res["data"]["data"]);
        let result = res["data"]["data"];

        const noOfChillers = result?.no_of_chillers_op;
        setValue(parseInt(noOfChillers) || 0);
        setFormData(res?.data?.data);
        // setNoOfChillers(result?.no_of_chillers_op || 1)
        setNoOfTRHs(parseInt(result.no_of_trhs_op) || 1);
        setNoOfPrimaryPumpsValue(result.no_of_primary_pumps_op || 0);
        setNoOfSecondaryPumpsValue(result.no_of_secondary_pumps_op || 0);
        setNoOfCondenserPumpsValue(result.no_of_condenser_pumps_op || 0);
        setNoOfTertiaryPumpsValue(result.no_of_tertiary_pumps_op || 0);
        setNoOfCoolingTowersValue(result.no_of_cooling_towers_op || 0);
        setNoOfYearlyPowerConsumptionValue(
          result.no_of_yearly_power_consumption_op || 1
        );
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {/* {
        isLoading ? <Spinner /> :
        (<> */}
      <Dashboardsidebar currentPage="operational" />
      <div className="content-page mt-0">
        <div className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="card transparent">
                  <div className="card-body pt-1">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className="page-title-box">
                          <h4 className="page-title">Operational Phases</h4>
                        </div>
                      </div>
                    </div>
                    <form
                      action="#"
                      onSubmit={handleSubmit}
                      method="post"
                      encType="multipart/form-data"
                      autoComplete="off"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <label
                            style={{ color: "#FFFF00" }}
                            htmlFor=""
                            className=""
                          >
                            Chiller Type
                          </label>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-6">
                          <div className="form-floating mb-2">
                            <span
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                marginLeft: "10px",
                              }}
                            >
                              Cooling Media{" "}
                            </span>
                            <select
                              name="condenser_cooling_media_op"
                              value={formData?.condenser_cooling_media_op}
                              className="form-select w-100"
                              onChange={handleInputChange}
                            >
                              <option value="">
                                ------------------------- Cooling
                                Media-------------------------{" "}
                              </option>
                              <option
                                value="Water-cooled"
                                selected={
                                  formData?.condenser_cooling_media_op ===
                                  ("Water-cooled" ||
                                    formData?.condenser_cooling_media_op)
                                }
                              >
                                Water-cooled
                              </option>
                              <option
                                value="Air-cooled"
                                selected={
                                  formData?.condenser_cooling_media_op ===
                                  ("Air-cooled" ||
                                    formData?.condenser_cooling_media_op)
                                }
                              >
                                Air-cooled
                              </option>
                            </select>

                            {formDataErrors.condenser_cooling_media_op && (
                              <div className="text-danger">
                                {formDataErrors.condenser_cooling_media_op}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <label
                            style={{ color: "#FFFF00" }}
                            htmlFor=""
                            className=""
                          >
                            Compressor Type
                          </label>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-6">
                          <div className="form-floating mb-2">
                            <span
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                marginLeft: "10px",
                              }}
                            >
                              Compressor Type{" "}
                            </span>
                            <select
                              name="compressor_type_op"
                              id=""
                              className="form-select w-100"
                              value={formData?.compressor_type_op}
                              onChange={handleInputChange}
                            >
                              <option value="">
                                ------------------------- Compressor Type
                                -------------------------
                              </option>
                              <option value="Screw"> Screw</option>
                              <option value="Scroll">Scroll </option>
                              <option value="Reciprocating">
                                Reciprocating
                              </option>
                              <option
                                value="Centrifugal"
                                style={{
                                  display:
                                    formData?.condenser_cooling_media ===
                                    "Water-cooled"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                Centrifugal
                              </option>
                            </select>

                            {formDataErrors.compressor_type_op && (
                              <div className="text-danger">
                                {formDataErrors.compressor_type_op}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center my-2">
                          <label htmlFor="" className="yellowcol">
                            Chiller Information
                          </label>
                        </div>
                      </div>

                      {/* <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="row d-flex justify-content-center align-items-center">
                                                        <div className="col-lg-4">
                                                            <label
                                                                style={{ color: "#FFFF00" }}
                                                                htmlFor="floatingInput"
                                                            >
                                                                Number Of Chillers
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-8 col-md-4">
                                                            <div className="form-group mb-2 mt-1">
                                                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                                                    <span className="input-group-btn input-group-prepend">
                                                                        <button
                                                                            className="btn btn-primary bootstrap-touchspin-down"
                                                                            type="button"
                                                                            onClick={handleChillersDecrement}
                                                                        >
                                                                            -
                                                                        </button>
                                                                    </span>
                                                                    <input
                                                                        type="number"
                                                                        pattern="[0-9]*"
                                                                        step="any"
                                                                        value={noOfChillers}
                                                                        name="no_of_chillers_op"
                                                                        className="text-center form-control"
                                                                        onChange={handleInputChange}
                                                                    />

                                                                    <span className="input-group-btn input-group-append">
                                                                        <button
                                                                            className="btn btn-primary bootstrap-touchspin-up"
                                                                            type="button"
                                                                            onClick={handleChillersIncrement}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {noOfChillers >= 1 ? (
                                                    <>
                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="form-floating mb-2">
                                                                <input
                                                                    value={formData?.chiller_1_capacity_op}
                                                                    onChange={handleInputChange}
                                                                    name="chiller_1_capacity_op"
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    step="any"
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    placeholder="Ex.1144"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                    Capacity(kW)
                                                                </label>
                                                                {formDataErrors.chiller_1_capacity_op && (
                                                                    <div className="text-danger">
                                                                    ** required
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {noOfChillers >= 2 ? (
                                                    <>
                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="form-floating mb-2">
                                                                <input
                                                                    value={formData?.chiller_2_capacity_op}
                                                                    onChange={handleInputChange}
                                                                    name="chiller_2_capacity_op"
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    step="any"
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    placeholder="Ex.1144"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                    Capacity(kW)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {noOfChillers >= 3 ? (
                                                    <>
                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="form-floating mb-2">
                                                                <input
                                                                    value={formData?.chiller_3_capacity_op}
                                                                    onChange={handleInputChange}
                                                                    name="chiller_3_capacity_op"
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    step="any"
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    placeholder="Ex.1144"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                    Capacity(kW)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                {noOfChillers >= 4 ? (
                                                    <>
                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="form-floating mb-2">
                                                                <input
                                                                    value={formData?.chiller_4_capacity_op}
                                                                    onChange={handleInputChange}
                                                                    name="chiller_4_capacity_op"
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    step="any"
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    placeholder="Ex.1144"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                    Capacity(kW)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                {noOfChillers >= 5 ? (
                                                    <>
                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="form-floating mb-2">
                                                                <input
                                                                    value={formData?.chiller_5_capacity_op}
                                                                    onChange={handleInputChange}
                                                                    name="chiller_5_capacity_op"
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    step="any"
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    placeholder="Ex.1144"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                    Capacity(kW)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                {noOfChillers >= 6 ? (
                                                    <>
                                                        <div className="col-lg-3">
                                                            <div className="form-floating mb-2">
                                                                <input
                                                                    value={formData?.chiller_6_capacity_op}
                                                                    onChange={handleInputChange}
                                                                    name="chiller_6_capacity_op"
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    step="any"
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    placeholder="Ex.1144"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                    Capacity(kW)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                {noOfChillers >= 7 ? (
                                                    <>
                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="form-floating mb-2">
                                                                <input
                                                                    value={formData?.chiller_7_capacity_op}
                                                                    onChange={handleInputChange}
                                                                    name="chiller_7_capacity_op"
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    step="any"
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    placeholder="Ex.1144"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                    Capacity(kW)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                {noOfChillers >= 8 ? (
                                                    <>
                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="form-floating mb-2">
                                                                <input
                                                                    value={formData?.chiller_8_capacity_op}
                                                                    onChange={handleInputChange}
                                                                    name="chiller_8_capacity_op"
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    step="any"
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    placeholder="Ex.1144"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                    Capacity(kW)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                {noOfChillers >= 9 ? (
                                                    <>
                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="form-floating mb-2">
                                                                <input
                                                                    value={formData?.chiller_9_capacity_op}
                                                                    onChange={handleInputChange}
                                                                    name="chiller_9_capacity_op"
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    step="any"
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    placeholder="Ex.1144"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                    Capacity(kW)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                {noOfChillers >= 10 ? (
                                                    <>
                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="form-floating mb-2">
                                                                <input
                                                                    value={formData?.chiller_10_capacity_op}
                                                                    onChange={handleInputChange}
                                                                    name="chiller_10_capacity_op"
                                                                    type="number"
                                                                    pattern="[0-9]*"
                                                                    step="any"
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    placeholder="Ex.1144"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                    Capacity(kW)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div> */}

                      <div className="row">
                        <div className="col-lg-2 col-md-4">
                          <div
                            style={{ color: "#FFFF00" }}
                            className="pt-2 fz-12"
                          >
                            Number Of Chillers
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4">
                          <div className="form-group mb-2 mt-1">
                            <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                              <span className="input-group-btn input-group-prepend">
                                <button
                                  className="btn btn-primary bootstrap-touchspin-down"
                                  type="button"
                                  onClick={handleDecrement}
                                >
                                  -
                                </button>
                              </span>
                              <input
                                type="number"
                                pattern="[0-9]*"
                                step="any"
                                value={value}
                                name="no_of_chillers_op"
                                className="text-center form-control"
                                onChange={handleInputChange}
                              />

                              <span className="input-group-btn input-group-append">
                                <button
                                  className="btn btn-primary bootstrap-touchspin-up"
                                  type="button"
                                  onClick={handleIncrement}
                                  //   disabled={readonly}
                                >
                                  +
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-cols-lg-5 row-cols-md-5 row-cols-sm-3 row-cols-2">
                        {formData &&
                          formData?.capacities_op &&
                          formData?.capacities_op.map((x, i) => {
                            return (
                              <div className="col-lg-4" key={`chiller-${i}`}>
                                <div className="form-floating mb-2">
                                  <input
                                    type="number"
                                    // disabled={readonly}
                                    pattern="[0-9]*"
                                    step="any"
                                    key={i}
                                    name="chiller"
                                    onChange={(e) =>
                                      handleCapacitiesChange(e, i)
                                    }
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Client Name"
                                    value={formData.capacities_op[i]["chiller"]}
                                  />
                                  <label htmlFor="floatingInput">
                                    Chiller {i + 1} - Capacity (kW)
                                  </label>
                                  {/* {formData?.capacities[i]["chiller"]  && (
                                  <p style={{ color: "red" }}>*Required</p>
                                )} */}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <div className="row">
                        <div className="col-lg-12  col-md-6">
                          <label
                            style={{ color: "#FFFF00" }}
                            htmlFor=""
                            className=""
                          >
                            Total Annual Operating Hours
                          </label>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-floating mb-2">
                            <input
                              value={formData?.operating_hours_per_year_op}
                              onChange={handleInputChange}
                              name="operating_hours_per_year_op"
                              type="number"
                              pattern="[0-9]*"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Ex.1144"
                            />
                            <label htmlFor="floatingInput">
                              Operating House per year
                            </label>
                            {formDataErrors.operating_hours_per_year_op && (
                              <div className="text-danger">
                                ** Please enter Operating Hours per year
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label
                                style={{ color: "#FFFF00" }}
                                htmlFor="floatingInput"
                              >
                                TRH
                              </label>
                            </div>
                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleTRHsDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfTRHs}
                                    name="no_of_trhs_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleTRHsIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {Array.from({ length: noOfTRHs }).map((_, index) => {
                          const capacityName =
                            index === 0
                              ? "capacity_of_trhs_op"
                              : `capacity_of_trhs_${index + 1}_op`;

                          return (
                            <div key={index} className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={formData?.[capacityName]}
                                  onChange={handleInputChange}
                                  name={capacityName}
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Year-{index + 1}
                                </label>
                                {formDataErrors?.[capacityName] && (
                                  <div className="text-danger">** required</div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-6">
                          <div className="">
                            <label
                              style={{ color: "#FFFF00", fontSize: "12px" }}
                              htmlFor="remember2"
                              className=" pl-0"
                            >
                              Nominal COP (Kw/kw)
                            </label>
                          </div>
                          <div className="form-floating mb-2">
                            <input
                              type="number"
                              pattern="[0-9]*"
                              step="any"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Ex.1144"
                              name="nominal_cop_op"
                              value={formData?.nominal_cop_op}
                              onChange={handleInputChange}
                            />

                            {formDataErrors.nominal_cop_op && (
                              <div className="text-danger">
                                {formDataErrors.nominal_cop_op}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-6 col-sm-5 col-12">
                          <div className="">
                            <label
                              style={{ color: "#FFFF00", fontSize: "12px" }}
                              htmlFor="remember2"
                              className="pl-0"
                            >
                              Minimum Startup Capacity (%)
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                          <div className="form-floating mb-2">
                            <input
                              type="number"
                              pattern="[0-9]*"
                              step="any"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Ex.1144"
                              name="minimum_start_up_capacity_op"
                              value={formData?.minimum_start_up_capacity_op}
                              onChange={handleInputChange}
                            />

                            {formDataErrors.minimum_start_up_capacity_op && (
                              <div className="text-danger">
                                {formDataErrors.minimum_start_up_capacity_op}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="yellowcol">
                            Thermal Store Information
                          </label>
                        </div>
                      </div>

                      {/* <div>
      <label>
        <input
          type="radio"
          value="Yes"
          checked={forData.is_thermal_store_available_op === "Yes"}
          onChange={handleRadioChange}
        />
        Yes
      </label>

      <label>
        <input
          type="radio"
          value="No"
          checked={forData.is_thermal_store_available_op === "No"}
          onChange={handleRadioChange}
        />
        No
      </label>

      {forData.is_thermal_store_available_op === "Yes" && (
        <div>
          <label>
            Capacity:
            <input type="text" />
          </label>
        </div>
      )}
    </div> */}
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-6">
                              <label htmlFor="" className="text-white">
                                Is Thermal Store Available?
                              </label>
                            </div>

                            <div className="col-lg-6 col-md-12 position-relative">
                              <div className="d-flex">
                                <div className="radiocust">
                                  <label className="text-white">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_thermal_store_available_op"
                                      value="No"
                                      onChange={handleRadioChange}
                                      checked={
                                        is_thermal_store_available_op ===
                                          "No" || ""
                                      }
                                    />
                                    No
                                  </label>
                                </div>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <div className="radiocust">
                                  <label className="text-white">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_thermal_store_available_op"
                                      value="Yes"
                                      onChange={handleRadioChange}
                                      checked={
                                        is_thermal_store_available_op ===
                                          "Yes" ||
                                        formData.is_thermal_store_available_op ===
                                          "Yes"
                                      }
                                    />
                                    Yes
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {formData?.is_thermal_store_available_op === "Yes" ? (
                          <>
                            <div className="col-lg-12">
                              <div className="form-floating mb-2">
                                <input
                                  value={formData?.thermal_store_capacity_op}
                                  onChange={handleInputChange}
                                  name="thermal_store_capacity_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity (kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <label htmlFor="" className="yellowcol ">
                            Pumps Information
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="yellowcol">
                            Primary Pumps
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              name="primary_pump_type_op"
                              value={formData?.primary_pump_type_op}
                              onChange={handleInputChange}
                              id=""
                              className="form-select w-100"
                            >
                              <option value="" disabled>
                                {" "}
                                ------------------------- Motor Type
                                -------------------------{" "}
                              </option>
                              <option
                                value="Fixed"
                                selected={
                                  formData?.primary_pump_type_op ===
                                  ("Fixed" || formData?.primary_pump_type_op)
                                }
                              >
                                Fixed
                              </option>
                              <option
                                value="Varible"
                                selected={
                                  formData?.primary_pump_type_op ===
                                  ("Varible" || formData?.primary_pump_type_op)
                                }
                              >
                                Varible
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label htmlFor="floatingInput">
                                Number of Primary Pumps
                              </label>
                            </div>
                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handlePrimaryPumpsDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfPrimaryPumpsValue}
                                    name="no_of_primary_pumps_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handlePrimaryPumpsIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {noOfPrimaryPumpsValue >= 1 ? (
                          <>
                            <div className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={formData?.capacity_of_primary_pumps_op}
                                  onChange={handleInputChange}
                                  name="capacity_of_primary_pumps_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfPrimaryPumpsValue >= 2 ? (
                          <>
                            <div className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_primary_pumps_2_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_primary_pumps_2_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfPrimaryPumpsValue >= 3 ? (
                          <>
                            <div className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_primary_pumps_3_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_primary_pumps_3_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfPrimaryPumpsValue >= 4 ? (
                          <>
                            <div className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_primary_pumps_4_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_primary_pumps_4_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfPrimaryPumpsValue >= 5 ? (
                          <>
                            <div className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_primary_pumps_5_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_primary_pumps_5_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfPrimaryPumpsValue >= 6 ? (
                          <>
                            <div className="col-lg-3">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_primary_pumps_6_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_primary_pumps_6_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfPrimaryPumpsValue >= 7 ? (
                          <>
                            <div className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_primary_pumps_7_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_primary_pumps_7_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfPrimaryPumpsValue >= 8 ? (
                          <>
                            <div className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_primary_pumps_8_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_primary_pumps_8_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfPrimaryPumpsValue >= 9 ? (
                          <>
                            <div className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_primary_pumps_9_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_primary_pumps_9_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfPrimaryPumpsValue >= 10 ? (
                          <>
                            <div className="col-lg-3 col-md-4">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_primary_pumps_10_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_primary_pumps_10_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="yellowcol">
                            Secondary Pumps
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              name="secondary_pump_type_op"
                              value={formData?.secondary_pump_type_op}
                              onChange={handleInputChange}
                              id=""
                              className="form-select w-100"
                            >
                              <option value="" disabled>
                                ------------------------- Motor Type
                                -------------------------
                              </option>
                              <option
                                value="Fixed"
                                selected={
                                  formData?.secondary_pump_type_op ===
                                  ("Fixed" || formData?.secondary_pump_type_op)
                                }
                              >
                                Fixed
                              </option>
                              <option
                                value="Varible"
                                selected={
                                  formData?.secondary_pump_type_op ===
                                  ("Varible" ||
                                    formData?.secondary_pump_type_op)
                                }
                              >
                                Varible
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label htmlFor="floatingInput">
                                Number of Secondary Pumps
                              </label>
                            </div>

                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleSecondaryPumpsDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfSecondaryPumpsValue}
                                    name="no_of_secondary_pumps_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleSecondaryPumpsIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {noOfSecondaryPumpsValue >= 1 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_secondary_pumps_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_secondary_pumps_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfSecondaryPumpsValue >= 2 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_secondary_pumps_2_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_secondary_pumps_2_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfSecondaryPumpsValue >= 3 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_secondary_pumps_3_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_secondary_pumps_3_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfSecondaryPumpsValue >= 4 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_secondary_pumps_4_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_secondary_pumps_4_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfSecondaryPumpsValue >= 5 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_secondary_pumps_5_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_secondary_pumps_5_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfSecondaryPumpsValue >= 6 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_secondary_pumps_6_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_secondary_pumps_6_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfSecondaryPumpsValue >= 7 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_secondary_pumps_7_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_secondary_pumps_7_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfSecondaryPumpsValue >= 8 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_secondary_pumps_8_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_secondary_pumps_8_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfSecondaryPumpsValue >= 9 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_secondary_pumps_9_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_secondary_pumps_9_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfSecondaryPumpsValue >= 10 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_secondary_pumps_10_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_secondary_pumps_10_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="yellowcol">
                            Condenser Pumps
                          </label>
                        </div>

                        <div className="col-lg-6 col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              name="condenser_pump_type_op"
                              value={formData?.condenser_pump_type_op}
                              onChange={handleInputChange}
                              id=""
                              className="form-select w-100"
                            >
                              <option value="" disabled>
                                ------------------------- Motor Type
                                -------------------------
                              </option>
                              <option
                                value="Fixed"
                                selected={
                                  formData?.condenser_pump_type_op ===
                                  ("Fixed" || formData?.condenser_pump_type_op)
                                }
                              >
                                Fixed
                              </option>
                              <option
                                value="Varible"
                                selected={
                                  formData?.condenser_pump_type_op ===
                                  ("Varible" ||
                                    formData?.condenser_pump_type_op)
                                }
                              >
                                Varible
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label htmlFor="floatingInput">
                                Number of Condenser Pumps
                              </label>
                            </div>
                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleCondenserPumpsDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfCondenserPumpsValue}
                                    name="no_of_condenser_pumps_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleCondenserPumpsIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {noOfCondenserPumpsValue >= 1 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_condenser_pumps_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_condenser_pumps_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCondenserPumpsValue >= 2 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_condenser_pumps_2_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_condenser_pumps_2_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCondenserPumpsValue >= 3 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_condenser_pumps_3_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_condenser_pumps_3_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCondenserPumpsValue >= 4 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_condenser_pumps_4_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_condenser_pumps_4_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCondenserPumpsValue >= 5 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_condenser_pumps_5_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_condenser_pumps_5_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCondenserPumpsValue >= 6 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_condenser_pumps_6_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_condenser_pumps_6_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCondenserPumpsValue >= 7 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_condenser_pumps_7_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_condenser_pumps_7_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCondenserPumpsValue >= 8 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_condenser_pumps_8_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_condenser_pumps_8_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCondenserPumpsValue >= 9 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_condenser_pumps_9_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_condenser_pumps_9_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCondenserPumpsValue >= 10 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_condenser_pumps_10_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_condenser_pumps_10_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="yellowcol">
                            Tertiary Pumps
                          </label>
                        </div>

                        <div className="col-lg-6 col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              name="tertiary_pump_type_op"
                              value={formData?.tertiary_pump_type_op}
                              onChange={handleInputChange}
                              id=""
                              className="form-select w-100"
                            >
                              <option value="" disabled>
                                ------------------------- Motor Type
                                --------------------------
                              </option>
                              <option
                                value="Fixed"
                                selected={
                                  formData?.tertiary_pump_type_op ===
                                  ("Fixed" || formData?.tertiary_pump_type_op)
                                }
                              >
                                Fixed
                              </option>
                              <option
                                value="Varible"
                                selected={
                                  formData?.tertiary_pump_type_op ===
                                  ("Varible" || formData?.tertiary_pump_type_op)
                                }
                              >
                                Varible
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label htmlFor="floatingInput">
                                Number of Tertiary Pumps
                              </label>
                            </div>
                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleTertiaryPumpsDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfTertiaryPumpsValue}
                                    name="no_of_tertiary_pumps_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleTertiaryPumpsIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {noOfTertiaryPumpsValue >= 1 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_tertiary_pumps_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_tertiary_pumps_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfTertiaryPumpsValue >= 2 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_tertiary_pumps_2_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_tertiary_pumps_2_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfTertiaryPumpsValue >= 3 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_tertiary_pumps_3_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_tertiary_pumps_3_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfTertiaryPumpsValue >= 4 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_tertiary_pumps_4_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_tertiary_pumps_4_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfTertiaryPumpsValue >= 5 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_tertiary_pumps_5_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_tertiary_pumps_5_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfTertiaryPumpsValue >= 6 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_tertiary_pumps_6_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_tertiary_pumps_6_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfTertiaryPumpsValue >= 7 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_tertiary_pumps_7_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_tertiary_pumps_7_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfTertiaryPumpsValue >= 8 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_tertiary_pumps_8_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_tertiary_pumps_8_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfTertiaryPumpsValue >= 9 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_tertiary_pumps_9_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_tertiary_pumps_9_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfTertiaryPumpsValue >= 10 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_tertiary_pumps_10_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_tertiary_pumps_10_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="yellowcol">
                            Cooling Tower Information
                          </label>
                        </div>

                        <div className="col-lg-6 col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              name="cooling_tower_type_op"
                              value={formData?.cooling_tower_type_op}
                              onChange={handleInputChange}
                              id=""
                              className="form-select w-100"
                            >
                              <option value="" disabled>
                                ------------------------- Motor Type
                                -------------------------
                              </option>
                              <option
                                value="Fixed"
                                selected={
                                  formData?.cooling_tower_type_op ===
                                  ("Fixed" || formData?.cooling_tower_type_op)
                                }
                              >
                                Fixed
                              </option>
                              <option
                                value="Varible"
                                selected={
                                  formData?.cooling_tower_type_op ===
                                  ("Varible" || formData?.cooling_tower_type_op)
                                }
                              >
                                Varible
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-4">
                              <label htmlFor="floatingInput">
                                Number of Cooling Towers
                              </label>
                            </div>
                            <div className="col-lg-8 col-md-4">
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={handleCoolingTowersDecrement}
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfCoolingTowersValue}
                                    name="no_of_cooling_towers_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={handleCoolingTowersIncrement}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {noOfCoolingTowersValue >= 1 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_cooling_towers_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_cooling_towers_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCoolingTowersValue >= 2 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_cooling_towers_2_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_cooling_towers_2_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCoolingTowersValue >= 3 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_cooling_towers_3_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_cooling_towers_3_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCoolingTowersValue >= 4 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_cooling_towers_4_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_cooling_towers_4_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCoolingTowersValue >= 5 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_cooling_towers_5_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_cooling_towers_5_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCoolingTowersValue >= 6 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_cooling_towers_6_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_cooling_towers_6_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCoolingTowersValue >= 7 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_cooling_towers_7_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_cooling_towers_7_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCoolingTowersValue >= 8 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_cooling_towers_8_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_cooling_towers_8_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCoolingTowersValue >= 9 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_cooling_towers_9_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_cooling_towers_9_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {noOfCoolingTowersValue >= 10 ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.capacity_of_cooling_towers_10_op
                                  }
                                  onChange={handleInputChange}
                                  name="capacity_of_cooling_towers_10_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Capacity(kW)
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="yellowcol">
                            Renewable Energy Information
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              <label htmlFor="" className="text-white">
                                Is Renewable Energy Available?
                              </label>
                            </div>

                            <div className="col-lg-12 col-md-12 position-relative">
                              <div className="d-flex">
                                <div className="radiocust">
                                  <label className="text-white">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_renewal_energy_applicable_op"
                                      value="No"
                                      onChange={handleRenewalRadioChange}
                                      checked={
                                        is_renewal_energy_applicable_op ===
                                          "No" ||
                                        formData.is_renewal_energy_applicable_op ===
                                          "No" ||
                                        formData.is_renewal_energy_applicable_op ===
                                          ""
                                      }
                                    />
                                    No
                                  </label>
                                </div>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <div className="radiocust">
                                  <label className="text-white">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_renewal_energy_applicable_op"
                                      value="Yes"
                                      onChange={handleRenewalRadioChange}
                                      checked={
                                        is_renewal_energy_applicable_op ===
                                          "Yes" ||
                                        formData?.is_renewal_energy_applicable_op ===
                                          "Yes"
                                      }
                                    />
                                    Yes
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {formData?.is_renewal_energy_applicable_op === "Yes" ? (
                          <>
                            <div className="col-lg-12">
                              <div className="form-floating mb-2">
                                <input
                                  value={
                                    formData?.is_renewal_energy_generated_op
                                  }
                                  onChange={handleInputChange}
                                  name="is_renewal_energy_generated_op"
                                  type="number"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  % of Energy Generated from Renewable Sources
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="yellowcol">
                            Sub-metering Information
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="row">
                            <div className="col-lg-6">
                              <label htmlFor="" className="text-white">
                                Is Sub-metering Available?
                              </label>
                            </div>
                            {/* <div className="col-lg-6 col-md-6 position-relative">
                              <div className="check">
                                <div className=" checkbox-info mb-2">
                                  <input
                                    type="checkbox"
                                    value={isSubmeterSubscribed}
                                    onChange={
                                      handleChangeIsSubMeteringAvailable
                                    }
                                    name="is_submeter_available_op"
                                  />
                                  <label className="text-white">
                                    &nbsp;&nbsp;
                                    {isSubmeterSubscribed ? "Yes" : "No"}
                                  </label>
                                </div>
                              </div>
                            </div> */}

                            <div className="col-lg-6 col-md-12 position-relative">
                              <div className="d-flex">
                                <div className="radiocust">
                                  <label className="text-white">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_submeter_available_op"
                                      value="No"
                                      checked={
                                        // is_submeter_available_op === "No" ||
                                        formData?.is_submeter_available_op ===
                                          "No" ||
                                        is_submeter_available_op === "No" ||
                                        formData?.is_submeter_available_op ===
                                          ""
                                      }
                                      onChange={handleSubmeterRadioChange}
                                      // checked={formData?.is_submeter_available_op === "No"}
                                    />
                                    No
                                  </label>
                                </div>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <div className="radiocust">
                                  <label className="text-white">
                                    <input
                                      className="mr-1"
                                      type="radio"
                                      name="is_submeter_available_op"
                                      value="Yes"
                                      onChange={handleSubmeterRadioChange}
                                      checked={
                                        formData?.is_submeter_available_op ===
                                          "Yes" ||
                                        is_submeter_available_op === "Yes"
                                      }
                                    />
                                    Yes
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center">
                        {formData?.is_submeter_available_op === "Yes" ? (
                          <>
                            <div className="col-lg-12 col-md-12">
                              <div className="table-responsive">
                                <table className="table table-blk">
                                  <thead>
                                    <tr className="bg-success">
                                      <th>Pump type</th>
                                      <th>Power Input (kWh / year)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-white">
                                            Primary Pump
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_primary_pump_power_input
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_primary_pump_power_input"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-white">
                                            Secondary Pump
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_secondary_pump_power_input
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_secondary_pump_power_input"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-white">
                                            Condenser Pump
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_condenser_pump_power_input
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_condenser_pump_power_input"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-white">
                                            Tertiary Pump
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_tertiary_pump_power_input
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_tertiary_pump_power_input"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-white">
                                            Cooling Tower
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_cooling_tower_power_input
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_cooling_tower_power_input"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-white">
                                            Chiller information
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_chiller_infomation
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_chiller_infomation"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-white">
                                            Thermal Store Infomation
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_termal_store_infomation
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_termal_store_infomation"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="form-floating">
                                          <p className="text-white">
                                            Renewable energy information
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-floating">
                                          <input
                                            value={
                                              formData?.sub_meter_renewal_energy_infomation
                                            }
                                            onChange={handleInputChange}
                                            name="sub_meter_renewal_energy_infomation"
                                            type="number"
                                            pattern="[0-9]*"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Ex.1144"
                                          />
                                          <label htmlFor="floatingInput">
                                            Power Input (kWh / year)
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="yellowcol">
                            Yearly Power Consumption Per Year
                          </label>
                        </div>
                        <div className="row d-flex justify-content-center align-items-center">
                          <div className="col-lg-6">
                            <label
                              className="font-weight-bold"
                              htmlFor="floatingInput"
                            >
                              Number of Years
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <div className="row d-flex  align-items-center">
                              {/* <label className="font-weight-bold" htmlFor="floatingInput">Number of Years</label> */}
                              <div className="form-group mb-2 mt-1">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-down"
                                      type="button"
                                      onClick={
                                        handleYearlyPowerConsumptionDecrement
                                      }
                                    >
                                      -
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    step="any"
                                    value={noOfYearlyPowerConsumptionValue}
                                    name="no_of_yearly_power_consumption_op"
                                    className="text-center form-control"
                                    onChange={handleInputChange}
                                  />

                                  <span className="input-group-btn input-group-append">
                                    <button
                                      className="btn btn-primary bootstrap-touchspin-up"
                                      type="button"
                                      onClick={
                                        handleYearlyPowerConsumptionIncrement
                                      }
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        {noOfYearlyPowerConsumptionValue >= 1 ? (
                          <>
                            <div className="col-lg-4">
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  onChange={handleInputChange}
                                  name="power_consumption_per_years_op"
                                  pattern="[0-9]*"
                                  step="any"
                                  value={
                                    formData?.power_consumption_per_years_op
                                  }
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                                {formDataErrors.power_consumption_per_years_op && (
                                  <div className="text-danger">
                                    *** Required Power Consumption Per Year
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfYearlyPowerConsumptionValue >= 2 ? (
                          <>
                            <div className="col-lg-4">
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  name="power_consumption_per_years_2_op"
                                  onChange={handleInputChange}
                                  value={
                                    formData?.power_consumption_per_years_2_op
                                  }
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfYearlyPowerConsumptionValue >= 3 ? (
                          <>
                            <div className="col-lg-4">
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  onChange={handleInputChange}
                                  value={
                                    formData?.power_consumption_per_years_3_op
                                  }
                                  name="power_consumption_per_years_3_op"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfYearlyPowerConsumptionValue >= 4 ? (
                          <>
                            <div className="col-lg-4">
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  onChange={handleInputChange}
                                  name="power_consumption_per_years_4_op"
                                  pattern="[0-9]*"
                                  step="any"
                                  value={
                                    formData?.power_consumption_per_years_4_op
                                  }
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfYearlyPowerConsumptionValue >= 5 ? (
                          <>
                            <div className="col-lg-4">
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  name="power_consumption_per_years_5_op"
                                  pattern="[0-9]*"
                                  onChange={handleInputChange}
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  value={
                                    formData?.power_consumption_per_years_5_op
                                  }
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfYearlyPowerConsumptionValue >= 6 ? (
                          <>
                            <div className="col-lg-4">
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  onChange={handleInputChange}
                                  name="power_consumption_per_years_6_op"
                                  pattern="[0-9]*"
                                  value={
                                    formData?.power_consumption_per_years_6_op
                                  }
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfYearlyPowerConsumptionValue >= 7 ? (
                          <>
                            <div className="col-lg-4">
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  onChange={handleInputChange}
                                  value={
                                    formData?.power_consumption_per_years_7_op
                                  }
                                  name="power_consumption_per_years_7_op"
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfYearlyPowerConsumptionValue >= 8 ? (
                          <>
                            <div className="col-lg-4">
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  name="power_consumption_per_years_8_op"
                                  onChange={handleInputChange}
                                  value={
                                    formData?.power_consumption_per_years_8_op
                                  }
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfYearlyPowerConsumptionValue >= 9 ? (
                          <>
                            <div className="col-lg-4">
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  onChange={handleInputChange}
                                  name="power_consumption_per_years_9_op"
                                  value={
                                    formData?.power_consumption_per_years_9_op
                                  }
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {noOfYearlyPowerConsumptionValue >= 10 ? (
                          <>
                            <div className="col-lg-4">
                              <div className="form-floating mb-2">
                                <input
                                  type="number"
                                  onChange={handleInputChange}
                                  name="power_consumption_per_years_10_op"
                                  value={
                                    formData?.power_consumption_per_years_10_op
                                  }
                                  pattern="[0-9]*"
                                  step="any"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Ex.1144"
                                />
                                <label htmlFor="floatingInput">
                                  Power Consumption Per Year
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="form-group row justify-content-end prj">
                        <div className="col-lg-12 text-right">
                          <button
                            type="submit"
                            className="btn btn-pryellow mr-1 waves-effect waves-light"
                          >
                            Verify & Calculate
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </>)
      } */}
    </>
  );
};

export default DEOperationalPhase;
