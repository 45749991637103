import React from 'react'

const Pagenotfound = () => {
  return (
    <div>
      <h1 style={{color:"white",textAlign:"center",margin:"0px auto",marginTop:"15px"}}>Page not found</h1>
    </div>
  )
}

export default Pagenotfound
 