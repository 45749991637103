import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";
import InputWidget from "./widgets/InputWidget";
import {
  BaseURL,
  GoogleMapApiKey,
  projectDetails,
  projectLocationUpdate,
  totalProjectsUrl,
} from "../../globalURL";
import { toast } from "react-toastify";
import { Loader } from "@googlemaps/js-api-loader";
import OperationalPhaseDetails from "./OperationalPhaseDetails";
import { useNavigate } from "react-router-dom";
import { getAccessTokenLocal } from "../../common/common_helper";
import UseServices from "../../services/api";

const ViewProjectDetails = () => {
  const autocompleteInputRef = useRef(null);
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const [isPrint, setIsPrint] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        window.print();
        setIsPrint(false);
      }, [500]);
    } else {
      setTimeout(() => {
        window.close();
      }, [1000]);
    }
  }, [isPrint]);

  const queryParams = new URLSearchParams(window.location.search);
  const projectId = queryParams.get("project_id");

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    access_token: getAccessTokenLocal(),
    no_of_fans: 10,
    lat: 17.245142,
    long: 83.245142,
    project_id: "",
    project_name: "",
    asbuilt: false,
    asoperated: false,
    asbuilt_and_asoperated: false,
    page: 1,
    page_limit: 200,
  });

  const [markersList, setMarkersList] = useState([]);
  const [centerObj, setCenterObj] = useState({
    lat: 25.2854,
    lng: 51.531,
  });

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const loader = new Loader({
    apiKey: "AIzaSyAz7eBSILkvevhoc65tDmnqOIMJVw9BEpw",
    version: "weekly",
    libraries: ["places"],
  });

  const initializeMap = () => {
    loader.load().then(() => {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: formData.lat, lng: formData.long },
        zoom: 8,
      });

      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteInputRef.current
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.error("Place details not available for", place);
          return;
        }

        locationUpdateChange(
          place.formatted_address,
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
        setFormData({
          ...formData,
          lat: place.geometry.location.lat(),
          long: place.geometry.location.lng(),
        });
      });

      markersList.forEach((markerData) => {
        const marker = new window.google.maps.Marker({
          position: markerData.location,
          map: map,
          title: markerData.name,
        });

        const infowindow = new window.google.maps.InfoWindow({
          content: markerData.info,
        });

        marker.addListener("click", () => {
          infowindow.open(map, marker);
        });
      });
    });
  };

  // useEffect(() => {
  //   initializeMap();
  // }, []);
  useEffect(() => {
    if (markersList.length > 0) {
      initializeMap(); // Reinitialize the map when markersList updates
    }
  }, [markersList]);

  useEffect(() => {
    if (isLoading) {
      fetchAllProjects(); // Fetch project details and update markersList
    }
  }, [isLoading]);

  // useEffect(() => {
  //    if(isLoading){

  //      loader.load().then(() => {
  //        const map = new window.google.maps.Map(document.getElementById("map"), {
  //          center: { lat: formData.lat, lng: formData.long },
  //          zoom: 8,
  //        });

  //  const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInputRef.current);

  //    autocomplete.addListener('place_changed', () => {
  //      const place = autocomplete.getPlace();
  //      if (!place.geometry) {
  //        console.error('Place details not available for', place);
  //        return;
  //      }

  //       locationUpdateChange(place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng());
  //      setFormData({
  //        ...formData,
  //        lat: place.geometry.location.lat(),
  //        long: place.geometry.location.lng(),
  //      });
  //    });

  //        markersList.forEach((markerData) => {
  //          const marker = new window.google.maps.Marker({
  //            position: markerData.location,
  //            map: map,
  //            title: markerData.name,
  //          });

  //          const infowindow = new window.google.maps.InfoWindow({
  //            content: markerData.info,
  //          });

  //          marker.addListener("click", () => {
  //            infowindow.open(map, marker);
  //          });
  //        });
  //      });

  //    }
  // }, [isLoading])

  // useEffect(() => {
  // if (dataFetchedRef.current) return;
  // dataFetchedRef.current = true;
  //   fetchAllProjects();
  // }, []);

  // console.log('markersList', markersList);

  // const handleMapPointer = (event) => {
  //   event.preventDefault();
  //   fetchAllProjects();
  // };

  const fetchAllProjects = async (props) => {
    setIsLoading(true);

    UseServices.fetchPostData(totalProjectsUrl, formData)
      .then((response) => {
        setIsLoading(false);
        // console.log("result : ")
        // console.log(response["data"]["projects"].length)
        // set user details based on response data
        if (response.data.projects) {
          let markersListData = [];
          let setLat = true;
          // console.log("Pro Len ::::" + response["data"]["projects"].length);
          response["data"]["projects"].map((app, index) => {
            if (app["lat"] !== "" && app["long"] !== "") {
              if (setLat) {
                // alert(index);
                setCenterObj({ lat: app["lat"], lng: app["long"] });
                // setDefaultLat(app["lat"]);
                // setDefaultLong(app["long"]);
                setLat = false;
              }
              let system_seer = parseFloat(app["system_seer_kw"]).toFixed(2);
              // console.log("system_seer", system_seer);
              let chiller_seer = parseFloat(app["chiller_seer_kw"]).toFixed(2);
              let system_seer_kw_ton = parseFloat(
                app["system_seer_kw_ton"]
              ).toFixed(2);
              let chiller_seer_kw_ton = parseFloat(
                app["chiller_seer_kw_ton"]
              ).toFixed(2);

              let system_seer_op = parseFloat(app["system_seer_op"]).toFixed(2);
              let system_performance_op = parseFloat(
                app["system_performance_op"]
              ).toFixed(2);
              let gsas_energy_efficiency_op = app["gsas_energy_efficiency_op"];

              let infowindo = "";
              if (app["project_phase"] === "operational") {
                infowindo =
                  "<table className='table'><tbody><tr className='table-default'><th className='text-left'>  Project ID </th><th className='text-left'> : </th><th className='text-left'>  " +
                  app["gsas_project_id"] +
                  "  </th></tr><tr className='table-default'><th className='text-left'> Project Name </th><th className='text-left'> : </th><th className='text-left'>  " +
                  app["project_name"] +
                  "  </th></tr><tr className='table-default'><th className='text-left'> Project Phase  </th><th className='text-left'> : </th><th className='text-left'>  " +
                  app["project_phase"] +
                  "  </th></tr><tr className='table-default'><th className='text-left'> System SEER  </th><th className='text-left'> : </th><th className='text-left'>  " +
                  system_seer_op +
                  "  </th></tr><tr className='table-default'><th className='text-left'> System Performance </th><th className='text-left'> : </th><th className='text-left'>  " +
                  system_performance_op +
                  "  </th></tr><tr className='table-default'><th className='text-left'> Energy Efficiency </th><th className='text-left'> : </th><th className='text-left'>  " +
                  gsas_energy_efficiency_op +
                  "  </th></tr></tbody></table>";
              } else {
                infowindo =
                  "<table className='table'><tbody><tr className='table-default'><th className='text-left'>  Project ID </th><th className='text-left'> : </th><th className='text-left'>  " +
                  app["gsas_project_id"] +
                  "  </th></tr><tr className='table-default'><th className='text-left'> Project Name </th><th className='text-left'> : </th><th className='text-left'>  " +
                  app["project_name"] +
                  "  </th></tr><tr className='table-default'><th className='text-left'> Project Phase  </th><th className='text-left'> : </th><th className='text-left'>  " +
                  app["project_phase"] +
                  "  </th></tr><tr className='table-default'><th className='text-left'> System SEER  </th><th className='text-left'> : </th><th className='text-left'>  " +
                  system_seer +
                  "  </th></tr><tr className='table-default'><th className='text-left'> Chiller SEER </th><th className='text-left'> : </th><th className='text-left'>  " +
                  chiller_seer +
                  "  </th></tr><tr className='table-default'><th className='text-left'> System performance </th><th className='text-left'> : </th><th className='text-left'>  " +
                  system_seer_kw_ton +
                  "  </th></tr><tr className='table-default'><th className='text-left'> Chiller Performance   </th><th className='text-left'> : </th><th className='text-left'>  " +
                  chiller_seer_kw_ton +
                  "  </th></tr></tbody></table>";
              }
              if (app["search_location"]) {
                markersListData.push({
                  name: app["search_location"],
                  location: {
                    lat: app["lat"],
                    lng: app["long"],
                  },
                  project_name: app["project_name"],
                  gsas_project_id: app["gsas_project_id"],
                  project_phase: app["project_phase"],
                  system_seer: system_seer,
                  chiller_seer: chiller_seer,
                  system_seer_kw_ton: system_seer_kw_ton,
                  chiller_seer_kw_ton: chiller_seer_kw_ton,
                  system_seer_op: system_seer_op,
                  system_performance_op: system_performance_op,
                  gsas_energy_efficiency_op: gsas_energy_efficiency_op,
                  info: infowindo,
                });
              }
            }
          });
          // console.log(markersListData);
          setMarkersList(markersListData);
        } else {
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        console.error(error);
      });
  };

  const handleExport = () => {
    // setIsPrint(true);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(projectDetails, JSON.stringify({ project_id: projectId }), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        // console.log("res", res.data);
        // console.log("view details res", res["data"]["data"]);
        let result = res["data"]["data"];
        setFormData(result);
        setIsLoading(false);
        setIsPrint(true);
      })

      .catch((error) => {
        console.log(error);
      });
  }, [projectId]);

  // useEffect(() => {
  //   if (dataFetchedRef.current) return;
  //   dataFetchedRef.current = true;
  //   getProjectDetails();
  // }, []);

  // const handlePrint = useReactToPrint({
  //   content: () => componentToPrintRef.current,
  //   pageStyle: `
  //     .printable-content {
  //       background-color: blue;
  //       color: white
  //     }
  //   `,
  // });

  const locationUpdateChange = async (searchLocation, lattitude, longitude) => {
    setIsLoading(true);
    await axios
      .post(
        projectLocationUpdate,
        JSON.stringify({
          id: projectId,
          search_location: searchLocation,
          lat: lattitude,
          long: longitude,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        toast.success(res.data.message);
        getProjectDetails();
      })

      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const getProjectDetails = async () => {
    setIsLoading(true);
    await axios
      .post(projectDetails, JSON.stringify({ project_id: projectId }), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setIsLoading(false);
        // console.log("sel", res["data"]["data"]);
        let result = res["data"]["data"];
        // console.log("resulttttttt", result);
        setFormData(result);
        if (result["project_status"] === "pending") {
          // alert("PENDING");
        } else if (result["project_status"] === "completed") {
          // lat: 17.245142, long: 83.245142
        } else {
          // cancelled
        }
      })

      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  // const loader = new Loader({
  //   apiKey: "AIzaSyAz7eBSILkvevhoc65tDmnqOIMJVw9BEpw",
  //   version: "weekly",
  //   libraries: ["places"],
  // });

  // loader.load().then(() => {
  //   const map = new window.google.maps.Map(document.getElementById("map"), {
  //     center: { lat: formData.lat, lng: formData.long },
  //     zoom: 8,
  //   });

  //   const marker = new window.google.maps.Marker({
  //     position: { lat: formData.lat, lng: formData.long },
  //     map: map,
  //     title: formData.search_location,
  //   });

  //   const infowindow = new window.google.maps.InfoWindow({
  //     content: formData.search_location,
  //   });

  //   marker.addListener("click", () => {
  //     infowindow.open(map, marker);
  //   });
  // });

  return (
    <div>
      <div className="">
        <div className="content">
          <div className="container-fluid">
            <div className="row justify-content-center printable-content">
              <div className="col-lg-10">
                <div className="card transparent">
                  <div className="card-body pt-1">
                    <div className="row profilepg">
                      <div className="col-lg-12">
                        {isPrint ? (
                          <>
                            <div className="card border-0">
                              <div className="row align-items-center justify-content-between">
                                <div className="col-3">
                                  <img
                                    src="assets/images/logo.png"
                                    alt=""
                                    width={150}
                                    height={60}
                                  />
                                </div>

                                <div className="col-6">
                                  <h2 className="text-white text-center mb-0 my-4">
                                    View Project Details
                                  </h2>
                                </div>

                                <div className="col-3 text-right">
                                  <img
                                    src="assets/images/logo-1.png"
                                    alt=""
                                    width={150}
                                    height={60}
                                  />
                                </div>
                              </div>

                              <>
                                <h3
                                  style={{ fontSize: "18px" }}
                                  className="yellowcol bg-success text-center p-2"
                                >
                                  Project Information
                                </h3>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="row">
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData._id}
                                          type={"text"}
                                          label="Project Referance ID"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          // value={formData.project_date}
                                          value={new Date(
                                            formData.project_date
                                          ).toLocaleString()}
                                          type={"text"}
                                          label="Date"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.gsas_project_id}
                                          type={"text"}
                                          label="Project ID"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.project_name}
                                          type={"text"}
                                          label="Project Name"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.consultant_name}
                                          type={"text"}
                                          label="Consultant Name"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.client_name}
                                          type={"text"}
                                          label="Client Name"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.project_phase}
                                          type={"text"}
                                          label="Project Phase"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.country_id}
                                          type={"text"}
                                          label="Country"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.city_id}
                                          type={"text"}
                                          label="City"
                                        />

                                        {/* <InputWidget cls="col-md-6" readonly={false} value={formData.project_status} type={"text"} label="project_status" /> */}
                                      </div>
                                      {formData.project_phase !==
                                        "As Operated" && (
                                        <>
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            Chillers
                                          </h3>
                                          <div className="row">
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={formData.no_of_chillers}
                                              type={"text"}
                                              label="No of Chillers"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_1_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 1 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_2_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 2 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_3_capacity
                                              }
                                              type={"text"}
                                              label="chiller_3_capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_4_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 4 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_5_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 5 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_6_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 6 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_7_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 7 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_8_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 8 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_9_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 9 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_10_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 10 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.if_capacity_is_lower_than_minimum_will_the_chiller_start
                                              }
                                              type={"text"}
                                              label="If Capacity Is Lower Than Minimum Will The Chiller Start"
                                            />

                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.minimum_start_up_capacity
                                              }
                                              type={"text"}
                                              label="Minimum Start Up Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={formData.nominal_cop}
                                              type={"text"}
                                              label="Nominal Cop"
                                            />
                                            {/* <InputWidget
                                              cls="col-md-12"
                                              readonly={true}
                                              value={
                                                formData.cooling_load_profile_file
                                              }
                                              type={"text"}
                                              label="Cooling Load Profile_File"
                                            /> */}
                                            <div className="col-md-6">
                                              <label
                                                style={{ fontSize: "12px" }}
                                                className="form-floating"
                                              >
                                                Cooling Load Profile File:
                                                {formData?.cooling_load_profile_file && (
                                                  <>
                                                    <a
                                                      rel="noreferrer"
                                                      style={{
                                                        color: "#FFFF00",
                                                        marginLeft: "15px",
                                                        fontSize: "14px",
                                                      }}
                                                      href={`${BaseURL}${formData?.cooling_load_profile_file}`}
                                                      target="_blank"
                                                    >
                                                      View document
                                                    </a>
                                                  </>
                                                )}
                                              </label>
                                            </div>
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.distribution_losses_type
                                              }
                                              type={"text"}
                                              label="distribution_losses_type"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.distribution_losses
                                              }
                                              type={"text"}
                                              label="distribution_losses"
                                            />

                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.condenser_cooling_media
                                              }
                                              type={"text"}
                                              label="Condenser Cooling Media"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={formData.compressor_type}
                                              type={"text"}
                                              label="Compressor Type"
                                            />

                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.condenser_temperature
                                              }
                                              type={"text"}
                                              label="Condenser Temperature"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.evaporator_temperature
                                              }
                                              type={"text"}
                                              label="Evaporator Temperature"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={formData.condenser_delta_t}
                                              type={"text"}
                                              label="Condenser Delta t"
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    {formData.project_phase !==
                                      "As Operated" && (
                                      <>
                                        <div className="col-md-12">
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            ThermalStore
                                          </h3>
                                        </div>
                                        {formData.is_thermal_store_available ===
                                        "No" ? (
                                          <InputWidget
                                            cls="col-md-4"
                                            readonly={true}
                                            value={
                                              formData.is_thermal_store_available
                                            }
                                            type={"text"}
                                            label="Is Thermal Store Available"
                                          />
                                        ) : (
                                          <>
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={
                                                formData.thermal_store_capacity_kwh
                                              }
                                              type={"text"}
                                              // label="Thermal Store Capacity KWH"
                                              label={`Thermal Store Capacity ${formData?.selected_units}`}
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={
                                                formData.lower_allowance_capacity_in_percentage
                                              }
                                              type={"text"}
                                              label="Lower Allowance Capacity In Percentage"
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={
                                                formData.charging_capacity_kwh_per_hr_maximum
                                              }
                                              type={"text"}
                                              // label="Charging Capacity KWH per hr maximum"
                                              label={`Charging Capacity ${formData?.selected_units} per hr minimum`}
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={
                                                formData.charging_capacity_kwh_per_hr_minimum
                                              }
                                              type={"text"}
                                              // label="Charging Capacity KWH per hr minimum"
                                              label={`Charging Capacity ${formData?.selected_units} per hr minimum`}
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={
                                                formData.maximum_daily_thershold
                                              }
                                              type={"text"}
                                              label="Maximum Daily Threshold"
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={formData.charging_cycles}
                                              type={"text"}
                                              label="Charging Cycles"
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={formData.losses_per_day}
                                              type={"text"}
                                              label="Losses Per Day"
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={formData.tes_pumps}
                                              type={"text"}
                                              label="Tes Pumps"
                                            />
                                          </>
                                        )}
                                      </>
                                    )}
                                    {formData.project_phase !==
                                      "As Operated" && (
                                      <>
                                        <>
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3
                                                style={{ fontSize: "18px" }}
                                                className="yellowcol bg-success text-center p-2"
                                              >
                                                Pumps
                                              </h3>
                                            </div>
                                            {formData.condenser_pump_type &&
                                            formData.condenser_pump_load_100 &&
                                            formData.condenser_pump_power_input_100 ? (
                                              <section className="w-100">
                                                <div className="col-lg-6">
                                                  <h3 className="yellowcol text-center clearfix">
                                                    Condenser Pump
                                                  </h3>
                                                  <div className="row justify-content-center">
                                                    <div className="col-lg-12 col-md-12">
                                                      <div className="form-floating mb-2">
                                                        <InputWidget
                                                          cls="col-md-12"
                                                          readonly={true}
                                                          value={
                                                            formData.condenser_pump_type
                                                          }
                                                          type={"text"}
                                                          label="Condenser Pump Type"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row justify-content-center">
                                                    <div className="col-lg-12">
                                                      <table className="table-blk w-100">
                                                        <thead>
                                                          <tr className="bg-primary">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>
                                                              Pump Load(%)
                                                            </th>
                                                            {/* <th>Power Input(KW)</th> */}
                                                            <th>
                                                              {" "}
                                                              {`Power Input( ${formData?.selected_units})`}{" "}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <InputWidget
                                                                cls="col-md-12"
                                                                readonly={true}
                                                                value={
                                                                  formData.condenser_pump_load_100
                                                                }
                                                                type={"number"}
                                                                label="Pump Load 100"
                                                              />
                                                            </td>
                                                            <td>
                                                              <InputWidget
                                                                cls="col-md-12"
                                                                readonly={true}
                                                                value={
                                                                  formData.condenser_pump_power_input_100
                                                                }
                                                                type={"number"}
                                                                label="Power Input 100"
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <InputWidget
                                                                cls="col-md-12"
                                                                readonly={true}
                                                                value={
                                                                  formData.condenser_pump_load_75
                                                                }
                                                                type={"number"}
                                                                label="Pump Load 75"
                                                              />
                                                            </td>
                                                            <td>
                                                              <InputWidget
                                                                cls="col-md-12"
                                                                readonly={true}
                                                                value={
                                                                  formData.condenser_pump_power_input_75
                                                                }
                                                                type={"number"}
                                                                label="Power Input 75"
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <InputWidget
                                                                cls="col-md-12"
                                                                readonly={true}
                                                                value={
                                                                  formData.condenser_pump_load_50
                                                                }
                                                                type={"number"}
                                                                label="Pump Load 50"
                                                              />
                                                            </td>
                                                            <td>
                                                              <InputWidget
                                                                cls="col-md-12"
                                                                readonly={true}
                                                                value={
                                                                  formData.condenser_pump_power_input_50
                                                                }
                                                                type={"number"}
                                                                label="Power Input 50"
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <InputWidget
                                                                cls="col-md-12"
                                                                readonly={true}
                                                                value={
                                                                  formData.condenser_pump_load_25
                                                                }
                                                                type={"number"}
                                                                label="Pump Load 25"
                                                              />
                                                            </td>
                                                            <td>
                                                              <InputWidget
                                                                cls="col-md-12"
                                                                readonly={true}
                                                                value={
                                                                  formData.condenser_pump_power_input_25
                                                                }
                                                                type={"number"}
                                                                label="Power Input 25"
                                                              />
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </section>
                                            ) : (
                                              ""
                                            )}

                                            {formData.primary_pump_type &&
                                            formData.primary_pump_load_100 &&
                                            formData.primary_pump_power_input_100 ? (
                                              <>
                                                <div className="col-lg-6 my-4">
                                                  <h3 className="yellowcol text-center clearfix">
                                                    Primary Pump
                                                  </h3>
                                                  <div className="row">
                                                    <div className="col-lg-12 col-md-12">
                                                      <div className="form-floating mb-2">
                                                        <InputWidget
                                                          cls="col-md-12"
                                                          readonly={true}
                                                          value={
                                                            formData.primary_pump_type
                                                          }
                                                          type={"text"}
                                                          label="Primary Pump Type"
                                                        />
                                                      </div>
                                                    </div>
                                                    <table className="table-blk w-100">
                                                      <thead>
                                                        <tr className="bg-primary">
                                                          <th>
                                                            Chiller&nbsp;Load(%)
                                                          </th>
                                                          <th>Pump Load(%)</th>
                                                          {/* <th>
                                                            Power Input(kW)
                                                          </th> */}
                                                          <th>
                                                            {" "}
                                                            {`Power Input( ${formData?.selected_units})`}{" "}
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td>100</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.primary_pump_load_100
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 100"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.primary_pump_power_input_100
                                                              }
                                                              type={"number"}
                                                              label="Power Input 100"
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>75</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.primary_pump_load_75
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 75"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.primary_pump_power_input_75
                                                              }
                                                              type={"number"}
                                                              label="Power Input 75"
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>50</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.primary_pump_load_50
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 50"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.primary_pump_power_input_50
                                                              }
                                                              type={"number"}
                                                              label="Power Input 50"
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>25</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.primary_pump_load_25
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 25"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.primary_pump_power_input_25
                                                              }
                                                              type={"number"}
                                                              label="Power Input 25"
                                                            />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}

                                            {formData.secondary_pump_type &&
                                            formData.secondary_pump_load_100 &&
                                            formData.secondary_pump_power_input_100 ? (
                                              <>
                                                <div className="col-lg-6">
                                                  <h3 className="yellowcol text-center clearfix">
                                                    Secondary Pump
                                                  </h3>
                                                  <div className="row">
                                                    <div className="col-lg-12 col-md-12">
                                                      <div className="form-floating mb-2">
                                                        <InputWidget
                                                          cls="col-md-12"
                                                          readonly={true}
                                                          value={
                                                            formData.secondary_pump_type
                                                          }
                                                          type={"text"}
                                                          label="Secondary Pump Type"
                                                        />
                                                      </div>
                                                    </div>
                                                    <table className="table-blk w-100">
                                                      <thead>
                                                        <tr className="bg-primary">
                                                          <th>
                                                            Chiller&nbsp;Load(%)
                                                          </th>
                                                          <th>Pump Load(%)</th>
                                                          {/* <th>
                                                            Power Input(kW)
                                                          </th> */}

                                                          <th>
                                                            {" "}
                                                            {`Power Input( ${formData?.selected_units})`}{" "}
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td>100</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.secondary_pump_load_100
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 100"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.secondary_pump_power_input_100
                                                              }
                                                              type={"number"}
                                                              label="Power Input 100"
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>75</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.secondary_pump_load_75
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 75"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.secondary_pump_power_input_75
                                                              }
                                                              type={"number"}
                                                              label="Power Input 75"
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>50</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.secondary_pump_load_50
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 50"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.secondary_pump_power_input_50
                                                              }
                                                              type={"number"}
                                                              label="Power Input 50"
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>25</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.secondary_pump_load_25
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 25"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.secondary_pump_power_input_25
                                                              }
                                                              type={"number"}
                                                              label="Power Input 25"
                                                            />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}

                                            {formData.tertiary_pump_type &&
                                            formData.tertiary_pump_load_100 &&
                                            formData.tertiary_pump_power_input_100 ? (
                                              <>
                                                <div className="col-lg-6">
                                                  <h3 className="yellowcol text-center clearfix">
                                                    Tertiary Pump
                                                  </h3>
                                                  <div className="row">
                                                    <div className="col-lg-12 col-md-12">
                                                      <div className="form-floating mb-2">
                                                        <InputWidget
                                                          cls="col-md-12"
                                                          readonly={true}
                                                          value={
                                                            formData.tertiary_pump_type
                                                          }
                                                          type={"text"}
                                                          label="Tertiary Pump Type"
                                                        />
                                                      </div>
                                                    </div>
                                                    <table className="table-blk w-100">
                                                      <thead>
                                                        <tr className="bg-primary">
                                                          <th>
                                                            Chiller&nbsp;Load(%)
                                                          </th>
                                                          <th>Pump Load(%)</th>
                                                          {/* <th>
                                                            Power Input(kW)
                                                          </th> */}
                                                          <th>
                                                            {" "}
                                                            {`Power Input( ${formData?.selected_units})`}{" "}
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td>100</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.tertiary_pump_load_100
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 100"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.tertiary_pump_power_input_100
                                                              }
                                                              type={"number"}
                                                              label="Power Input 100"
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>75</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.tertiary_pump_load_75
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 75"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.tertiary_pump_power_input_75
                                                              }
                                                              type={"number"}
                                                              label="Power Input 75"
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>50</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.tertiary_pump_load_50
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 50"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.tertiary_pump_power_input_50
                                                              }
                                                              type={"number"}
                                                              label="Power Input 50"
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>25</td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.tertiary_pump_load_25
                                                              }
                                                              type={"number"}
                                                              label="Pump Load 25"
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              cls="col-md-12"
                                                              readonly={true}
                                                              value={
                                                                formData.tertiary_pump_power_input_25
                                                              }
                                                              type={"number"}
                                                              label="Power Input 25"
                                                            />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </>
                                        <div className="col-md-12">
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            Auxiliaries
                                          </h3>
                                        </div>
                                        <div className="row ">
                                          <InputWidget
                                            cls="col-md-12"
                                            readonly={true}
                                            value={
                                              formData.other_auxiliaries_kwh
                                            }
                                            type={"text"}
                                            // label="Other Auxiliaries (kWh)"
                                            label={`Other Auxiliaries (${formData?.selected_units}h)`}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>

                                  {formData.project_phase === "As Operated" && (
                                    <>
                                      <OperationalPhaseDetails
                                        formData={formData}
                                      />
                                    </>
                                  )}
                                  <br />
                                  {formData.project_phase !== "As Operated" && (
                                    <>
                                      <div className={"row clearfix pd-10"}>
                                        <div className="col-md-12">
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            Fans
                                          </h3>
                                        </div>
                                        <div className="row">
                                          {formData.no_of_fans >= 1 ? (
                                            <div className="col-lg-4">
                                              <div className="row">
                                                <div className="col-lg-12">
                                                  <div className="page-title-box">
                                                    <h4 className="page-title fz-16">
                                                      Fan 1 {formData.fan1_type}
                                                    </h4>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                  <div className="form-floating mb-2">
                                                    <select
                                                      name="fan1_type"
                                                      value={formData.fan1_type}
                                                      defaultValue={"DEFAULT"}
                                                      id=""
                                                      className="form-select w-100"
                                                    >
                                                      <option
                                                        value="DEFAULT"
                                                        hidden
                                                      >
                                                        Type
                                                      </option>
                                                      <option
                                                        value="Fixed"
                                                        selected={
                                                          formData.fan1_type ===
                                                          "Fixed"
                                                        }
                                                      >
                                                        Fixed
                                                      </option>
                                                      <option
                                                        value="Variable"
                                                        selected={
                                                          formData.fan1_type ===
                                                          "Variable"
                                                        }
                                                      >
                                                        Variable
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                  <div className="table-responsive">
                                                    <table className="table-blk w-100">
                                                      <thead>
                                                        <tr className="bg-success">
                                                          <th>
                                                            Chiller&nbsp;Load(%)
                                                          </th>
                                                          <th>Fan Load(%)</th>
                                                          {/* <th>
                                                            Power Input(kW)
                                                          </th> */}

                                                          <th>
                                                            {`Power Input(${formData?.selected_units})`}
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td>100</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                name="fan1_power_load_100"
                                                                value={
                                                                  formData?.fan1_power_load_100
                                                                }
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                name="fan1_power_input_100"
                                                                value={
                                                                  formData?.fan1_power_input_100
                                                                }
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>75</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_load_75"
                                                                value={
                                                                  formData?.fan1_power_load_75
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_input_75"
                                                                value={
                                                                  formData?.fan1_power_input_75
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>50</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_load_50"
                                                                value={
                                                                  formData?.fan1_power_load_50
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_input_50"
                                                                value={
                                                                  formData?.fan1_power_input_50
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>25</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_load_25"
                                                                value={
                                                                  formData?.fan1_power_load_25
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_input_25"
                                                                value={
                                                                  formData?.fan1_power_input_25
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}

                                          {formData.no_of_fans >= 2 ? (
                                            <div className="col-lg-4">
                                              <div className="row">
                                                <div className="col-lg-12">
                                                  <div className="page-title-box">
                                                    <h4 className="page-title fz-16">
                                                      Fan 2
                                                    </h4>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                  <div className="form-floating mb-2">
                                                    <select
                                                      name="fan2_type"
                                                      value={formData.fan2_type}
                                                      defaultValue={"DEFAULT"}
                                                      id=""
                                                      className="form-select w-100"
                                                    >
                                                      <option
                                                        value="DEFAULT"
                                                        hidden
                                                      >
                                                        Type
                                                      </option>
                                                      <option
                                                        value="Fixed"
                                                        selected={
                                                          formData?.fan2_type ===
                                                          ("Fixed" ||
                                                            formData.fan2_type)
                                                        }
                                                      >
                                                        Fixed
                                                      </option>
                                                      <option
                                                        value="Variable"
                                                        selected={
                                                          formData?.fan2_type ===
                                                          ("Variable" ||
                                                            formData.fan2_type)
                                                        }
                                                      >
                                                        Variable
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                  <div className="table-responsive">
                                                    <table className="table-blk w-100">
                                                      <thead>
                                                        <tr className="bg-success">
                                                          <th>
                                                            Chiller&nbsp;Load(%)
                                                          </th>
                                                          <th>Fan Load(%)</th>
                                                          {/* <th>
                                                            Power Input(kW)
                                                          </th> */}

                                                          <th>
                                                            {`Power Input(${formData?.selected_units})`}
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td>100</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                name="fan2_power_load_100"
                                                                value={
                                                                  formData?.fan2_power_load_100
                                                                }
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                name="fan2_power_input_100"
                                                                value={
                                                                  formData?.fan2_power_input_100
                                                                }
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>75</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_load_75"
                                                                value={
                                                                  formData?.fan2_power_load_75
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_input_75"
                                                                value={
                                                                  formData?.fan2_power_input_75
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>50</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_load_50"
                                                                value={
                                                                  formData?.fan2_power_load_50
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_input_50"
                                                                value={
                                                                  formData?.fan2_power_input_50
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>25</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_load_25"
                                                                value={
                                                                  formData?.fan2_power_load_25
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_input_25"
                                                                value={
                                                                  formData?.fan2_power_input_25
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}

                                          {formData.no_of_fans >= 3 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 3
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan3_type"
                                                        value={
                                                          formData.fan3_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan3_type ===
                                                            ("Fixed" ||
                                                              formData.fan3_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan3_type ===
                                                            ("Variable" ||
                                                              formData.fan3_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table-blk w-100">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            {/* <th>
                                                              Power Input(kW)
                                                            </th> */}

                                                            <th>
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan3_power_load_100"
                                                                  value={
                                                                    formData?.fan3_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan3_power_input_100"
                                                                  value={
                                                                    formData?.fan3_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_load_75"
                                                                  value={
                                                                    formData?.fan3_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_input_75"
                                                                  value={
                                                                    formData?.fan3_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_load_50"
                                                                  value={
                                                                    formData?.fan3_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_input_50"
                                                                  value={
                                                                    formData?.fan3_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_load_25"
                                                                  value={
                                                                    formData?.fan3_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_input_25"
                                                                  value={
                                                                    formData?.fan3_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {formData.no_of_fans >= 4 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 4
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan4_type"
                                                        value={
                                                          formData.fan4_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        id=""
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan4_type ===
                                                            ("Fixed" ||
                                                              formData.fan4_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan4_type ===
                                                            ("Variable" ||
                                                              formData.fan4_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table-blk w-100">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            {/* <th>
                                                              Power Input(kW)
                                                            </th> */}
                                                            <th>
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan4_power_load_100"
                                                                  value={
                                                                    formData?.fan4_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan4_power_input_100"
                                                                  value={
                                                                    formData?.fan4_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_load_75"
                                                                  value={
                                                                    formData?.fan4_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_input_75"
                                                                  value={
                                                                    formData?.fan4_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_load_50"
                                                                  value={
                                                                    formData?.fan4_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_input_50"
                                                                  value={
                                                                    formData?.fan4_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_load_25"
                                                                  value={
                                                                    formData?.fan4_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_input_25"
                                                                  value={
                                                                    formData?.fan4_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 5 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 5
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan5_type"
                                                        value={
                                                          formData.fan5_type
                                                        }
                                                        id=""
                                                        defaultValue={"DEFAULT"}
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan5_type ===
                                                            ("Fixed" ||
                                                              formData.fan5_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan5_type ===
                                                            ("Variable" ||
                                                              formData.fan5_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table-blk w-100">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            {/* <th>
                                                              Power Input(kW)
                                                            </th> */}

                                                            <th>
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan5_power_load_100"
                                                                  value={
                                                                    formData?.fan5_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan5_power_input_100"
                                                                  value={
                                                                    formData?.fan5_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_load_75"
                                                                  value={
                                                                    formData?.fan5_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_input_75"
                                                                  value={
                                                                    formData?.fan5_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_load_50"
                                                                  value={
                                                                    formData?.fan5_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_input_50"
                                                                  value={
                                                                    formData?.fan5_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_load_25"
                                                                  value={
                                                                    formData?.fan5_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_input_25"
                                                                  value={
                                                                    formData?.fan5_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 6 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 6
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan6_type"
                                                        value={
                                                          formData.fan6_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        id=""
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan6_type ===
                                                            ("Fixed" ||
                                                              formData.fan6_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan6_type ===
                                                            ("Variable" ||
                                                              formData.fan6_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="w-100 table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            {/* <th>
                                                              Power Input(kW)
                                                            </th> */}

                                                            <th>
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan6_power_load_100"
                                                                  value={
                                                                    formData?.fan6_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan6_power_input_100"
                                                                  value={
                                                                    formData?.fan6_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_load_75"
                                                                  value={
                                                                    formData?.fan6_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_input_75"
                                                                  value={
                                                                    formData?.fan6_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_load_50"
                                                                  value={
                                                                    formData?.fan6_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_input_50"
                                                                  value={
                                                                    formData?.fan6_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_load_25"
                                                                  value={
                                                                    formData?.fan6_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_input_25"
                                                                  value={
                                                                    formData?.fan6_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 7 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 7
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan7_type"
                                                        value={
                                                          formData.fan7_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan7_type ===
                                                            ("Fixed" ||
                                                              formData.fan7_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan7_type ===
                                                            ("Variable" ||
                                                              formData.fan7_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="w-100 table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            {/* <th>
                                                              Power Input(kW)
                                                            </th> */}
                                                            <th>
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan7_power_load_100"
                                                                  value={
                                                                    formData?.fan7_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan7_power_input_100"
                                                                  value={
                                                                    formData?.fan7_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_load_75"
                                                                  value={
                                                                    formData?.fan7_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_input_75"
                                                                  value={
                                                                    formData?.fan7_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_load_50"
                                                                  value={
                                                                    formData?.fan7_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_input_50"
                                                                  value={
                                                                    formData?.fan7_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_load_25"
                                                                  value={
                                                                    formData?.fan7_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_input_25"
                                                                  value={
                                                                    formData?.fan7_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 8 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 8
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan8_type"
                                                        value={
                                                          formData.fan8_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        id=""
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan8_type ===
                                                            ("Fixed" ||
                                                              formData.fan8_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan8_type ===
                                                            ("Variable" ||
                                                              formData.fan8_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="w-100 table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            {/* <th>
                                                              Power Input(kW)
                                                            </th> */}

                                                            <th>
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan8_power_load_100"
                                                                  value={
                                                                    formData?.fan8_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan8_power_input_100"
                                                                  value={
                                                                    formData?.fan8_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_load_75"
                                                                  value={
                                                                    formData?.fan8_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_input_75"
                                                                  value={
                                                                    formData?.fan8_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_load_50"
                                                                  value={
                                                                    formData?.fan8_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_input_50"
                                                                  value={
                                                                    formData?.fan8_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_load_25"
                                                                  value={
                                                                    formData?.fan8_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_input_25"
                                                                  value={
                                                                    formData?.fan8_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 9 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 9
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan9_type"
                                                        value={
                                                          formData.fan9_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        id=""
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan9_type ===
                                                            ("Fixed" ||
                                                              formData.fan9_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan9_type ===
                                                            ("Variable" ||
                                                              formData.fan9_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="w-100 table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            {/* <th>
                                                              Power Input(kW)
                                                            </th> */}
                                                            <th>
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan9_power_load_100"
                                                                  value={
                                                                    formData?.fan9_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan9_power_input_100"
                                                                  value={
                                                                    formData?.fan9_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_load_75"
                                                                  value={
                                                                    formData?.fan9_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_input_75"
                                                                  value={
                                                                    formData?.fan9_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_load_50"
                                                                  value={
                                                                    formData?.fan9_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_input_50"
                                                                  value={
                                                                    formData?.fan9_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_load_25"
                                                                  value={
                                                                    formData?.fan9_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_input_25"
                                                                  value={
                                                                    formData?.fan9_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 10 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 10
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan10_type"
                                                        value={
                                                          formData.fan10_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        id=""
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan10_type ===
                                                            ("Fixed" ||
                                                              formData.fan10_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan10_type ===
                                                            ("Variable" ||
                                                              formData.fan10_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="w-100 table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            {/* <th>
                                                              Power Input(kW)
                                                            </th> */}
                                                            <th>
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan10_power_load_100"
                                                                  value={
                                                                    formData?.fan10_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan10_power_input_100"
                                                                  value={
                                                                    formData?.fan10_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_load_75"
                                                                  value={
                                                                    formData?.fan10_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_input_75"
                                                                  value={
                                                                    formData?.fan10_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_load_50"
                                                                  value={
                                                                    formData?.fan10_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_input_50"
                                                                  value={
                                                                    formData?.fan10_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_load_25"
                                                                  value={
                                                                    formData?.fan10_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_input_25"
                                                                  value={
                                                                    formData?.fan10_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {formData.project_phase !== "As Operated" && (
                                    <>
                                      <div className={"row clearfix pd-10"}>
                                        <div className="col-md-12">
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            Cooling Tower
                                          </h3>
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="yellowcol  ">
                                                Wet Bulb Temperature (0C)
                                              </h3>
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_1}
                                                type={"text"}
                                                label={"Wet Bulb Temp 1"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_2}
                                                type={"text"}
                                                label={"Wet Bulb Temp 2"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_3}
                                                type={"text"}
                                                label={"Wet Bulb Temp 3"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_4}
                                                type={"text"}
                                                label={"Wet Bulb Temp 4"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_5}
                                                type={"text"}
                                                label={"Wet Bulb Temp 5"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_6}
                                                type={"text"}
                                                label={"Wet Bulb Temp 6"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_7}
                                                type={"text"}
                                                label={"Wet Bulb Temp 7"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_8}
                                                type={"text"}
                                                label={"Wet Bulb Temp 8"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_9}
                                                type={"text"}
                                                label={"Wet Bulb Temp 9"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.wet_bulb_temp_10
                                                }
                                                type={"text"}
                                                label={"Wet Bulb Temp 10"}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="yellowcol  ">
                                                Corresponding Leaving
                                                Temperature (<sup>0</sup>C)
                                              </h3>
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_1
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 1"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_2
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 2"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_3
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 3"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_4
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 4"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_5
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 5"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_6
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 6"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_7
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 7"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_8
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 8"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_9
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 9"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_10
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 10"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {formData.project_phase !== "As Operated" && (
                                    <>
                                      <div className={"row clearfix"}>
                                        <div className="col-md-12">
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            Coefficient Information
                                          </h3>
                                          <div className="row">
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.curvature_coefficient_type
                                                }
                                                type={"text"}
                                                label={
                                                  "Curvature Coefficient Type"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4"></div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.temperature}
                                                type={"text"}
                                                label={"temperature"}
                                              />
                                            </div>
                                          </div>
                                          <br />
                                          <div className="row">
                                            <div className="col-md-12 row">
                                              <div className="col-md-12">
                                                <h3
                                                  style={{ fontSize: "16px" }}
                                                  className="yellowcol p-2"
                                                >
                                                  Condenser Water Leaving
                                                  Temperature (<sup>0</sup>C)
                                                </h3>
                                              </div>
                                              <InputWidget
                                                cls="col-md-4"
                                                readonly={true}
                                                value={
                                                  formData.condenser_temp_1
                                                }
                                                type={"text"}
                                                label="Condenser Temp 1"
                                              />
                                              <InputWidget
                                                cls="col-md-4"
                                                readonly={true}
                                                value={
                                                  formData.condenser_temp_2
                                                }
                                                type={"text"}
                                                label="Condenser Temp 2"
                                              />
                                              <InputWidget
                                                cls="col-md-4"
                                                readonly={true}
                                                value={
                                                  formData.condenser_temp_3
                                                }
                                                type={"text"}
                                                label="Condenser Temp 3"
                                              />
                                              <InputWidget
                                                cls="col-md-4"
                                                readonly={true}
                                                value={
                                                  formData.condenser_temp_4
                                                }
                                                type={"text"}
                                                label="Condenser Temp 4"
                                              />
                                              <InputWidget
                                                cls="col-md-4"
                                                readonly={true}
                                                value={
                                                  formData.condenser_temp_5
                                                }
                                                type={"text"}
                                                label="Condenser Temp 5"
                                              />
                                              <div className="col-md-4"></div>
                                              <br />

                                              <div className="col-md-12">
                                                <h3
                                                  style={{ fontSize: "16px" }}
                                                  className="yellowcol p-2"
                                                >
                                                  Evaporator Chilled Water
                                                  Outlet Temperature (
                                                  <sup>0</sup>C)
                                                </h3>
                                              </div>

                                              <InputWidget
                                                cls="col-md-4"
                                                readonly={true}
                                                value={
                                                  formData.evaporator_chilled_water_outlet_1
                                                }
                                                type={"text"}
                                                label="Evaporator Chilled Water Outlet 1"
                                              />
                                              <InputWidget
                                                cls="col-md-4"
                                                readonly={true}
                                                value={
                                                  formData.evaporator_chilled_water_outlet_2
                                                }
                                                type={"text"}
                                                label="Evaporator Chilled Water Outlet 2"
                                              />
                                              <InputWidget
                                                cls="col-md-4"
                                                readonly={true}
                                                value={
                                                  formData.evaporator_chilled_water_outlet_3
                                                }
                                                type={"text"}
                                                label="Evaporator Chilled Water Outlet 3"
                                              />
                                              <InputWidget
                                                cls="col-md-4"
                                                readonly={true}
                                                value={
                                                  formData.evaporator_chilled_water_outlet_4
                                                }
                                                type={"text"}
                                                label="Evaporator Chilled Water Outlet 4"
                                              />
                                              <InputWidget
                                                cls="col-md-4"
                                                readonly={true}
                                                value={
                                                  formData.evaporator_chilled_water_outlet_5
                                                }
                                                type={"text"}
                                                label="Evaporator Chilled Water Outlet 5"
                                              />
                                              <div className="col-md-4"></div>
                                              <br />
                                              <div className="col-md-12">
                                                <h3
                                                  style={{ fontSize: "16px" }}
                                                  className="yellowcol p-2"
                                                >
                                                  {/* Capacity (kW) */}
                                                  {`Capacity (${formData?.selected_units})`}
                                                </h3>
                                              </div>
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_1}
                                                type={"text"}
                                                // label="Capacity KW 1"
                                                label={`Capacity ${formData?.selected_units} 1`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_2}
                                                type={"text"}
                                                // label="Capacity KW 2"
                                                label={`Capacity ${formData?.selected_units} 2`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_3}
                                                type={"text"}
                                                // label="Capacity KW 3"
                                                label={`Capacity ${formData?.selected_units} 3`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_4}
                                                type={"text"}
                                                // label="Capacity KW 4"
                                                label={`Capacity ${formData?.selected_units} 4`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_5}
                                                type={"text"}
                                                // label="Capacity KW 5"
                                                label={`Capacity ${formData?.selected_units} 5`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_6}
                                                type={"text"}
                                                // label="Capacity KW 6"
                                                label={`Capacity ${formData?.selected_units} 6`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_7}
                                                type={"text"}
                                                // label="Capacity KW 7"
                                                label={`Capacity ${formData?.selected_units} 7`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_8}
                                                type={"text"}
                                                // label="Capacity KW 8"
                                                label={`Capacity ${formData?.selected_units} 8`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_9}
                                                type={"text"}
                                                // label="Capacity KW 9"
                                                label={`Capacity ${formData?.selected_units} 9`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_10}
                                                type={"text"}
                                                // label="Capacity KW 10"
                                                label={`Capacity ${formData?.selected_units} 10`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_11}
                                                type={"text"}
                                                // label="Capacity KW 11"
                                                label={`Capacity ${formData?.selected_units} 11`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_12}
                                                type={"text"}
                                                // label="Capacity KW 12"
                                                label={`Capacity ${formData?.selected_units} 12`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_13}
                                                type={"text"}
                                                // label="Capacity KW 13"
                                                label={`Capacity ${formData?.selected_units} 13`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_14}
                                                type={"text"}
                                                // label="Capacity KW 14"
                                                label={`Capacity ${formData?.selected_units} 14`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_15}
                                                type={"text"}
                                                // label="Capacity KW 15"
                                                label={`Capacity ${formData?.selected_units} 15`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_16}
                                                type={"text"}
                                                // label="capacity_kw_16"
                                                label={`Capacity ${formData?.selected_units} 16`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_17}
                                                type={"text"}
                                                // label="Capacity KW 17"
                                                label={`Capacity ${formData?.selected_units} 17`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_18}
                                                type={"text"}
                                                // label="Capacity KW 18"
                                                label={`Capacity ${formData?.selected_units} 18`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_19}
                                                type={"text"}
                                                // label="Capacity KW 19"
                                                label={`Capacity ${formData?.selected_units} 19`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_20}
                                                type={"text"}
                                                // label="Capacity KW 20"
                                                label={`Capacity ${formData?.selected_units} 20`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_21}
                                                label={`Capacity ${formData?.selected_units} 21`}
                                                type={"text"}
                                                // label="Capacity KW 21"
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_22}
                                                type={"text"}
                                                // label="Capacity KW 22"
                                                label={`Capacity ${formData?.selected_units} 22`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_23}
                                                type={"text"}
                                                // label="Capacity KW 23"
                                                label={`Capacity ${formData?.selected_units} 23`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_24}
                                                type={"text"}
                                                // label="Capacity KW 24"
                                                label={`Capacity ${formData?.selected_units} 24`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={formData.capacity_kw_25}
                                                type={"text"}
                                                // label="Capacity KW 25"
                                                label={`Capacity ${formData?.selected_units} 25`}
                                              />
                                              <div className="col-md-8"></div>
                                              <br />
                                              <div className="col-md-12">
                                                <h3
                                                  style={{ fontSize: "16px" }}
                                                  className="yellowcol p-2"
                                                >
                                                  {/* Power Input (kW) */}
                                                  {`Power Input (${formData?.selected_units})`}
                                                </h3>
                                              </div>
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_1
                                                }
                                                type={"text"}
                                                // label="Power Input KW 1"
                                                label={`Power Input ${formData?.selected_units} 1`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_2
                                                }
                                                type={"text"}
                                                // label="Power Input KW 2"
                                                label={`Power Input ${formData?.selected_units} 2`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_3
                                                }
                                                type={"text"}
                                                // label="Power Input KW 3"
                                                label={`Power Input ${formData?.selected_units} 3`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_4
                                                }
                                                type={"text"}
                                                // label="Power Input KW 4"
                                                label={`Power Input ${formData?.selected_units} 4`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_5
                                                }
                                                type={"text"}
                                                // label="Power Input KW 5"
                                                label={`Power Input ${formData?.selected_units} 5`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_6
                                                }
                                                type={"text"}
                                                // label="Power Input KW 6"
                                                label={`Power Input ${formData?.selected_units} 6`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_7
                                                }
                                                type={"text"}
                                                // label="Power Input KW 7"
                                                label={`Power Input ${formData?.selected_units} 7`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_8
                                                }
                                                type={"text"}
                                                // label="Power Input KW 8"
                                                label={`Power Input ${formData?.selected_units} 8`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_9
                                                }
                                                type={"text"}
                                                // label="Power Input KW 9"
                                                label={`Power Input ${formData?.selected_units} 9`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_10
                                                }
                                                type={"text"}
                                                // label="Power Input KW 10"
                                                label={`Power Input ${formData?.selected_units} 10`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_11
                                                }
                                                type={"text"}
                                                // label="Power Input KW 11"
                                                label={`Power Input ${formData?.selected_units} 11`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_12
                                                }
                                                type={"text"}
                                                // label="Power Input KW 12"
                                                label={`Power Input ${formData?.selected_units} 12`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_13
                                                }
                                                type={"text"}
                                                // label="Power Input KW 13"
                                                label={`Power Input ${formData?.selected_units} 13`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_14
                                                }
                                                type={"text"}
                                                // label="Power Input KW 14"
                                                label={`Power Input ${formData?.selected_units} 14`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_15
                                                }
                                                type={"text"}
                                                // label="Power Input KW 15"
                                                label={`Power Input ${formData?.selected_units} 15`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_16
                                                }
                                                type={"text"}
                                                // label="Power Input KW 16"
                                                label={`Power Input ${formData?.selected_units} 16`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_17
                                                }
                                                type={"text"}
                                                // label="Power Input KW 17"
                                                label={`Power Input ${formData?.selected_units} 17`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_18
                                                }
                                                type={"text"}
                                                // label="Power Input KW 18"
                                                label={`Power Input ${formData?.selected_units} 18`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_19
                                                }
                                                type={"text"}
                                                // label="Power Input KW 19"
                                                label={`Power Input ${formData?.selected_units} 19`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_20
                                                }
                                                type={"text"}
                                                // label="Power Input KW 20"
                                                label={`Power Input ${formData?.selected_units} 20`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_21
                                                }
                                                type={"text"}
                                                // label="Power Input KW 21"
                                                label={`Power Input ${formData?.selected_units} 21`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_22
                                                }
                                                type={"text"}
                                                // label="Power Input KW 22"
                                                label={`Power Input ${formData?.selected_units} 22`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_23
                                                }
                                                type={"text"}
                                                // label="Power Input KW 23"
                                                label={`Power Input ${formData?.selected_units} 23`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_24
                                                }
                                                type={"text"}
                                                // label="Power Input KW 24"
                                                label={`Power Input ${formData?.selected_units} 24`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.power_input_kw_25
                                                }
                                                type={"text"}
                                                // label="Power Input KW 25"
                                                label={`Power Input ${formData?.selected_units} 25`}
                                              />
                                              <div className="col-md-8"></div>
                                              <br />
                                              <div className="col-md-12">
                                                <h3
                                                  style={{ fontSize: "16px" }}
                                                  className="yellowcol p-2"
                                                >
                                                  {/* Partial Chiller Capacity (kW) */}
                                                  {` Partial Chiller Capacity (${formData?.selected_units})`}
                                                </h3>
                                              </div>
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.partial_chiller_capacity_kw_1
                                                }
                                                type={"text"}
                                                // label="Partial Chiller Capacity KW 1"
                                                label={`Partial Chiller Capacity ${formData?.selected_units} 1`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.partial_chiller_capacity_kw_2
                                                }
                                                type={"text"}
                                                // label="Partial Chiller Capacity KW 2"
                                                label={`Partial Chiller Capacity ${formData?.selected_units} 1`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.partial_chiller_capacity_kw_3
                                                }
                                                type={"text"}
                                                // label="Partial Chiller Capacity KW 3"
                                                label={`Partial Chiller Capacity ${formData?.selected_units} 3`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.partial_chiller_capacity_kw_4
                                                }
                                                type={"text"}
                                                // label="Partial Chiller Capacity KW 4"
                                                label={`Partial Chiller Capacity ${formData?.selected_units} 4`}
                                              />
                                              <div className="col-md-12">
                                                <h3
                                                  style={{ fontSize: "16px" }}
                                                  className="yellowcol p-2"
                                                >
                                                  {/* Partial Power Input (kW) */}
                                                  {` Partial Power Input (${formData?.selected_units})`}
                                                </h3>
                                              </div>

                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.partial_power_input_kw_1
                                                }
                                                type={"text"}
                                                // label="Partial Power Input KW 1"
                                                label={`Partial Power Input ${formData?.selected_units} 1`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.partial_power_input_kw_2
                                                }
                                                type={"text"}
                                                // label="Partial Power Input KW 2"
                                                label={`Partial Power Input ${formData?.selected_units} 2`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.partial_power_input_kw_3
                                                }
                                                type={"text"}
                                                // label="Partial Power Input KW 3"
                                                label={`Partial Power Input ${formData?.selected_units} 3`}
                                              />
                                              <InputWidget
                                                cls="col-md-3"
                                                readonly={true}
                                                value={
                                                  formData.partial_power_input_kw_4
                                                }
                                                type={"text"}
                                                // label="Partial Power Input KW 4"
                                                label={`Partial Power Input ${formData?.selected_units} 4`}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="card border-0">
                              <div className="row d-flex align-items-center justify-content-between">
                                <div className="col-lg-6">
                                  <h2 className="text-white text-center mb-0 my-4">
                                    View Project Details
                                  </h2>
                                </div>

                                <div className="col-lg-2">
                                  <button
                                    onClick={handleExport}
                                    className="btn btn-primary"
                                    type="button"
                                  >
                                    Export PDF
                                  </button>
                                </div>
                              </div>

                              <>
                                <h3
                                  style={{ fontSize: "18px" }}
                                  className="yellowcol bg-success text-center p-2"
                                >
                                  Project Information
                                </h3>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="row">
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData._id}
                                          type={"text"}
                                          label="Project Referance ID"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          // value={formData.project_date}
                                          value={new Date(
                                            formData.project_date
                                          ).toLocaleString()}
                                          type={"text"}
                                          label="Date"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.gsas_project_id}
                                          type={"text"}
                                          label=" Project ID"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.project_name}
                                          type={"text"}
                                          label="Project Name"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.consultant_name}
                                          type={"text"}
                                          label="Consultant Name"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.client_name}
                                          type={"text"}
                                          label="Client Name"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.project_phase}
                                          type={"text"}
                                          label="Project Phase"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.country_id}
                                          type={"text"}
                                          label="Country"
                                        />
                                        <InputWidget
                                          cls="col-md-6"
                                          readonly={true}
                                          value={formData.city_id}
                                          type={"text"}
                                          label="City"
                                        />

                                        {/* <InputWidget cls="col-md-6" readonly={false} value={formData.project_status} type={"text"} label="project_status" /> */}
                                      </div>
                                      {formData.project_phase !==
                                        "As Operated" && (
                                        <>
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            Chillers
                                          </h3>
                                          <div className="row">
                                            <InputWidget
                                              cls="col-md-12"
                                              readonly={true}
                                              value={formData.no_of_chillers}
                                              type={"text"}
                                              label="No of Chillers"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_1_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 1 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_2_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 2 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_3_capacity
                                              }
                                              type={"text"}
                                              label="chiller_3_capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_4_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 4 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_5_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 5 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_6_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 6 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_7_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 7 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_8_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 8 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_9_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 9 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-3"
                                              readonly={true}
                                              value={
                                                formData.chiller_10_capacity
                                              }
                                              type={"text"}
                                              label="Chiller 10 Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-12"
                                              readonly={true}
                                              value={
                                                formData.if_capacity_is_lower_than_minimum_will_the_chiller_start
                                              }
                                              type={"text"}
                                              label="If Capacity Is Lower Than Minimum Will The Chiller Start"
                                            />

                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.minimum_start_up_capacity
                                              }
                                              type={"text"}
                                              label="Minimum Start Up Capacity"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={formData.nominal_cop}
                                              type={"text"}
                                              label="Nominal Cop"
                                            />
                                            {/* <InputWidget
                                              cls="col-md-12"
                                              readonly={true}
                                              value={
                                                formData.cooling_load_profile_file
                                              }
                                              type={"text"}
                                              label="Cooling Load Profile_File"
                                            /> */}
                                            <div className="col-md-6">
                                              <label
                                                style={{ fontSize: "12px" }}
                                                className="form-floating"
                                              >
                                                Cooling Load Profile File:
                                                {formData?.cooling_load_profile_file && (
                                                  <>
                                                    <a
                                                      rel="noreferrer"
                                                      style={{
                                                        color: "#FFFF00",
                                                        marginLeft: "15px",
                                                        fontSize: "14px",
                                                      }}
                                                      href={`${BaseURL}${formData?.cooling_load_profile_file}`}
                                                      target="_blank"
                                                    >
                                                      View document
                                                    </a>
                                                  </>
                                                )}
                                              </label>
                                            </div>

                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.distribution_losses_type
                                              }
                                              type={"text"}
                                              label="distribution_losses_type"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.distribution_losses
                                              }
                                              type={"text"}
                                              label="distribution_losses"
                                            />

                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.condenser_cooling_media
                                              }
                                              type={"text"}
                                              label="Condenser Cooling Media"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={formData.compressor_type}
                                              type={"text"}
                                              label="Compressor Type"
                                            />

                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.condenser_temperature
                                              }
                                              type={"text"}
                                              label="Condenser Temperature"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={
                                                formData.evaporator_temperature
                                              }
                                              type={"text"}
                                              label="Evaporator Temperature"
                                            />
                                            <InputWidget
                                              cls="col-md-6"
                                              readonly={true}
                                              value={formData.condenser_delta_t}
                                              type={"text"}
                                              label="Condenser Delta t"
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    <div className="col-lg-6">
                                      <Autocomplete
                                        // ref={autocompleteInputRef}
                                        style={{
                                          width: "100%",
                                          background: " rgb(5 32 113 / 50%)",
                                          border: "0px",
                                          height: "38px",
                                          marginBottom: "12px",
                                          padding: "0px 15px",
                                          color: "#fff",
                                          borderRadius: "25px",
                                        }}
                                        apiKey={GoogleMapApiKey}
                                        inputAutocompleteValue={
                                          formData.search_location
                                        }
                                        // defaultInputValue={"DFSDFSDF"}
                                        onPlaceSelected={(place) => {
                                          locationUpdateChange(
                                            place.formatted_address,
                                            place.geometry.location.lat(),
                                            place.geometry.location.lng()
                                          );
                                          // console.log(place.formatted_address);
                                          // console.log(place);
                                          // console.log(
                                          //   "Lat",
                                          //   place.geometry.location.lat()
                                          // );
                                          // console.log(
                                          //   "Lng",
                                          //   place.geometry.location.lng()
                                          // );
                                          // // setFormData({...formData, "search_location":  place.geometry.location.lat()});
                                          setFormData({
                                            ...formData,
                                            lat: place.geometry.location.lat(),
                                          });
                                          setFormData({
                                            ...formData,
                                            long: place.geometry.location.lng(),
                                          });
                                        }}
                                      />
                                      {/* <InputWidget 
                                      readonly={false}
                                      value={formData.search_location}
                                      type={"text"}
                                      label="Search Location"
                                    /> */}
                                      <div className="row">
                                        <div className="col-md-6">
                                          <InputWidget
                                            cls="col-md-12"
                                            readonly={false}
                                            value={formData.lat}
                                            type={"text"}
                                            label="Lattitude"
                                          />
                                        </div>
                                        <div className="col-md-6">
                                          <InputWidget
                                            cls="col-md-12"
                                            readonly={false}
                                            value={formData.long}
                                            type={"text"}
                                            label="Longitude"
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        {/* <iframe 
                                        style={{ border: 0 }}
                                        src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.7484973930896!2d"+formData.lat+"!3d"+formData.long+"!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35fb79dae665c7%3A0x8f5c39c275b228dc!2s"+formData.search_location+"!5e0!3m2!1sen!2sin!4v1681731154619!5m2!1sen!2sin"}
                                        width="100%"
                                        height="480"
                                        allowFullScreen={true}
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                      ></iframe> */}

                                        <div
                                          id="map"
                                          style={{
                                            width: "100%",
                                            height: "480px",
                                          }}
                                        ></div>

                                        {/* <Map markersList={markersList} center={centerObj} /> */}

                                        {/* <CommonMapComponent center={centerObj} markersList={markersList} /> */}
                                      </div>
                                    </div>

                                    {formData.project_phase !==
                                      "As Operated" && (
                                      <>
                                        <div className="col-md-12">
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            ThermalStore
                                          </h3>
                                        </div>
                                        {formData.is_thermal_store_available ===
                                        "No" ? (
                                          <InputWidget
                                            cls="col-md-4"
                                            readonly={true}
                                            value={
                                              formData.is_thermal_store_available
                                            }
                                            type={"text"}
                                            label="Is Thermal Store Available"
                                          />
                                        ) : (
                                          <>
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={
                                                formData.thermal_store_capacity_kwh
                                              }
                                              type={"text"}
                                              // label="Thermal Store Capacity KWH"
                                              label={`Thermal Store Capacity ${formData?.selected_units}`}
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={
                                                formData.lower_allowance_capacity_in_percentage
                                              }
                                              type={"text"}
                                              label="Lower Allowance Capacity In Percentage"
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={
                                                formData.charging_capacity_kwh_per_hr_maximum
                                              }
                                              type={"text"}
                                              // label="Charging Capacity KWH per hr maximum"
                                              label={`Charging Capacity ${formData?.selected_units} per hr minimum`}
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={
                                                formData.charging_capacity_kwh_per_hr_minimum
                                              }
                                              type={"text"}
                                              // label="Charging Capacity KWH per hr minimum"
                                              label={`Charging Capacity ${formData?.selected_units} per hr minimum`}
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={
                                                formData.maximum_daily_thershold
                                              }
                                              type={"text"}
                                              label="Maximum Daily Threshold"
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={formData.charging_cycles}
                                              type={"text"}
                                              label="Charging Cycles"
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={formData.losses_per_day}
                                              type={"text"}
                                              label="Losses Per Day"
                                            />
                                            <InputWidget
                                              cls="col-md-4"
                                              readonly={true}
                                              value={formData.tes_pumps}
                                              type={"text"}
                                              label="Tes Pumps"
                                            />
                                          </>
                                        )}
                                      </>
                                    )}

                                    {formData.project_phase !==
                                      "As Operated" && (
                                      <>
                                        <>
                                          <div className="col-md-12">
                                            <h3
                                              style={{ fontSize: "18px" }}
                                              className="yellowcol bg-success text-center p-2"
                                            >
                                              Pumps
                                            </h3>
                                          </div>

                                          {formData.condenser_pump_type &&
                                          formData.condenser_pump_load_100 &&
                                          formData.condenser_pump_power_input_100 ? (
                                            <>
                                              <div className="col-lg-6 my-4">
                                                <h3 className="yellowcol text-center clearfix">
                                                  Condenser Pump
                                                </h3>
                                                <div className="row">
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <InputWidget
                                                        cls="col-md-12"
                                                        readonly={true}
                                                        value={
                                                          formData.condenser_pump_type
                                                        }
                                                        type={"text"}
                                                        label="Condenser Pump Type"
                                                      />
                                                    </div>
                                                  </div>
                                                  <table className="table table-blk">
                                                    <thead>
                                                      <tr className="bg-primary">
                                                        <th>
                                                          Chiller&nbsp;Load(%)
                                                        </th>
                                                        <th>Pump Load(%)</th>
                                                        {/* <th>
                                                            Power Input(kW)
                                                          </th> */}
                                                        <th>
                                                          {`Power Input (${formData?.selected_units})`}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>100</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.condenser_pump_load_100
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 100"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.condenser_pump_power_input_100
                                                            }
                                                            type={"number"}
                                                            label="Power Input 100"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>75</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.condenser_pump_load_75
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 75"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.condenser_pump_power_input_75
                                                            }
                                                            type={"number"}
                                                            label="Power Input 75"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>50</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.condenser_pump_load_50
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 50"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.condenser_pump_power_input_50
                                                            }
                                                            type={"number"}
                                                            label="Power Input 50"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>25</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.condenser_pump_load_25
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 25"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.condenser_pump_power_input_25
                                                            }
                                                            type={"number"}
                                                            label="Power Input 25"
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {formData.primary_pump_type &&
                                          formData.primary_pump_load_100 &&
                                          formData.primary_pump_power_input_100 ? (
                                            <>
                                              <div className="col-lg-6">
                                                <h3 className="yellowcol text-center clearfix">
                                                  Primary Pump
                                                </h3>
                                                <div className="row">
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <InputWidget
                                                        cls="col-md-12"
                                                        readonly={true}
                                                        value={
                                                          formData.primary_pump_type
                                                        }
                                                        type={"text"}
                                                        label="Primary Pump Type"
                                                      />
                                                    </div>
                                                  </div>
                                                  <table className="table table-blk">
                                                    <thead>
                                                      <tr className="bg-primary">
                                                        <th>
                                                          Chiller&nbsp;Load(%)
                                                        </th>
                                                        <th>Pump Load(%)</th>
                                                        <th>
                                                          {/* Power Input(kW) */}
                                                          {`Power Input (${formData?.selected_units})`}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>100</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.primary_pump_load_100
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 100"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.primary_pump_power_input_100
                                                            }
                                                            type={"number"}
                                                            label="Power Input 100"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>75</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.primary_pump_load_75
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 75"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.primary_pump_power_input_75
                                                            }
                                                            type={"number"}
                                                            label="Power Input 75"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>50</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.primary_pump_load_50
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 50"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.primary_pump_power_input_50
                                                            }
                                                            type={"number"}
                                                            label="Power Input 50"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>25</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.primary_pump_load_25
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 25"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.primary_pump_power_input_25
                                                            }
                                                            type={"number"}
                                                            label="Power Input 25"
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {formData.secondary_pump_type &&
                                          formData.secondary_pump_load_100 &&
                                          formData.secondary_pump_power_input_100 ? (
                                            <>
                                              <div className="col-lg-6">
                                                <h3 className="yellowcol text-center clearfix">
                                                  Secondary Pump
                                                </h3>
                                                <div className="row">
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <InputWidget
                                                        cls="col-md-12"
                                                        readonly={true}
                                                        value={
                                                          formData.secondary_pump_type
                                                        }
                                                        type={"text"}
                                                        label="Secondary Pump Type"
                                                      />
                                                    </div>
                                                  </div>
                                                  <table className="table table-blk">
                                                    <thead>
                                                      <tr className="bg-primary">
                                                        <th>
                                                          Chiller&nbsp;Load(%)
                                                        </th>
                                                        <th>Pump Load(%)</th>
                                                        <th>
                                                          {/* Power Input(kW) */}
                                                          {`Power Input (${formData?.selected_units})`}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>100</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.secondary_pump_load_100
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 100"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.secondary_pump_power_input_100
                                                            }
                                                            type={"number"}
                                                            label="Power Input 100"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>75</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.secondary_pump_load_75
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 75"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.secondary_pump_power_input_75
                                                            }
                                                            type={"number"}
                                                            label="Power Input 75"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>50</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.secondary_pump_load_50
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 50"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.secondary_pump_power_input_50
                                                            }
                                                            type={"number"}
                                                            label="Power Input 50"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>25</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.secondary_pump_load_25
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 25"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.secondary_pump_power_input_25
                                                            }
                                                            type={"number"}
                                                            label="Power Input 25"
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {formData.tertiary_pump_type &&
                                          formData.tertiary_pump_load_100 &&
                                          formData.tertiary_pump_power_input_100 ? (
                                            <>
                                              <div className="col-lg-6">
                                                <h3 className="yellowcol text-center clearfix">
                                                  Tertiary Pumps
                                                </h3>
                                                <div className="row">
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <InputWidget
                                                        cls="col-md-12"
                                                        readonly={true}
                                                        value={
                                                          formData.tertiary_pump_type
                                                        }
                                                        type={"text"}
                                                        label="Tertiary Pump Type"
                                                      />
                                                    </div>
                                                  </div>
                                                  <table className="table table-blk">
                                                    <thead>
                                                      <tr className="bg-primary">
                                                        <th>
                                                          Chiller&nbsp;Load(%)
                                                        </th>
                                                        <th>Pump Load(%)</th>
                                                        <th>
                                                          {/* Power Input(kW) */}
                                                          {` Power Input(${formData?.selected_units})`}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>100</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.tertiary_pump_load_100
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 100"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.tertiary_pump_power_input_100
                                                            }
                                                            type={"number"}
                                                            label="Power Input 100"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>75</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.tertiary_pump_load_75
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 75"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.tertiary_pump_power_input_75
                                                            }
                                                            type={"number"}
                                                            label="Power Input 75"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>50</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.tertiary_pump_load_50
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 50"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.tertiary_pump_power_input_50
                                                            }
                                                            type={"number"}
                                                            label="Power Input 50"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>25</td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.tertiary_pump_load_25
                                                            }
                                                            type={"number"}
                                                            label="Pump Load 25"
                                                          />
                                                        </td>
                                                        <td>
                                                          <InputWidget
                                                            cls="col-md-12"
                                                            readonly={true}
                                                            value={
                                                              formData.tertiary_pump_power_input_25
                                                            }
                                                            type={"number"}
                                                            label="Power Input 25"
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                        <div className="col-md-12">
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            Auxiliaries
                                          </h3>
                                        </div>
                                        <div className="row ">
                                          <InputWidget
                                            cls="col-md-12"
                                            readonly={true}
                                            value={
                                              formData.other_auxiliaries_kwh
                                            }
                                            type={"text"}
                                            label={`Other Auxiliaries (${formData?.selected_units}h)`}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>

                                  {formData.project_phase === "As Operated" && (
                                    <>
                                      <OperationalPhaseDetails
                                        formData={formData}
                                      />
                                    </>
                                  )}
                                  <br />
                                  {formData.project_phase !== "As Operated" && (
                                    <>
                                      <div className={"row clearfix pd-10"}>
                                        <div className="col-md-12">
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            Fans
                                          </h3>
                                        </div>
                                        <div className="row">
                                          {formData.no_of_fans >= 1 ? (
                                            <div className="col-lg-4">
                                              <div className="row">
                                                <div className="col-lg-12">
                                                  <div className="page-title-box">
                                                    <h4 className="page-title fz-16">
                                                      Fan 1 {formData.fan1_type}
                                                    </h4>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                  <div className="form-floating mb-2">
                                                    <select
                                                      name="fan1_type"
                                                      value={formData.fan1_type}
                                                      defaultValue={"DEFAULT"}
                                                      id=""
                                                      className="form-select w-100"
                                                    >
                                                      <option
                                                        value="DEFAULT"
                                                        hidden
                                                      >
                                                        Type
                                                      </option>
                                                      <option
                                                        value="Fixed"
                                                        selected={
                                                          formData.fan1_type ===
                                                          "Fixed"
                                                        }
                                                      >
                                                        Fixed
                                                      </option>
                                                      <option
                                                        value="Variable"
                                                        selected={
                                                          formData.fan1_type ===
                                                          "Variable"
                                                        }
                                                      >
                                                        Variable
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                  <div className="table-responsive">
                                                    <table className="table table-blk">
                                                      <thead>
                                                        <tr className="bg-success">
                                                          <th>
                                                            Chiller&nbsp;Load(%)
                                                          </th>
                                                          <th>Fan Load(%)</th>
                                                          <th>
                                                            {/* Power Input(kW) */}
                                                            {`Power Input(${formData?.selected_units})`}
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td>100</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                name="fan1_power_load_100"
                                                                value={
                                                                  formData?.fan1_power_load_100
                                                                }
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                name="fan1_power_input_100"
                                                                value={
                                                                  formData?.fan1_power_input_100
                                                                }
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>75</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_load_75"
                                                                value={
                                                                  formData?.fan1_power_load_75
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_input_75"
                                                                value={
                                                                  formData?.fan1_power_input_75
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>50</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_load_50"
                                                                value={
                                                                  formData?.fan1_power_load_50
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_input_50"
                                                                value={
                                                                  formData?.fan1_power_input_50
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>25</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_load_25"
                                                                value={
                                                                  formData?.fan1_power_load_25
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan1_power_input_25"
                                                                value={
                                                                  formData?.fan1_power_input_25
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}

                                          {formData.no_of_fans >= 2 ? (
                                            <div className="col-lg-4">
                                              <div className="row">
                                                <div className="col-lg-12">
                                                  <div className="page-title-box">
                                                    <h4 className="page-title fz-16">
                                                      Fan 2
                                                    </h4>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                  <div className="form-floating mb-2">
                                                    <select
                                                      name="fan2_type"
                                                      value={formData.fan2_type}
                                                      defaultValue={"DEFAULT"}
                                                      id=""
                                                      className="form-select w-100"
                                                    >
                                                      <option
                                                        value="DEFAULT"
                                                        hidden
                                                      >
                                                        Type
                                                      </option>
                                                      <option
                                                        value="Fixed"
                                                        selected={
                                                          formData?.fan2_type ===
                                                          ("Fixed" ||
                                                            formData.fan2_type)
                                                        }
                                                      >
                                                        Fixed
                                                      </option>
                                                      <option
                                                        value="Variable"
                                                        selected={
                                                          formData?.fan2_type ===
                                                          ("Variable" ||
                                                            formData.fan2_type)
                                                        }
                                                      >
                                                        Variable
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                  <div className="table-responsive">
                                                    <table className="table table-blk">
                                                      <thead>
                                                        <tr className="bg-success">
                                                          <th>
                                                            Chiller&nbsp;Load(%)
                                                          </th>
                                                          <th>Fan Load(%)</th>
                                                          <th>
                                                            {/* Power Input(kW) */}
                                                            {`Power Input(${formData?.selected_units})`}
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td>100</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                name="fan2_power_load_100"
                                                                value={
                                                                  formData?.fan2_power_load_100
                                                                }
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                name="fan2_power_input_100"
                                                                value={
                                                                  formData?.fan2_power_input_100
                                                                }
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>75</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_load_75"
                                                                value={
                                                                  formData?.fan2_power_load_75
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_input_75"
                                                                value={
                                                                  formData?.fan2_power_input_75
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>50</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_load_50"
                                                                value={
                                                                  formData?.fan2_power_load_50
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_input_50"
                                                                value={
                                                                  formData?.fan2_power_input_50
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>25</td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_load_25"
                                                                value={
                                                                  formData?.fan2_power_load_25
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                                min={0}
                                                                max={100}
                                                                step="0.01"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Fan Load
                                                              </label>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="form-floating">
                                                              <input
                                                                name="fan2_power_input_25"
                                                                value={
                                                                  formData?.fan2_power_input_25
                                                                }
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                className="form-control"
                                                                id="floatingInput"
                                                              />
                                                              <label htmlFor="floatingInput">
                                                                Power Input
                                                              </label>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}

                                          {formData.no_of_fans >= 3 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 3
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan3_type"
                                                        value={
                                                          formData.fan3_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan3_type ===
                                                            ("Fixed" ||
                                                              formData.fan3_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan3_type ===
                                                            ("Variable" ||
                                                              formData.fan3_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            <th>
                                                              {/* Power Input(kW) */}
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan3_power_load_100"
                                                                  value={
                                                                    formData?.fan3_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan3_power_input_100"
                                                                  value={
                                                                    formData?.fan3_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_load_75"
                                                                  value={
                                                                    formData?.fan3_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_input_75"
                                                                  value={
                                                                    formData?.fan3_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_load_50"
                                                                  value={
                                                                    formData?.fan3_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_input_50"
                                                                  value={
                                                                    formData?.fan3_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_load_25"
                                                                  value={
                                                                    formData?.fan3_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan3_power_input_25"
                                                                  value={
                                                                    formData?.fan3_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {formData.no_of_fans >= 4 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 4
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan4_type"
                                                        value={
                                                          formData.fan4_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        id=""
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan4_type ===
                                                            ("Fixed" ||
                                                              formData.fan4_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan4_type ===
                                                            ("Variable" ||
                                                              formData.fan4_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            <th>
                                                              {/* Power Input(kW) */}
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan4_power_load_100"
                                                                  value={
                                                                    formData?.fan4_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan4_power_input_100"
                                                                  value={
                                                                    formData?.fan4_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_load_75"
                                                                  value={
                                                                    formData?.fan4_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_input_75"
                                                                  value={
                                                                    formData?.fan4_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_load_50"
                                                                  value={
                                                                    formData?.fan4_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_input_50"
                                                                  value={
                                                                    formData?.fan4_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_load_25"
                                                                  value={
                                                                    formData?.fan4_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan4_power_input_25"
                                                                  value={
                                                                    formData?.fan4_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 5 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 5
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan5_type"
                                                        value={
                                                          formData.fan5_type
                                                        }
                                                        id=""
                                                        defaultValue={"DEFAULT"}
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan5_type ===
                                                            ("Fixed" ||
                                                              formData.fan5_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan5_type ===
                                                            ("Variable" ||
                                                              formData.fan5_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            <th>
                                                              {/* Power Input(kW) */}
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan5_power_load_100"
                                                                  value={
                                                                    formData?.fan5_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan5_power_input_100"
                                                                  value={
                                                                    formData?.fan5_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_load_75"
                                                                  value={
                                                                    formData?.fan5_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_input_75"
                                                                  value={
                                                                    formData?.fan5_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_load_50"
                                                                  value={
                                                                    formData?.fan5_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_input_50"
                                                                  value={
                                                                    formData?.fan5_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_load_25"
                                                                  value={
                                                                    formData?.fan5_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan5_power_input_25"
                                                                  value={
                                                                    formData?.fan5_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 6 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 6
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan6_type"
                                                        value={
                                                          formData.fan6_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        id=""
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan6_type ===
                                                            ("Fixed" ||
                                                              formData.fan6_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan6_type ===
                                                            ("Variable" ||
                                                              formData.fan6_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            <th>
                                                              {/* Power Input(kW) */}
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan6_power_load_100"
                                                                  value={
                                                                    formData?.fan6_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan6_power_input_100"
                                                                  value={
                                                                    formData?.fan6_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_load_75"
                                                                  value={
                                                                    formData?.fan6_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_input_75"
                                                                  value={
                                                                    formData?.fan6_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_load_50"
                                                                  value={
                                                                    formData?.fan6_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_input_50"
                                                                  value={
                                                                    formData?.fan6_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_load_25"
                                                                  value={
                                                                    formData?.fan6_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan6_power_input_25"
                                                                  value={
                                                                    formData?.fan6_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 7 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 7
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan7_type"
                                                        value={
                                                          formData.fan7_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan7_type ===
                                                            ("Fixed" ||
                                                              formData.fan7_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan7_type ===
                                                            ("Variable" ||
                                                              formData.fan7_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            <th>
                                                              {/* Power Input(kW) */}
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan7_power_load_100"
                                                                  value={
                                                                    formData?.fan7_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan7_power_input_100"
                                                                  value={
                                                                    formData?.fan7_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_load_75"
                                                                  value={
                                                                    formData?.fan7_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_input_75"
                                                                  value={
                                                                    formData?.fan7_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_load_50"
                                                                  value={
                                                                    formData?.fan7_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_input_50"
                                                                  value={
                                                                    formData?.fan7_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_load_25"
                                                                  value={
                                                                    formData?.fan7_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan7_power_input_25"
                                                                  value={
                                                                    formData?.fan7_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 8 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 8
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan8_type"
                                                        value={
                                                          formData.fan8_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        id=""
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan8_type ===
                                                            ("Fixed" ||
                                                              formData.fan8_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan8_type ===
                                                            ("Variable" ||
                                                              formData.fan8_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            <th>
                                                              {/* Power Input(kW) */}
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan8_power_load_100"
                                                                  value={
                                                                    formData?.fan8_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan8_power_input_100"
                                                                  value={
                                                                    formData?.fan8_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_load_75"
                                                                  value={
                                                                    formData?.fan8_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_input_75"
                                                                  value={
                                                                    formData?.fan8_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_load_50"
                                                                  value={
                                                                    formData?.fan8_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_input_50"
                                                                  value={
                                                                    formData?.fan8_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_load_25"
                                                                  value={
                                                                    formData?.fan8_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan8_power_input_25"
                                                                  value={
                                                                    formData?.fan8_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 9 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 9
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan9_type"
                                                        value={
                                                          formData.fan9_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        id=""
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan9_type ===
                                                            ("Fixed" ||
                                                              formData.fan9_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan9_type ===
                                                            ("Variable" ||
                                                              formData.fan9_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            <th>
                                                              {/* Power Input(kW) */}
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan9_power_load_100"
                                                                  value={
                                                                    formData?.fan9_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan9_power_input_100"
                                                                  value={
                                                                    formData?.fan9_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_load_75"
                                                                  value={
                                                                    formData?.fan9_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_input_75"
                                                                  value={
                                                                    formData?.fan9_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_load_50"
                                                                  value={
                                                                    formData?.fan9_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_input_50"
                                                                  value={
                                                                    formData?.fan9_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_load_25"
                                                                  value={
                                                                    formData?.fan9_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan9_power_input_25"
                                                                  value={
                                                                    formData?.fan9_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {formData.no_of_fans >= 10 ? (
                                            <>
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="page-title-box">
                                                      <h4 className="page-title fz-16">
                                                        Fan 10
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="form-floating mb-2">
                                                      <select
                                                        name="fan10_type"
                                                        value={
                                                          formData.fan10_type
                                                        }
                                                        defaultValue={"DEFAULT"}
                                                        id=""
                                                        className="form-select w-100"
                                                      >
                                                        <option
                                                          value="DEFAULT"
                                                          hidden
                                                        >
                                                          Type
                                                        </option>
                                                        <option
                                                          value="Fixed"
                                                          selected={
                                                            formData?.fan10_type ===
                                                            ("Fixed" ||
                                                              formData.fan10_type)
                                                          }
                                                        >
                                                          Fixed
                                                        </option>
                                                        <option
                                                          value="Variable"
                                                          selected={
                                                            formData?.fan10_type ===
                                                            ("Variable" ||
                                                              formData.fan10_type)
                                                          }
                                                        >
                                                          Variable
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12 col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="table table-blk">
                                                        <thead>
                                                          <tr className="bg-success">
                                                            <th>
                                                              Chiller&nbsp;Load(%)
                                                            </th>
                                                            <th>Fan Load(%)</th>
                                                            <th>
                                                              {/* Power Input(kW) */}
                                                              {`Power Input(${formData?.selected_units})`}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>100</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  name="fan10_power_load_100"
                                                                  value={
                                                                    formData?.fan10_power_load_100
                                                                  }
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  name="fan10_power_input_100"
                                                                  value={
                                                                    formData?.fan10_power_input_100
                                                                  }
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>75</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_load_75"
                                                                  value={
                                                                    formData?.fan10_power_load_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_input_75"
                                                                  value={
                                                                    formData?.fan10_power_input_75
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>50</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_load_50"
                                                                  value={
                                                                    formData?.fan10_power_load_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_input_50"
                                                                  value={
                                                                    formData?.fan10_power_input_50
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>25</td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_load_25"
                                                                  value={
                                                                    formData?.fan10_power_load_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                  min={0}
                                                                  max={100}
                                                                  step="0.01"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Fan Load
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-floating">
                                                                <input
                                                                  name="fan10_power_input_25"
                                                                  value={
                                                                    formData?.fan10_power_input_25
                                                                  }
                                                                  type="number"
                                                                  pattern="[0-9]*"
                                                                  className="form-control"
                                                                  id="floatingInput"
                                                                />
                                                                <label htmlFor="floatingInput">
                                                                  Power Input
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {formData.project_phase !== "As Operated" && (
                                    <>
                                      <div className={"row clearfix pd-10"}>
                                        <div className="col-md-12">
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            Cooling Tower
                                          </h3>
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="yellowcol  ">
                                                Wet Bulb Temperature (
                                                <sup>0</sup>C)
                                              </h3>
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_1}
                                                type={"text"}
                                                label={"Wet Bulb Temp 1"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_2}
                                                type={"text"}
                                                label={"Wet Bulb Temp 2"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_3}
                                                type={"text"}
                                                label={"Wet Bulb Temp 3"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_4}
                                                type={"text"}
                                                label={"Wet Bulb Temp 4"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_5}
                                                type={"text"}
                                                label={"Wet Bulb Temp 5"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_6}
                                                type={"text"}
                                                label={"Wet Bulb Temp 6"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_7}
                                                type={"text"}
                                                label={"Wet Bulb Temp 7"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_8}
                                                type={"text"}
                                                label={"Wet Bulb Temp 8"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.wet_bulb_temp_9}
                                                type={"text"}
                                                label={"Wet Bulb Temp 9"}
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.wet_bulb_temp_10
                                                }
                                                type={"text"}
                                                label={"Wet Bulb Temp 10"}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="yellowcol  ">
                                                Corresponding Leaving
                                                Temperature (<sup>0</sup>C)
                                              </h3>
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_1
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 1"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_2
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 2"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_3
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 3"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_4
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 4"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_5
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 5"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_6
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 6"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_7
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 7"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_8
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 8"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_9
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 9"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.corresponding_leaving_water_temp_10
                                                }
                                                type={"text"}
                                                label={
                                                  "Corresponding Leaving Water Temp 10"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {formData.project_phase !== "As Operated" && (
                                    <>
                                      <div className={"row clearfix"}>
                                        <div className="col-md-12">
                                          <h3
                                            style={{ fontSize: "18px" }}
                                            className="yellowcol bg-success text-center p-2"
                                          >
                                            Coefficient Information
                                          </h3>
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <h3
                                                style={{ fontSize: "16px" }}
                                                className="yellowcol p-2"
                                              >
                                                Electric chiller
                                                coefficient component
                                              </h3>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={
                                                  formData.curvature_coefficient_type
                                                }
                                                type={"text"}
                                                label={
                                                  "Curvature Coefficient Type"
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4"></div>
                                            <div className="col-md-4">
                                              <InputWidget
                                                cls="col-md-12"
                                                readonly={true}
                                                value={formData.temperature}
                                                type={"text"}
                                                label={"temperature"}
                                              />
                                            </div>
                                          </div>
                                          <br />
                                          <div className="row">
                                            <div className="col-md-12 row">
                                              <div className="col-md-12">
                                                <h3
                                                  style={{ fontSize: "16px" }}
                                                  className="yellowcol p-2"
                                                >
                                                  Coefficient for Cooling
                                                  Capacity
                                                </h3>

                                                <h4 className="text-white text-center">
                                                  Full Load Ratio
                                                </h4>
                                                <p
                                                  className="text-center"
                                                  style={{ color: "#FFFF00" }}
                                                >
                                                  Condenser Water Leaving
                                                  Temperature (<sup>0</sup>C)
                                                </p>
                                              </div>

                                              <div className="row">
                                                <div className="col-lg-12">
                                                  <div className="table-responsive effcfz">
                                                    <table className="table">
                                                      <tbody>
                                                        <tr>
                                                          <td rowSpan="11">
                                                            Evaporator Chilled
                                                            Water Outlet
                                                            Temperature (
                                                            <sup>0</sup>C)
                                                          </td>
                                                          <td></td>
                                                          <td></td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.condenser_temp_1
                                                              }
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.condenser_temp_2
                                                              }
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.condenser_temp_3
                                                              }
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.condenser_temp_4
                                                              }
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.condenser_temp_5
                                                              }
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>

                                                        <tr>
                                                          <td rowSpan="2">
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.evaporator_chilled_water_outlet_1
                                                              }
                                                              name="evaporator_chilled_wat
                                                    readonly={true}er_outlet_1"
                                                            />
                                                          </td>
                                                          {/* <td>Capacity (kW)</td> */}
                                                          <td>{`Capacity (${formData?.selected_units})`}</td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_1
                                                              }
                                                              name="capacity_kw_1"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_2
                                                              }
                                                              name="capacity_kw_2"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_3
                                                              }
                                                              name="capacity_kw_3"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_4
                                                              }
                                                              name="capacity_kw_4"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_5
                                                              }
                                                              name="capacity_kw_5"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            {/* Power Input (kW) */}
                                                            {`Power Input (${formData?.selected_units})`}
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_1
                                                              }
                                                              name="power_input_kw_1"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_2
                                                              }
                                                              name="power_input_kw_2"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            {/* <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_3
                                                    }
                                                    name="power_input_kw_3"
                                                    readonly={true}
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  /> */}

                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_3
                                                              }
                                                              name="power_input_kw_3"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            {/* <input
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="floatingInput"
                                                    value={
                                                      formData?.power_input_kw_4
                                                    }
                                                    name="power_input_kw_4"
                                                    readonly={true}
                                                    className="form-control trspbg"
                                                    placeholder=""
                                                  /> */}

                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_4
                                                              }
                                                              name="power_input_kw_4"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_5
                                                              }
                                                              name="power_input_kw_5"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>

                                                        <tr>
                                                          <td rowSpan="2">
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.evaporator_chilled_water_outlet_2
                                                              }
                                                              name="evaporator_chilled_wat
                                                    readonly={true}er_outlet_2"
                                                            />
                                                          </td>
                                                          {/* <td>Capacity (kW)</td> */}
                                                          <td>{`Capacity (${formData?.selected_units})`}</td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_6
                                                              }
                                                              name="capacity_kw_6"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_7
                                                              }
                                                              name="capacity_kw_7"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_8
                                                              }
                                                              name="capacity_kw_8"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_9
                                                              }
                                                              name="capacity_kw_9"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_10
                                                              }
                                                              name="capacity_kw_10"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            {/* Power Input (kW) */}
                                                            {`Power Input (${formData?.selected_units})`}
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_6
                                                              }
                                                              name="power_input_kw_6"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_7
                                                              }
                                                              name="power_input_kw_7"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_8
                                                              }
                                                              name="power_input_kw_8"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_9
                                                              }
                                                              name="power_input_kw_9"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_10
                                                              }
                                                              name="power_input_kw_10"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td rowSpan="2">
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.evaporator_chilled_water_outlet_3
                                                              }
                                                              name="evaporator_chilled_wat
                                                    readonly={true}er_outlet_3"
                                                            />
                                                          </td>
                                                          {/* <td>Capacity (kW)</td> */}
                                                          <td>{`Capacity (${formData?.selected_units})`}</td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_11
                                                              }
                                                              name="capacity_kw_11"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_12
                                                              }
                                                              name="capacity_kw_12"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_13
                                                              }
                                                              name="capacity_kw_13"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_14
                                                              }
                                                              name="capacity_kw_14"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_15
                                                              }
                                                              name="capacity_kw_15"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>

                                                        <tr>
                                                          <td>
                                                            {/* Power Input (kW) */}
                                                            {`Power Input (${formData?.selected_units})`}
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_11
                                                              }
                                                              name="power_input_kw_11"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_12
                                                              }
                                                              name="power_input_kw_12"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_13
                                                              }
                                                              name="power_input_kw_13"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_14
                                                              }
                                                              name="power_input_kw_14"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_15
                                                              }
                                                              name="power_input_kw_15"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td rowSpan="2">
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.evaporator_chilled_water_outlet_4
                                                              }
                                                              name="evaporator_chilled_wat
                                                    readonly={true}er_outlet_4"
                                                            />
                                                          </td>
                                                          {/* <td>Capacity (kW)</td> */}
                                                          <td>{`Capacity (${formData?.selected_units})`}</td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_16
                                                              }
                                                              name="capacity_kw_16"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_17
                                                              }
                                                              name="capacity_kw_17"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_18
                                                              }
                                                              name="capacity_kw_18"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_19
                                                              }
                                                              name="capacity_kw_19"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_20
                                                              }
                                                              name="capacity_kw_20"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            {/* Power Input (kW) */}
                                                            {`Power Input (${formData?.selected_units})`}
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_16
                                                              }
                                                              name="power_input_kw_16"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_17
                                                              }
                                                              name="power_input_kw_17"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_18
                                                              }
                                                              name="power_input_kw_18"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_19
                                                              }
                                                              name="power_input_kw_19"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_20
                                                              }
                                                              name="power_input_kw_20"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td rowSpan="2">
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.evaporator_chilled_water_outlet_5
                                                              }
                                                              name="evaporator_chilled_wat
                                                    readonly={true}er_outlet_5"
                                                            />
                                                          </td>
                                                          {/* <td>Capacity (kW)</td> */}
                                                          <td>
                                                            {" "}
                                                            {`Capacity (${formData?.selected_units})`}{" "}
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_21
                                                              }
                                                              name="capacity_kw_21"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_22
                                                              }
                                                              name="capacity_kw_22"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_23
                                                              }
                                                              name="capacity_kw_23"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_24
                                                              }
                                                              name="capacity_kw_24"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.capacity_kw_25
                                                              }
                                                              name="capacity_kw_25"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            {/* Power Input (kW) */}
                                                            {` Power Input (${formData?.selected_units})`}
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_21
                                                              }
                                                              name="power_input_kw_21"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_22
                                                              }
                                                              name="power_input_kw_22"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_23
                                                              }
                                                              name="power_input_kw_23"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_24
                                                              }
                                                              name="power_input_kw_24"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                          <td>
                                                            <InputWidget
                                                              className="form-control trspbg"
                                                              value={
                                                                formData?.power_input_kw_25
                                                              }
                                                              name="power_input_kw_25"
                                                              readonly={true}
                                                            />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>

                                                    <div className="col-lg-12">
                                                      <div className="page-title-box">
                                                        <h4
                                                          style={{
                                                            color: "#FFFF00",
                                                          }}
                                                          className="page-title pb-0"
                                                        >
                                                          Part Load Ratio
                                                        </h4>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                      <div className="table-responsive">
                                                        <table className="table table-blk">
                                                          <thead>
                                                            <tr className="bg-success">
                                                              <th>
                                                                Chiller Load (%)
                                                              </th>
                                                              <th>
                                                                {/* Chiller Capacity
                                                                (kW) */}
                                                                {` Chiller Capacity
                                                                (${formData?.selected_units})`}
                                                              </th>
                                                              <th>
                                                                Power Input (kW)
                                                                {` Power Input (${formData?.selected_units})`}
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <td>100</td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  <InputWidget
                                                                    readOnly={
                                                                      true
                                                                    }
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    value={
                                                                      formData?.partial_chiller_capacity_kw_1
                                                                    }
                                                                    name="partial_chiller_capacity_kw_1"
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  <InputWidget
                                                                    readOnly={
                                                                      true
                                                                    }
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    value={
                                                                      formData?.partial_power_input_kw_1
                                                                    }
                                                                    name="partial_power_input_kw_1"
                                                                  />
                                                                </div>
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>75</td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  <InputWidget
                                                                    readOnly={
                                                                      true
                                                                    }
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    value={
                                                                      formData?.partial_chiller_capacity_kw_2
                                                                    }
                                                                    name="partial_chiller_capacity_kw_2"
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  <InputWidget
                                                                    readOnly={
                                                                      true
                                                                    }
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    value={
                                                                      formData?.partial_power_input_kw_2
                                                                    }
                                                                    name="partial_power_input_kw_2"
                                                                  />
                                                                </div>
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>50</td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  <InputWidget
                                                                    readOnly={
                                                                      true
                                                                    }
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    value={
                                                                      formData?.partial_chiller_capacity_kw_3
                                                                    }
                                                                    name="partial_chiller_capacity_kw_3"
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  <InputWidget
                                                                    readOnly={
                                                                      true
                                                                    }
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    value={
                                                                      formData?.partial_power_input_kw_3
                                                                    }
                                                                    name="partial_power_input_kw_3"
                                                                  />
                                                                </div>
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>25</td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  <InputWidget
                                                                    readOnly={
                                                                      true
                                                                    }
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    value={
                                                                      formData?.partial_chiller_capacity_kw_4
                                                                    }
                                                                    name="partial_chiller_capacity_kw_4"
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  <InputWidget
                                                                    readOnly={
                                                                      true
                                                                    }
                                                                    className="form-control"
                                                                    id="floatingInput"
                                                                    value={
                                                                      formData?.partial_power_input_kw_4
                                                                    }
                                                                    name="partial_power_input_kw_4"
                                                                  />
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                      <div className="page-title-box">
                                                        <h4
                                                          style={{
                                                            color: "#FFFF00",
                                                          }}
                                                          className="page-title pb-0"
                                                        >
                                                          Uploaded Document
                                                          Files
                                                        </h4>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                      <div className="table-responsive">
                                                        <table className="table table-blk">
                                                          <thead>
                                                            <tr className="bg-success">
                                                              <th>
                                                                Document File
                                                                Name
                                                              </th>
                                                              <th>
                                                                Uploaded
                                                                Document
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <td>
                                                                Cooling Load
                                                                Profile File
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  {formData?.cooling_load_profile_file && (
                                                                    <>
                                                                      <a
                                                                        rel="noreferrer"
                                                                        style={{
                                                                          color:
                                                                            "#FFFF00",
                                                                          marginLeft:
                                                                            "15px",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                        href={`${BaseURL}${formData?.cooling_load_profile_file}`}
                                                                        target="_blank"
                                                                      >
                                                                        View
                                                                        document
                                                                      </a>
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Chiller Document
                                                                File
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  {formData?.chiller_other_document_file && (
                                                                    <>
                                                                      <a
                                                                        rel="noreferrer"
                                                                        style={{
                                                                          color:
                                                                            "#FFFF00",
                                                                          marginLeft:
                                                                            "15px",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                        href={`${BaseURL}${formData?.chiller_other_document_file}`}
                                                                        target="_blank"
                                                                      >
                                                                        View
                                                                        document
                                                                      </a>
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Thermal Store
                                                                Document File
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  {formData?.tes_other_document_file && (
                                                                    <>
                                                                      <a
                                                                        rel="noreferrer"
                                                                        style={{
                                                                          color:
                                                                            "#FFFF00",
                                                                          marginLeft:
                                                                            "15px",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                        href={`${BaseURL}${formData?.tes_other_document_file}`}
                                                                        target="_blank"
                                                                      >
                                                                        View
                                                                        document
                                                                      </a>
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Pumps Document
                                                                File
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  {formData?.pumps_other_document_file && (
                                                                    <>
                                                                      <a
                                                                        rel="noreferrer"
                                                                        style={{
                                                                          color:
                                                                            "#FFFF00",
                                                                          marginLeft:
                                                                            "15px",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                        href={`${BaseURL}${formData?.pumps_other_document_file}`}
                                                                        target="_blank"
                                                                      >
                                                                        View
                                                                        document
                                                                      </a>
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Auxiliaries
                                                                Document File
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  {formData?.auxiliaries_other_document_file && (
                                                                    <>
                                                                      <a
                                                                        rel="noreferrer"
                                                                        style={{
                                                                          color:
                                                                            "#FFFF00",
                                                                          marginLeft:
                                                                            "15px",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                        href={`${BaseURL}${formData?.auxiliaries_other_document_file}`}
                                                                        target="_blank"
                                                                      >
                                                                        View
                                                                        document
                                                                      </a>
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Cooling Towers
                                                                Document File
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  {formData?.cooling_tower_other_document_file && (
                                                                    <>
                                                                      <a
                                                                        rel="noreferrer"
                                                                        style={{
                                                                          color:
                                                                            "#FFFF00",
                                                                          marginLeft:
                                                                            "15px",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                        href={`${BaseURL}${formData?.cooling_tower_other_document_file}`}
                                                                        target="_blank"
                                                                      >
                                                                        View
                                                                        document
                                                                      </a>
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </td>
                                                            </tr>

                                                            <tr>
                                                              <td>
                                                                Fans Document
                                                                File
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  {formData?.fans_other_document_file && (
                                                                    <>
                                                                      <a
                                                                        rel="noreferrer"
                                                                        style={{
                                                                          color:
                                                                            "#FFFF00",
                                                                          marginLeft:
                                                                            "15px",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                        href={`${BaseURL}${formData?.fans_other_document_file}`}
                                                                        target="_blank"
                                                                      >
                                                                        View
                                                                        document
                                                                      </a>
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </td>
                                                            </tr>

                                                            <tr>
                                                              <td>
                                                                Coefficients
                                                                Document File
                                                              </td>
                                                              <td>
                                                                <div className="form-floating">
                                                                  {formData?.co_efficients_other_document_file && (
                                                                    <>
                                                                      <a
                                                                        rel="noreferrer"
                                                                        style={{
                                                                          color:
                                                                            "#FFFF00",
                                                                          marginLeft:
                                                                            "15px",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                        href={`${BaseURL}${formData?.co_efficients_other_document_file}`}
                                                                        target="_blank"
                                                                      >
                                                                        View
                                                                        document
                                                                      </a>
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <br />

                                              {/* <div className="col-md-12">
                                                <h3
                                                  style={{ fontSize: "16px" }}
                                                  className="yellowcol p-2"
                                                >
                                                  Evaporator Chilled Water
                                                  Outlet Temperature (
                                                  <sup>0</sup>C)
                                                </h3>
                                              </div> */}

                                              <br />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>

                                <div className="form-group row justify-content-end mt-3">
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left prj">
                                    <button
                                      style={{ backgroundColor: "#68b90b" }}
                                      type="submit"
                                      onClick={() => navigate("/totalprojects")}
                                      className="btn text-light mr-1 waves-effect waves-light"
                                    >
                                      Return to previous page
                                    </button>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right prj"></div>
                                </div>
                              </>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProjectDetails;
